import {Language} from "./Model";

export const languages : Language[] = [
    {
        "code": "it",
        "name": "Italian",
        "native": "Italiano"
    },
    {
        "code": "gb",
        "name": "English",
        "native": "English"
    },
    {
        "code": "es",
        "name": "Spanish",
        "native": "Español"
    },
    {
        "code": "fr",
        "name": "French",
        "native": "Français"
    },
    {
        "code": "de",
        "name": "German",
        "native": "Deutsch"
    },
    {
        "code": "jp",
        "name": "Japanese",
        "native": "日本語"
    },
    {
        "code": "cn",
        "name": "Chinese",
        "native": "中文"
    },
]