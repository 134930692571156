import {PageStandard} from "../../utils/PageStandard";
import {Button, ButtonGroup, Card, CardContent, Chip, IconButton, Stack, Typography} from "@mui/joy";
import {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {AuthContext} from "../../utils/AuthProvider";
import moment from "moment";
import firebase from "firebase/compat/app";
import {APIurl} from "../../utils/Utils";
import {BookingFormattedModel} from "../../utils/Model";
import {ChevronLeft, ChevronRight, ZoomOut} from "@mui/icons-material";
import {Link} from "react-router-dom";

export function Agenda() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const [date, setDate] = useState(moment())
    const [bookings, setBookings] = useState<BookingFormattedModel[]>([])

    useEffect(() => {
        fetchBookingsAgenda().then(() => {})
    }, [date]);
    async function fetchBookingsAgenda(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getBookingsAgenda/${date.format("YYYY-MM-DD")}/${user.uid}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setBookings(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }


    return (
        <PageStandard title="Agenda" sidebarVisible={true}>
            <Stack sx={{
                display: 'flex',
                width: "100%",
                maxWidth: '800px',
                mx: 'auto',
                px: {xs: 2, md: 6},
                py: {xs: 2, md: 3},
            }} spacing={2}>
                <Stack direction="row" spacing={2} justifyContent="center">
                    <ButtonGroup>
                        <IconButton
                            onClick={() => setDate(date.clone().subtract(1, "day"))}
                        >
                            <ChevronLeft/>
                        </IconButton>
                        <Button>
                            {date.format("DD/MM/YYYY")}
                        </Button>
                        <IconButton
                            onClick={() => setDate(date.clone().add(1, "day"))}
                        >
                            <ChevronRight/>
                        </IconButton>
                    </ButtonGroup>
                </Stack>
                <Stack direction="column" spacing={2} justifyContent="center">
                    {
                        bookings.length !== 0 ?
                            bookings.map((booking, index) => <CardPrenotazione key={booking.id} booking={booking} dateVisible={false} />)
                            :
                            <Typography level="body-sm" textAlign="center" pt={4}>Non ci sono prenotazioni per il giorno {date.format("DD/MM/YYYY")}</Typography>
                    }
                </Stack>
            </Stack>
        </PageStandard>
    )
}

export function CardPrenotazione(props: {booking: BookingFormattedModel, dateVisible: boolean}) {
    const booking = props.booking

    const isBefore = moment(`${booking.date} ${booking.end}`).isBefore(moment())

    return (
        <Link to={"/prenotazioni/prenotazione?id=" + booking.id}>
            <Card className="hoverScale" sx={{opacity: isBefore ? "0.75" : "1"}}>
                <CardContent>
                    <Stack spacing={1}>
                        <Typography level="body-md"
                                    sx={{
                                        fontWeight:600,
                                        lineHeight:"1em"
                                    }}
                        >
                            {booking.customer}
                        </Typography>

                        <Chip size="sm" variant="soft" color="primary">{booking.service}</Chip>
                        {
                            props.dateVisible ?
                                <>
                                    <Typography level="body-sm"
                                                sx={{
                                                    lineHeight:"1em"
                                                }}
                                    >
                                        Data: {moment(booking.date).format("DD/MM/YYYY")}
                                    </Typography>
                                    <Typography level="body-sm"
                                                sx={{
                                                    lineHeight:"1em"
                                                }}
                                    >
                                        Ora: {booking.start} - {booking.end}
                                    </Typography>
                                </>
                                :
                                <Typography level="body-sm"
                                            sx={{
                                                lineHeight:"1em"
                                            }}
                                >
                                    {booking.start} - {booking.end}
                                </Typography>
                        }
                    </Stack>
                </CardContent>
            </Card>
        </Link>
    )
}