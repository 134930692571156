import {PageStandard} from "../utils/PageStandard";
import React, {ReactNode, useContext, useEffect, useState} from "react";
import {AuthContext} from "../utils/AuthProvider";
import {FeedbackContext} from "../utils/FeedbackContext";
import {
    Alert,
    AspectRatio,
    Box,
    Button,
    Card,
    CardContent, FormControl, FormHelperText,
    FormLabel,
    Grid, List, ListItem, ListItemDecorator, Option, Radio, RadioGroup,
    Select,
    Sheet,
    Stack, Switch, Tooltip,
    Typography
} from "@mui/joy";
import {Apartment, ArrowForward, CalendarMonth, Check, Close, People, Person, Warning} from "@mui/icons-material";
import {AlertModel, BookingFormattedModel} from "../utils/Model";
import firebase from "firebase/compat/app";
import {APIurl, minutesToHoursString} from "../utils/Utils";
import {Link} from "react-router-dom";
import moment from "moment";
import {CardPrenotazione} from "./bookings/Agenda";
import {LogoOctopus} from "../assets/Logo";

export function ConfigurazioneIniziale() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const enum TABS {
        WELCOME,
        SLOT_MINIMO,
        DELAY_MINIMO_MASSIMO_PRENOTAZIONE,
        CONSENTIRE_MODIFICA_PRENOTAZIONI,
        CONSENTIRE_ANNULLAMENTO_PRENOTAZIONI,
        REMINDER_EMAIL,
        REMINDER_WHATSAPP,
        TURNI_AUTOMATICI,
        END_SETUP
    }
    const [tab, setTab] = useState<TABS>(TABS.WELCOME)

    const gestionaleSettingsInitial = {
        prenotazioniAttive: true,
        reminderEmail: true,
        emailConfirmation: true,
        emailEdit: false,
        emailDelete: false,
        emailReminderOneDayBefore: false,
        emailReminderSameDay: true,
        reminderWhatsapp: false,
        whatsappConfirmation: false,
        whatsappEdit: false,
        whatsappDelete: false,
        whatsappReminderOneDayBefore: false,
        whatsappReminderSameDay: false,
        customerEditBooking: true,
        customerEditBookingDelay: 1,
        customerDeleteBooking: true,
        customerDeleteBookingDelay: 1,
        customerMinDelay: 1,
        customerMaxDelay: 14,
        turniAutomatici: true,
        turniDelay: 3,
        slotMinimo: 30
    }
    const [gestionaleSettings, setGestionaleSettings] = useState(gestionaleSettingsInitial)

    return (
        <Box sx={{maxHeight:"100vh", textAlign: {xs:"center", md:"start"}}}>
            {switchTabs()}
        </Box>
    )

    function switchTabs() {
        switch (tab) {
            case TABS.WELCOME:
                return <TabWelcome />
            case TABS.SLOT_MINIMO:
                return <TabSlotMinimo />
            case TABS.DELAY_MINIMO_MASSIMO_PRENOTAZIONE:
                return <TabDelayMinimoMassimoPrenotazione />
            case TABS.CONSENTIRE_MODIFICA_PRENOTAZIONI:
                return <TabConsentireModificaPrenotazione />
            case TABS.CONSENTIRE_ANNULLAMENTO_PRENOTAZIONI:
                return <TabConsentireAnnullamentoPrenotazione />
            case TABS.REMINDER_EMAIL:
                return <TabReminderEmail />
            case TABS.REMINDER_WHATSAPP:
                return <TabReminderWhatsapp />
            case TABS.TURNI_AUTOMATICI:
                return <TabTurniAutomatici />
            case TABS.END_SETUP:
                return <TabEndSetup />
        }
    }

    function TabWelcome() {
        return (
            <Stack direction="column" spacing={1} sx={{height:"100vh", p:4}} justifyContent="center" alignItems="center">
                <Box sx={{ display: 'flex', gap: 2, flexDirection:"row", justifyContent:"center", alignItems: 'center', pb:5 }}>
                    <AspectRatio ratio={1} sx={{width:60, borderRadius:20}}>
                        <Sheet variant="soft" color="primary" >
                            <LogoOctopus width="40" height="40" color="var(--joy-palette-primary-500)" />
                        </Sheet>
                    </AspectRatio>
                    <Typography level="h3">Octopus Desk</Typography>
                </Box>

                <Typography level="h3">Ti diamo il benvenuto in Octopus Desk</Typography>
                <Typography level="body-sm" pb={5}>E' importante impostare correttamente tutto per poter iniziare con le prenotazioni.</Typography>
                <Button onClick={() => nextTab()}>
                    Iniziamo la configurazione
                </Button>
            </Stack>
        )
    }

    function TabSlotMinimo() {

        const generateTimeSlots = (props: {s: number, e: number}) => {
            const start = moment().set({ hour: props.s, minute: 0 });
            const end = moment().set({ hour: props.e, minute: 0 });
            const timeSlots = [];

            while (start.isBefore(end)) {
                timeSlots.push(moment(start));
                start.add(gestionaleSettings.slotMinimo, 'minutes');
            }

            return timeSlots;
        };

        return (
            <Stack direction="column" spacing={4} sx={{height:"100vh", p:4}} justifyContent="space-between" alignItems="center">
                <Box sx={{ display: 'flex', gap: 2, flexDirection:"row", justifyContent:"center", alignItems: 'center' }}>
                    <AspectRatio ratio={1} sx={{width:60, borderRadius:20}}>
                        <Sheet variant="soft" color="primary" >
                            <LogoOctopus width="40" height="40" color="var(--joy-palette-primary-500)" />
                        </Sheet>
                    </AspectRatio>
                    <Typography level="h3">Octopus Desk</Typography>
                </Box>

                <Stack direction={{xs:"column", md:"row"}} spacing={5} justifyContent="space-evenly" alignItems="center" sx={{flexGrow:1}}>
                    <Stack direction="column" spacing={0}>
                        <Typography level="h3" mb={2}>Slot minimo</Typography>
                        <Typography level="body-md">E' la base della struttura dei calendari nel gestionale.</Typography>
                        <Typography level="body-md" mb={2}>Indica, ad esempio, ogni quanto il cliente può prenotare uno slot.</Typography>
                        <Select
                            size="md"
                            placeholder="Scegli..."
                            slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                            value={gestionaleSettings.slotMinimo}
                            onChange={(e, newValue) => setGestionaleSettings({
                                ...gestionaleSettings,
                                slotMinimo: Number(newValue!)
                            })}
                        >
                            <Option value="" disabled>Scegli...</Option>
                            {
                                Array.from({length: 12}, (_, i) => (i + 1)*15)
                                    .map(a => <Option value={a}
                                                      key={a}>{minutesToHoursString(a)}</Option>)
                            }
                        </Select>
                        <FormHelperText sx={{mt:2}}>Slot minimo consigliato: 30 minuti</FormHelperText>
                    </Stack>
                    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" sx={{maxWidth: {xs:"75vw", md:"30vw"}}}>
                        <Typography level="h4">Esempio di prenotazione</Typography>
                        <Stack direction="row" spacing={2}>
                            <Stack direction="column" spacing={2}>
                                <Typography level="body-lg" fontWeight="bold" textAlign="center">
                                    {moment().format("ddd DD/MM")}
                                </Typography>
                                <Stack direction="column" spacing={1} sx={{maxHeight: {xs:"60vh", md:"50vh"}, pl: 1}}
                                       className="scrollbarOrari">
                                    {
                                        generateTimeSlots({s: 9, e: 14}).map((s, index) =>
                                            <Button variant="outlined"
                                                    size="sm"
                                                    key={index}
                                            >
                                                {s.format("HH:mm")}
                                            </Button>
                                        )
                                    }
                                </Stack>
                            </Stack>
                            <Stack direction="column" spacing={2}>
                                <Typography level="body-lg" fontWeight="bold" textAlign="center">
                                    {moment().add(1, "day").format("ddd DD/MM")}
                                </Typography>
                                <Stack direction="column" spacing={1} sx={{maxHeight: {xs:"60vh", md:"50vh"}, pl: 1}}
                                       className="scrollbarOrari">
                                    {
                                        generateTimeSlots({s: 12, e: 15}).map((s, index) =>
                                            <Button variant="outlined"
                                                    size="sm"
                                                    key={index}
                                            >
                                                {s.format("HH:mm")}
                                            </Button>
                                        )
                                    }
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} sx={{width:"100vw"}} justifyContent="space-between" alignItems="center" px={5} pb={4}>
                    <Button onClick={() => setTab(tab-1)} variant="outlined">
                        Indietro
                    </Button>
                    <Button onClick={() => nextTab()}>
                        Avanti
                    </Button>
                </Stack>
            </Stack>
        )
    }

    function TabDelayMinimoMassimoPrenotazione() {

        return (
            <Stack direction="column" spacing={4} sx={{height:"100vh", p:5}} justifyContent="space-between" alignItems="center">
                <Box sx={{ display: 'flex', gap: 2, flexDirection:"row", justifyContent:"center", alignItems: 'center' }}>
                    <AspectRatio ratio={1} sx={{width:60, borderRadius:20}}>
                        <Sheet variant="soft" color="primary" >
                            <LogoOctopus width="40" height="40" color="var(--joy-palette-primary-500)" />
                        </Sheet>
                    </AspectRatio>
                    <Typography level="h3">Octopus Desk</Typography>
                </Box>

                <Stack direction={{xs:"column", md:"row"}} spacing={5} justifyContent="space-evenly" alignItems="center" sx={{flexGrow:1}}>
                    <Stack direction="column" spacing={0}>
                        <Typography level="h3" mb={2}>Delay minimo e massimo per prenotazioni</Typography>
                        <Typography level="body-md" mb={2}>Indica l'intervallo di tempo in cui i clienti possono prenotare uno slot.</Typography>
                        <Stack direction="row" spacing={2}>
                            <FormControl sx={{flexGrow:1}}>
                                <FormLabel>Minimo</FormLabel>
                                <Select
                                    size="md"
                                    placeholder="Scegli..."
                                    slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                                    value={gestionaleSettings.customerMinDelay}
                                    onChange={(e, newValue) => setGestionaleSettings({
                                        ...gestionaleSettings,
                                        customerMinDelay: Number(newValue!)
                                    })}
                                >
                                    <Option value="" disabled>Scegli...</Option>
                                    {
                                        Array.from({length: 30}, (_, i) => i + 1)
                                            .map(a => <Option value={a}
                                                              key={a}>{a} giorni</Option>)
                                    }
                                </Select>
                                <FormHelperText sx={{mt:1}}>Valore consigliato: 1 giorno</FormHelperText>
                            </FormControl>
                            <FormControl sx={{flexGrow:1}}>
                                <FormLabel>Massimo</FormLabel>
                                <Select
                                    size="md"
                                    placeholder="Scegli..."
                                    slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                                    value={gestionaleSettings.customerMaxDelay}
                                    onChange={(e, newValue) => setGestionaleSettings({
                                        ...gestionaleSettings,
                                        customerMaxDelay: Number(newValue!)
                                    })}
                                >
                                    <Option value="" disabled>Scegli...</Option>
                                    {
                                        Array.from({length: 30}, (_, i) => i + 1)
                                            .map(a => <Option value={a}
                                                              key={a}>{a} giorni</Option>)
                                    }
                                </Select>
                                <FormHelperText sx={{mt:1}}>Valore consigliato: 14 giorni</FormHelperText>
                            </FormControl>
                        </Stack>
                    </Stack>
                    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" sx={{maxWidth: {xs:"75vw", md:"30vw"}}}>
                        <Typography level="h4">Esempio di prenotazione</Typography>
                        <Stack direction="row" spacing={2}>
                            <Stack direction="column" spacing={2}>
                                <Typography level="body-md" textAlign="center">Se un cliente prenotasse adesso,<br/>vedrebbe tutte le disponibilità da <b>{moment().add(gestionaleSettings.customerMinDelay, "days").format("dddd DD/MM")}</b> a <b>{moment().add(gestionaleSettings.customerMaxDelay, "days").format("dddd DD/MM")}</b>.</Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} sx={{width:"100vw"}} justifyContent="space-between" alignItems="center" px={5} pb={4}>
                    <Button onClick={() => setTab(tab-1)} variant="outlined">
                        Indietro
                    </Button>
                    <Button onClick={() => nextTab()}>
                        Avanti
                    </Button>
                </Stack>
            </Stack>
        )
    }

    function TabConsentireModificaPrenotazione() {

        return (
            <Stack direction="column" spacing={4} sx={{height:"100vh", p:5}} justifyContent="space-between" alignItems="center">
                <Box sx={{ display: 'flex', gap: 2, flexDirection:"row", justifyContent:"center", alignItems: 'center' }}>
                    <AspectRatio ratio={1} sx={{width:60, borderRadius:20}}>
                        <Sheet variant="soft" color="primary" >
                            <LogoOctopus width="40" height="40" color="var(--joy-palette-primary-500)" />
                        </Sheet>
                    </AspectRatio>
                    <Typography level="h3">Octopus Desk</Typography>
                </Box>

                <Stack direction={{xs:"column", md:"row"}} spacing={5} justifyContent="space-evenly" alignItems="center" sx={{flexGrow:1}}>
                    <Stack direction="column" spacing={0}>
                        <Typography level="h3" mb={1}>Vuoi consentire la modifica della prenotazione da parte del cliente?</Typography>
                        <Typography level="body-md" mb={2}>Se selezioni Sì, il cliente può modificare in modo autonomo la propria prenotazione.</Typography>
                        <RadioGroup>
                            <List
                                sx={{
                                    '--List-gap': '0.5rem',
                                    '--ListItem-paddingY': '1rem',
                                    '--ListItem-radius': '8px',
                                    '--ListItemDecorator-size': '32px',
                                    padding: 0
                                }}
                                orientation="horizontal"
                            >
                                <ListItem variant="outlined" sx={{ boxShadow: 'sm', flexGrow:1 }}>
                                    <ListItemDecorator>
                                        <Check />
                                    </ListItemDecorator>
                                    <Radio
                                        overlay
                                        value={gestionaleSettings.customerEditBooking}
                                        checked={gestionaleSettings.customerEditBooking}
                                        onClick={() => {
                                            if(!gestionaleSettings.customerEditBooking) {
                                                setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    customerEditBooking: true
                                                })
                                            }
                                        }}
                                        label="Sì"
                                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                                        slotProps={{
                                            action: ({ checked }) => ({
                                                sx: (theme) => ({
                                                    ...(checked && {
                                                        inset: -1,
                                                        border: '2px solid',
                                                        borderColor: theme.vars.palette.success[500],
                                                    }),
                                                }),
                                            }),
                                        }}
                                    />
                                </ListItem>
                                <ListItem variant="outlined" sx={{ boxShadow: 'sm', flexGrow:1 }}>
                                    <ListItemDecorator>
                                        <Close />
                                    </ListItemDecorator>
                                    <Radio
                                        overlay
                                        value={!gestionaleSettings.customerEditBooking}
                                        checked={!gestionaleSettings.customerEditBooking}
                                        onClick={() => {
                                            if(gestionaleSettings.customerEditBooking) {
                                                setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    customerEditBooking: false
                                                })
                                            }
                                        }}
                                        label="No"
                                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                                        slotProps={{
                                            action: ({ checked }) => ({
                                                sx: (theme) => ({
                                                    ...(checked && {
                                                        inset: -1,
                                                        border: '2px solid',
                                                        borderColor: theme.vars.palette.danger[500],
                                                    }),
                                                }),
                                            }),
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </RadioGroup>

                        {
                            gestionaleSettings.customerEditBooking &&
                            <>
                                <Typography level="h4" mt={5} mb={1}>Fino a quante ore prima della prenotazione il cliente può modificare la prenotazione?</Typography>
                                <FormControl sx={{flexGrow:1}}>
                                    <Select
                                        size="md"
                                        placeholder="Scegli..."
                                        slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                                        value={gestionaleSettings.customerEditBookingDelay}
                                        onChange={(e, newValue) => setGestionaleSettings({
                                            ...gestionaleSettings,
                                            customerEditBookingDelay: Number(newValue!)
                                        })}
                                    >
                                        <Option value="" disabled>Scegli...</Option>
                                        {
                                            Array.from({length: 48}, (_, i) => i + 1)
                                                .map(a => <Option value={a}
                                                                  key={a}>{a} ore</Option>)
                                        }
                                    </Select>
                                    <FormHelperText sx={{mt:1}}>Valore consigliato: 1 ora</FormHelperText>
                                </FormControl>
                            </>
                        }
                    </Stack>
                    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" sx={{maxWidth: {xs:"75vw", md:"30vw"}}}>
                        <Typography level="h4">Esempio di prenotazione</Typography>
                        <Stack direction="row" spacing={2}>
                            <Stack direction="column" spacing={2}>
                                {
                                    gestionaleSettings.customerEditBooking ?
                                        <Typography level="body-md" textAlign="center">Il cliente può modificare la prenotazione fino a <b>{gestionaleSettings.customerEditBookingDelay} ore</b> prima dell'inizio della prenotazione.</Typography>
                                        :
                                        <Typography level="body-md" textAlign="center">Il cliente non può modificare la prenotazione.</Typography>
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} sx={{width:"100vw"}} justifyContent="space-between" alignItems="center" px={5} pb={4}>
                    <Button onClick={() => setTab(tab-1)} variant="outlined">
                        Indietro
                    </Button>
                    <Button onClick={() => nextTab()}>
                        Avanti
                    </Button>
                </Stack>
            </Stack>
        )
    }

    function TabConsentireAnnullamentoPrenotazione() {

        return (
            <Stack direction="column" spacing={4} sx={{height:"100vh", p:5}} justifyContent="space-between" alignItems="center">
                <Box sx={{ display: 'flex', gap: 2, flexDirection:"row", justifyContent:"center", alignItems: 'center' }}>
                    <AspectRatio ratio={1} sx={{width:60, borderRadius:20}}>
                        <Sheet variant="soft" color="primary" >
                            <LogoOctopus width="40" height="40" color="var(--joy-palette-primary-500)" />
                        </Sheet>
                    </AspectRatio>
                    <Typography level="h3">Octopus Desk</Typography>
                </Box>

                <Stack direction={{xs:"column", md:"row"}} spacing={5} justifyContent="space-evenly" alignItems="center" sx={{flexGrow:1}}>
                    <Stack direction="column" spacing={0}>
                        <Typography level="h3" mb={1}>Vuoi consentire l'annullamento della prenotazione da parte del cliente?</Typography>
                        <Typography level="body-md" mb={2}>Se selezioni Sì, il cliente può annullare in modo autonomo la propria prenotazione.</Typography>
                        <RadioGroup>
                            <List
                                sx={{
                                    '--List-gap': '0.5rem',
                                    '--ListItem-paddingY': '1rem',
                                    '--ListItem-radius': '8px',
                                    '--ListItemDecorator-size': '32px',
                                    padding: 0
                                }}
                                orientation="horizontal"
                            >
                                <ListItem variant="outlined" sx={{ boxShadow: 'sm', flexGrow:1 }}>
                                    <ListItemDecorator>
                                        <Check />
                                    </ListItemDecorator>
                                    <Radio
                                        overlay
                                        value={gestionaleSettings.customerDeleteBooking}
                                        checked={gestionaleSettings.customerDeleteBooking}
                                        onClick={() => {
                                            if(!gestionaleSettings.customerDeleteBooking) {
                                                setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    customerDeleteBooking: true
                                                })
                                            }
                                        }}
                                        label="Sì"
                                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                                        slotProps={{
                                            action: ({ checked }) => ({
                                                sx: (theme) => ({
                                                    ...(checked && {
                                                        inset: -1,
                                                        border: '2px solid',
                                                        borderColor: theme.vars.palette.success[500],
                                                    }),
                                                }),
                                            }),
                                        }}
                                    />
                                </ListItem>
                                <ListItem variant="outlined" sx={{ boxShadow: 'sm', flexGrow:1 }}>
                                    <ListItemDecorator>
                                        <Close />
                                    </ListItemDecorator>
                                    <Radio
                                        overlay
                                        value={!gestionaleSettings.customerDeleteBooking}
                                        checked={!gestionaleSettings.customerDeleteBooking}
                                        onClick={() => {
                                            if(gestionaleSettings.customerDeleteBooking) {
                                                setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    customerDeleteBooking: false
                                                })
                                            }
                                        }}
                                        label="No"
                                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                                        slotProps={{
                                            action: ({ checked }) => ({
                                                sx: (theme) => ({
                                                    ...(checked && {
                                                        inset: -1,
                                                        border: '2px solid',
                                                        borderColor: theme.vars.palette.danger[500],
                                                    }),
                                                }),
                                            }),
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </RadioGroup>

                        {
                            gestionaleSettings.customerDeleteBooking &&
                            <>
                                <Typography level="h4" mt={5} mb={1}>Fino a quante ore prima della prenotazione il cliente può annullare la prenotazione?</Typography>
                                <FormControl sx={{flexGrow:1}}>
                                    <Select
                                        size="md"
                                        placeholder="Scegli..."
                                        slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                                        value={gestionaleSettings.customerDeleteBookingDelay}
                                        onChange={(e, newValue) => setGestionaleSettings({
                                            ...gestionaleSettings,
                                            customerDeleteBookingDelay: Number(newValue!)
                                        })}
                                    >
                                        <Option value="" disabled>Scegli...</Option>
                                        {
                                            Array.from({length: 48}, (_, i) => i + 1)
                                                .map(a => <Option value={a}
                                                                  key={a}>{a} ore</Option>)
                                        }
                                    </Select>
                                    <FormHelperText sx={{mt:1}}>Valore consigliato: 1 ora</FormHelperText>
                                </FormControl>
                            </>
                        }
                    </Stack>
                    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" sx={{maxWidth: {xs:"75vw", md:"30vw"}}}>
                        <Typography level="h4">Esempio di prenotazione</Typography>
                        <Stack direction="row" spacing={2}>
                            <Stack direction="column" spacing={2}>
                                {
                                    gestionaleSettings.customerDeleteBooking ?
                                        <Typography level="body-md" textAlign="center">Il cliente può annullare la prenotazione fino a <b>{gestionaleSettings.customerDeleteBookingDelay} ore</b> prima dell'inizio della prenotazione.</Typography>
                                        :
                                        <Typography level="body-md" textAlign="center">Il cliente non può annullare la prenotazione.</Typography>
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} sx={{width:"100vw"}} justifyContent="space-between" alignItems="center" px={5} pb={4}>
                    <Button onClick={() => setTab(tab-1)} variant="outlined">
                        Indietro
                    </Button>
                    <Button onClick={() => nextTab()}>
                        Avanti
                    </Button>
                </Stack>
            </Stack>
        )
    }

    function TabReminderEmail() {

        return (
            <Stack direction="column" spacing={4} sx={{height:"100vh", p:5}} justifyContent="space-between" alignItems="center">
                <Box sx={{ display: 'flex', gap: 2, flexDirection:"row", justifyContent:"center", alignItems: 'center' }}>
                    <AspectRatio ratio={1} sx={{width:60, borderRadius:20}}>
                        <Sheet variant="soft" color="primary" >
                            <LogoOctopus width="40" height="40" color="var(--joy-palette-primary-500)" />
                        </Sheet>
                    </AspectRatio>
                    <Typography level="h3">Octopus Desk</Typography>
                </Box>

                <Stack direction={{xs:"column", md:"row"}} spacing={5} justifyContent="space-evenly" alignItems="center" sx={{flexGrow:1}}>
                    <Stack direction="column" spacing={0}>
                        <Typography level="h3" mb={1}>Vuoi abilitare i reminder via email?</Typography>
                        <Typography level="body-md" mb={2}>Se selezioni Sì, il cliente riceverà informazioni sulla sua prenotazione via email.<br/>I reminder via email sono gratuiti.</Typography>
                        <RadioGroup>
                            <List
                                sx={{
                                    '--List-gap': '0.5rem',
                                    '--ListItem-paddingY': '1rem',
                                    '--ListItem-radius': '8px',
                                    '--ListItemDecorator-size': '32px',
                                    padding: 0
                                }}
                                orientation="horizontal"
                            >
                                <ListItem variant="outlined" sx={{ boxShadow: 'sm', flexGrow:1 }}>
                                    <ListItemDecorator>
                                        <Check />
                                    </ListItemDecorator>
                                    <Radio
                                        overlay
                                        value={gestionaleSettings.reminderEmail}
                                        checked={gestionaleSettings.reminderEmail}
                                        onClick={() => {
                                            if(!gestionaleSettings.reminderEmail) {
                                                setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    reminderEmail: true
                                                })
                                            }
                                        }}
                                        label="Sì"
                                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                                        slotProps={{
                                            action: ({ checked }) => ({
                                                sx: (theme) => ({
                                                    ...(checked && {
                                                        inset: -1,
                                                        border: '2px solid',
                                                        borderColor: theme.vars.palette.success[500],
                                                    }),
                                                }),
                                            }),
                                        }}
                                    />
                                </ListItem>
                                <ListItem variant="outlined" sx={{ boxShadow: 'sm', flexGrow:1 }}>
                                    <ListItemDecorator>
                                        <Close />
                                    </ListItemDecorator>
                                    <Radio
                                        overlay
                                        value={!gestionaleSettings.reminderEmail}
                                        checked={!gestionaleSettings.reminderEmail}
                                        onClick={() => {
                                            if(gestionaleSettings.reminderEmail) {
                                                setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    reminderEmail: false
                                                })
                                            }
                                        }}
                                        label="No"
                                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                                        slotProps={{
                                            action: ({ checked }) => ({
                                                sx: (theme) => ({
                                                    ...(checked && {
                                                        inset: -1,
                                                        border: '2px solid',
                                                        borderColor: theme.vars.palette.danger[500],
                                                    }),
                                                }),
                                            }),
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </RadioGroup>

                        {
                            gestionaleSettings.reminderEmail &&
                            <>
                                <Tooltip title="La mail di conferma viene inviata appena l'utente crea una prenotazione."
                                         placement="bottom-start"
                                >
                                    <Typography level="h4" mt={4} textAlign="start" sx={{width:"auto"}} startDecorator={
                                        <Switch
                                            checked={gestionaleSettings.emailConfirmation}
                                            onChange={(event) => setGestionaleSettings({
                                                ...gestionaleSettings,
                                                emailConfirmation: event.target.checked
                                            })}
                                            sx={{mr: 2}}
                                        />
                                    }>
                                        Conferma prenotazione
                                    </Typography>
                                </Tooltip>
                                <Tooltip title="La mail di modifica viene inviata quando la prenotazione viene modificata."
                                         placement="bottom-start"
                                >
                                    <Typography level="h4" mt={1} textAlign="start" sx={{width:"auto"}} startDecorator={
                                        <Switch
                                            checked={gestionaleSettings.emailEdit}
                                            onChange={(event) => setGestionaleSettings({
                                                ...gestionaleSettings,
                                                emailEdit: event.target.checked
                                            })}
                                            sx={{mr: 2}}
                                        />
                                    }>
                                        Modifica prenotazione
                                    </Typography>
                                </Tooltip>
                                <Tooltip title="La mail di annullamento viene inviata quando la prenotazione viene annullata."
                                         placement="bottom-start"
                                >
                                    <Typography level="h4" mt={1} textAlign="start" sx={{width:"auto"}} startDecorator={
                                        <Switch
                                            checked={gestionaleSettings.emailDelete}
                                            onChange={(event) => setGestionaleSettings({
                                                ...gestionaleSettings,
                                                emailDelete: event.target.checked
                                            })}
                                            sx={{mr: 2}}
                                        />
                                    }>
                                        Annullamento prenotazione
                                    </Typography>
                                </Tooltip>
                                <Tooltip title="Questa mail viene inviata il giorno prima dell'appuntamento."
                                         placement="bottom-start"
                                >
                                    <Typography level="h4" mt={1} textAlign="start" sx={{width:"auto"}} startDecorator={
                                        <Switch
                                            checked={gestionaleSettings.emailReminderOneDayBefore}
                                            onChange={(event) => setGestionaleSettings({
                                                ...gestionaleSettings,
                                                emailReminderOneDayBefore: event.target.checked
                                            })}
                                            sx={{mr: 2}}
                                        />
                                    }>
                                        Reminder giorno precedente alla prenotazione
                                    </Typography>
                                </Tooltip>
                                <Tooltip title="Questa mail viene inviata il giorno stesso dell'appuntamento."
                                         placement="bottom-start"
                                >
                                    <Typography level="h4" mt={1} textAlign="start" sx={{width:"auto"}} startDecorator={
                                        <Switch
                                            checked={gestionaleSettings.emailReminderSameDay}
                                            onChange={(event) => setGestionaleSettings({
                                                ...gestionaleSettings,
                                                emailReminderSameDay: event.target.checked
                                            })}
                                            sx={{mr: 2}}
                                        />
                                    }>
                                        Reminder stesso giorno della prenotazione
                                    </Typography>
                                </Tooltip>
                            </>
                        }
                    </Stack>
                    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" sx={{maxWidth: {xs:"75vw", md:"30vw"}}}>
                        <Typography level="h4">Reminder email</Typography>
                        <Stack direction="row" spacing={2}>
                            <Stack direction="column" spacing={2}>
                                {
                                    gestionaleSettings.reminderEmail ?
                                        <Typography level="body-md" textAlign="center">Il cliente riceverà aggiornamenti via mail sulla prenotazione.</Typography>
                                        :
                                        <Typography level="body-md" textAlign="center">Il cliente non riceverà nessuna mail sulla prenotazione.</Typography>
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} sx={{width:"100vw"}} justifyContent="space-between" alignItems="center" px={5} pb={4}>
                    <Button onClick={() => setTab(tab-1)} variant="outlined">
                        Indietro
                    </Button>
                    <Button onClick={() => nextTab()}>
                        Avanti
                    </Button>
                </Stack>
            </Stack>
        )
    }

    function TabReminderWhatsapp() {

        return (
            <Stack direction="column" spacing={4} sx={{height:"100vh", p:5}} justifyContent="space-between" alignItems="center">
                <Box sx={{ display: 'flex', gap: 2, flexDirection:"row", justifyContent:"center", alignItems: 'center' }}>
                    <AspectRatio ratio={1} sx={{width:60, borderRadius:20}}>
                        <Sheet variant="soft" color="primary" >
                            <LogoOctopus width="40" height="40" color="var(--joy-palette-primary-500)" />
                        </Sheet>
                    </AspectRatio>
                    <Typography level="h3">Octopus Desk</Typography>
                </Box>

                <Stack direction={{xs:"column", md:"row"}} spacing={5} justifyContent="space-evenly" alignItems="center" sx={{flexGrow:1}}>
                    <Stack direction="column" spacing={0}>
                        <Typography level="h3" mb={1}>Vuoi abilitare i reminder via WhatsApp?</Typography>
                        <Typography level="body-md" mb={2}>Se selezioni Sì, il cliente riceverà informazioni sulla sua prenotazione via WhatsApp.<br/>I reminder via WhatsApp sono soggetti a tariffazione a consumo. Consulta le <a href="https://www.octopusdesk.it/reminder-whatsapp" rel="noreferrer" target="_blank">tariffe</a>.</Typography>
                        <RadioGroup>
                            <List
                                sx={{
                                    '--List-gap': '0.5rem',
                                    '--ListItem-paddingY': '1rem',
                                    '--ListItem-radius': '8px',
                                    '--ListItemDecorator-size': '32px',
                                    padding: 0
                                }}
                                orientation="horizontal"
                            >
                                <ListItem variant="outlined" sx={{ boxShadow: 'sm', flexGrow:1 }}>
                                    <ListItemDecorator>
                                        <Check />
                                    </ListItemDecorator>
                                    <Radio
                                        overlay
                                        value={gestionaleSettings.reminderWhatsapp}
                                        checked={gestionaleSettings.reminderWhatsapp}
                                        onClick={() => {
                                            if(!gestionaleSettings.reminderWhatsapp) {
                                                setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    reminderWhatsapp: true
                                                })
                                            }
                                        }}
                                        label="Sì"
                                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                                        slotProps={{
                                            action: ({ checked }) => ({
                                                sx: (theme) => ({
                                                    ...(checked && {
                                                        inset: -1,
                                                        border: '2px solid',
                                                        borderColor: theme.vars.palette.success[500],
                                                    }),
                                                }),
                                            }),
                                        }}
                                    />
                                </ListItem>
                                <ListItem variant="outlined" sx={{ boxShadow: 'sm', flexGrow:1 }}>
                                    <ListItemDecorator>
                                        <Close />
                                    </ListItemDecorator>
                                    <Radio
                                        overlay
                                        value={!gestionaleSettings.reminderWhatsapp}
                                        checked={!gestionaleSettings.reminderWhatsapp}
                                        onClick={() => {
                                            if(gestionaleSettings.reminderWhatsapp) {
                                                setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    reminderWhatsapp: false
                                                })
                                            }
                                        }}
                                        label="No"
                                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                                        slotProps={{
                                            action: ({ checked }) => ({
                                                sx: (theme) => ({
                                                    ...(checked && {
                                                        inset: -1,
                                                        border: '2px solid',
                                                        borderColor: theme.vars.palette.danger[500],
                                                    }),
                                                }),
                                            }),
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </RadioGroup>

                        {
                            gestionaleSettings.reminderWhatsapp &&
                            <>
                                <Tooltip title="Il messaggio di conferma viene inviato appena l'utente crea una prenotazione."
                                         placement="bottom-start"
                                >
                                    <Typography level="h4" mt={4} textAlign="start" sx={{width:"auto"}} startDecorator={
                                        <Switch
                                            checked={gestionaleSettings.whatsappConfirmation}
                                            onChange={(event) => setGestionaleSettings({
                                                ...gestionaleSettings,
                                                whatsappConfirmation: event.target.checked
                                            })}
                                            sx={{mr: 2}}
                                        />
                                    }>
                                        Conferma prenotazione
                                    </Typography>
                                </Tooltip>
                                <Tooltip title="Il messaggio di modifica viene inviato quando la prenotazione viene modificata."
                                         placement="bottom-start"
                                >
                                    <Typography level="h4" mt={1} textAlign="start" sx={{width:"auto"}} startDecorator={
                                        <Switch
                                            checked={gestionaleSettings.whatsappEdit}
                                            onChange={(event) => setGestionaleSettings({
                                                ...gestionaleSettings,
                                                whatsappEdit: event.target.checked
                                            })}
                                            sx={{mr: 2}}
                                        />
                                    }>
                                        Modifica prenotazione
                                    </Typography>
                                </Tooltip>
                                <Tooltip title="Il messaggio di annullamento viene inviato quando la prenotazione viene annullata."
                                         placement="bottom-start"
                                >
                                    <Typography level="h4" mt={1} textAlign="start" sx={{width:"auto"}} startDecorator={
                                        <Switch
                                            checked={gestionaleSettings.whatsappDelete}
                                            onChange={(event) => setGestionaleSettings({
                                                ...gestionaleSettings,
                                                whatsappDelete: event.target.checked
                                            })}
                                            sx={{mr: 2}}
                                        />
                                    }>
                                        Annullamento prenotazione
                                    </Typography>
                                </Tooltip>
                                <Tooltip title="Questo messaggio viene inviato il giorno prima dell'appuntamento."
                                         placement="bottom-start"
                                >
                                    <Typography level="h4" mt={1} textAlign="start" sx={{width:"auto"}} startDecorator={
                                        <Switch
                                            checked={gestionaleSettings.whatsappReminderOneDayBefore}
                                            onChange={(event) => setGestionaleSettings({
                                                ...gestionaleSettings,
                                                whatsappReminderOneDayBefore: event.target.checked
                                            })}
                                            sx={{mr: 2}}
                                        />
                                    }>
                                        Reminder giorno precedente alla prenotazione
                                    </Typography>
                                </Tooltip>
                                <Tooltip title="Questo messaggio viene inviato il giorno stesso dell'appuntamento."
                                         placement="bottom-start"
                                >
                                    <Typography level="h4" mt={1} textAlign="start" sx={{width:"auto"}} startDecorator={
                                        <Switch
                                            checked={gestionaleSettings.whatsappReminderSameDay}
                                            onChange={(event) => setGestionaleSettings({
                                                ...gestionaleSettings,
                                                whatsappReminderSameDay: event.target.checked
                                            })}
                                            sx={{mr: 2}}
                                        />
                                    }>
                                        Reminder stesso giorno della prenotazione
                                    </Typography>
                                </Tooltip>
                            </>
                        }
                    </Stack>
                    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" sx={{maxWidth: {xs:"75vw", md:"30vw"}}}>
                        <Typography level="h4">Reminder WhatsApp</Typography>
                        <Stack direction="row" spacing={2}>
                            <Stack direction="column" spacing={2}>
                                {
                                    gestionaleSettings.reminderWhatsapp ?
                                        <Typography level="body-md" textAlign="center">Il cliente riceverà aggiornamenti via WhatsApp sulla prenotazione.</Typography>
                                        :
                                        <Typography level="body-md" textAlign="center">Il cliente non riceverà nessun messaggio WhatsApp sulla prenotazione.</Typography>
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} sx={{width:"100vw"}} justifyContent="space-between" alignItems="center" px={5} pb={4}>
                    <Button onClick={() => setTab(tab-1)} variant="outlined">
                        Indietro
                    </Button>
                    <Button onClick={() => {
                        if(userCompany.plan.turniAutomatici) setTab(tab + 1)
                        else setTab(tab + 2)
                    }}>
                        Avanti
                    </Button>
                </Stack>
            </Stack>
        )
    }

    function TabTurniAutomatici() {

        return (
            <Stack direction="column" spacing={4} sx={{height:"100vh", p:5}} justifyContent="space-between" alignItems="center">
                <Box sx={{ display: 'flex', gap: 2, flexDirection:"row", justifyContent:"center", alignItems: 'center' }}>
                    <AspectRatio ratio={1} sx={{width:60, borderRadius:20}}>
                        <Sheet variant="soft" color="primary" >
                            <LogoOctopus width="40" height="40" color="var(--joy-palette-primary-500)" />
                        </Sheet>
                    </AspectRatio>
                    <Typography level="h3">Octopus Desk</Typography>
                </Box>

                <Stack direction={{xs:"column", md:"row"}} spacing={5} justifyContent="space-evenly" alignItems="center" sx={{flexGrow:1}}>
                    <Stack direction="column" spacing={0}>
                        <Typography level="h3" mb={1}>Vuoi abilitare la generazione automatica dei turni di lavoro?</Typography>
                        <Typography level="body-md" mb={2}>Se selezioni Sì, i turni di lavoro verranno generati automaticamente sulla base dei turni di default dei singoli dipendenti.<br/>I turni vengono generati automaticamente nella notte tra la domenica e il lunedì.</Typography>
                        <RadioGroup>
                            <List
                                sx={{
                                    '--List-gap': '0.5rem',
                                    '--ListItem-paddingY': '1rem',
                                    '--ListItem-radius': '8px',
                                    '--ListItemDecorator-size': '32px',
                                    padding: 0
                                }}
                                orientation="horizontal"
                            >
                                <ListItem variant="outlined" sx={{ boxShadow: 'sm', flexGrow:1 }}>
                                    <ListItemDecorator>
                                        <Check />
                                    </ListItemDecorator>
                                    <Radio
                                        overlay
                                        value={gestionaleSettings.turniAutomatici}
                                        checked={gestionaleSettings.turniAutomatici}
                                        onClick={() => {
                                            if(!gestionaleSettings.turniAutomatici) {
                                                setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    turniAutomatici: true
                                                })
                                            }
                                        }}
                                        label="Sì"
                                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                                        slotProps={{
                                            action: ({ checked }) => ({
                                                sx: (theme) => ({
                                                    ...(checked && {
                                                        inset: -1,
                                                        border: '2px solid',
                                                        borderColor: theme.vars.palette.success[500],
                                                    }),
                                                }),
                                            }),
                                        }}
                                    />
                                </ListItem>
                                <ListItem variant="outlined" sx={{ boxShadow: 'sm', flexGrow:1 }}>
                                    <ListItemDecorator>
                                        <Close />
                                    </ListItemDecorator>
                                    <Radio
                                        overlay
                                        value={!gestionaleSettings.turniAutomatici}
                                        checked={!gestionaleSettings.turniAutomatici}
                                        onClick={() => {
                                            if(gestionaleSettings.turniAutomatici) {
                                                setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    turniAutomatici: false
                                                })
                                            }
                                        }}
                                        label="No"
                                        sx={{ flexGrow: 1, flexDirection: 'row-reverse' }}
                                        slotProps={{
                                            action: ({ checked }) => ({
                                                sx: (theme) => ({
                                                    ...(checked && {
                                                        inset: -1,
                                                        border: '2px solid',
                                                        borderColor: theme.vars.palette.danger[500],
                                                    }),
                                                }),
                                            }),
                                        }}
                                    />
                                </ListItem>
                            </List>
                        </RadioGroup>

                        {
                            gestionaleSettings.turniAutomatici &&
                            <>
                                <Typography level="h4" mt={5}>Delay generazione turni automatici</Typography>
                                <Typography level="body-md" mb={1}>Indica tra quante settimane verranno generati i turni.</Typography>
                                <FormControl sx={{flexGrow:1}}>
                                    <Select
                                        size="md"
                                        placeholder="Scegli..."
                                        slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                                        value={gestionaleSettings.turniDelay}
                                        onChange={(e, newValue) => setGestionaleSettings({
                                            ...gestionaleSettings,
                                            turniDelay: Number(newValue!)
                                        })}
                                    >
                                        <Option value="" disabled>Scegli...</Option>
                                        {
                                            Array.from({length: 10}, (_, i) => i + 1)
                                                .map(a => <Option value={a}
                                                                  key={a}>{a} settimane</Option>)
                                        }
                                    </Select>
                                    <FormHelperText sx={{mt:1}}>Valore consigliato: 4 settimane</FormHelperText>
                                </FormControl>
                            </>
                        }
                    </Stack>
                    <Stack direction="column" spacing={2} justifyContent="center" alignItems="center" sx={{maxWidth: {xs:"75vw", md:"30vw"}}}>
                        <Typography level="h4">Esempio generazione turni</Typography>
                        <Stack direction="row" spacing={2}>
                            <Stack direction="column" spacing={2}>
                                {
                                    gestionaleSettings.turniAutomatici ?
                                        <Typography level="body-md" textAlign="center">Con questa configurazione <b>lunedì {moment().add(1, "week").startOf("week").format("DD/MM")}</b> a  mezzanotte,  verranno generati i turni della settimana <b>dal {moment().add(gestionaleSettings.turniDelay + 1, "week").startOf("week").format("DD/MM")} al {moment().add(gestionaleSettings.turniDelay + 1, "week").endOf("week").format("DD/MM")}</b>.</Typography>
                                        :
                                        <Typography level="body-md" textAlign="center">I turni non verranno generati automaticamente.</Typography>
                                }
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
                <Stack direction="row" spacing={1} sx={{width:"100vw"}} justifyContent="space-between" alignItems="center" px={5} pb={4}>
                    <Button onClick={() => setTab(tab-1)} variant="outlined">
                        Indietro
                    </Button>
                    <Button onClick={() => nextTab()}>
                        Avanti
                    </Button>
                </Stack>
            </Stack>
        )
    }

    function TabEndSetup() {

        useEffect(() => {
            setConfigurationDone().then(() => {})
        }, []);

        return (
            <Stack direction="column" spacing={1} sx={{height:"100vh"}} justifyContent="center" alignItems="center">
                <Box sx={{ display: 'flex', gap: 2, flexDirection:"row", justifyContent:"center", alignItems: 'center', pb:5 }}>
                    <AspectRatio ratio={1} sx={{width:60, borderRadius:20}}>
                        <Sheet variant="soft" color="primary" >
                            <LogoOctopus width="40" height="40" color="var(--joy-palette-primary-500)" />
                        </Sheet>
                    </AspectRatio>
                    <Typography level="h3">Octopus Desk</Typography>
                </Box>

                <Typography level="h3">Configurazione completata</Typography>
                <Typography level="body-sm" pb={5}>Hai correttamente configurato tutto.</Typography>
                <Button onClick={() => {
                    if(window.location.pathname === "/") window.location.reload()
                    else window.location.replace("/")
                }}>
                    Vai al pannello di controllo
                </Button>
            </Stack>
        )
    }
    
    function nextTab() {
        updateGestionaleSettings().then(() => setTab(tab+1))
    }

    async function updateGestionaleSettings() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/updateGestionaleSettings`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({newSettings: gestionaleSettings})
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
    async function setConfigurationDone() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/setConfigurationDone`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}