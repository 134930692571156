import {PageStandard} from "../utils/PageStandard";
import {
    AspectRatio,
    Autocomplete,
    AutocompleteOption,
    Box,
    Button,
    Card,
    CardActions,
    CardOverflow,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    Input,
    ListItemDecorator,
    Option,
    Select,
    Stack, Tab, TabList, TabPanel, Tabs,
    ToggleButtonGroup,
    Typography,
    useColorScheme
} from "@mui/joy";
import {Language, Permissions, Roles, UserModel} from "../utils/Model";
import {APIurl, capitalize, isValidPhoneNumber, TelefonoInput} from "../utils/Utils";
import React, {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../utils/FeedbackContext";
import {Computer, ContentCopy, DarkMode, LightMode, Visibility, VisibilityOff} from "@mui/icons-material";
import moment from "moment";
import {languages} from "../utils/Languages";
import {AuthContext} from "../utils/AuthProvider";
import firebase from "firebase/compat/app";
import {TurniDipendentiInner} from "./users/TurniDipendenti";
import {FerieDipendentiInner} from "./users/FerieDipendenti";


export function Settings() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany, user} = useContext(AuthContext)

    const [initialdata, setInitialData] = useState<UserModel | null>(null)
    const [data, setData] = useState<UserModel | null>(null)
    const [password, setPassword] = useState<string>("")
    const [password1, setPassword1] = useState<string>("")
    const [password2, setPassword2] = useState<string>("")
    const [pswvisible, setPswvisible] = useState<boolean>(false)
    const {mode, setMode} = useColorScheme()
    const [language, setLanguage] = useState<Language>(languages[0])

    useEffect(() => {
        fetchUser().then(() => {})
    }, []);
    console.log(user.permissions, Permissions.GESTIRE_TURNI, Permissions.CREARE_FERIE)

    return (
        <PageStandard title="Impostazioni" sidebarVisible={true}>
            <Tabs aria-label="Basic tabs" defaultValue="profilo" sx={{backgroundColor: "transparent"}}>
                <TabList>
                    <Tab value="profilo">Il mio profilo</Tab>
                    <Tab value="preferenze">Preferenze</Tab>
                    <Tab value="account">Account</Tab>
                    {
                        (user.role===Roles.ADMIN || user.permissions.includes(Permissions.GESTIRE_TURNI)) &&
                        <Tab value="turni">Turni di lavoro</Tab>
                    }
                    {
                        (user.role===Roles.ADMIN || user.permissions.includes(Permissions.CREARE_FERIE)) &&
                        <Tab value="ferie">Ferie</Tab>
                    }
                </TabList>
                <TabPanel value="profilo">
                    <TabProfilo />
                </TabPanel>
                <TabPanel value="preferenze">
                    <TabPreferenze />
                </TabPanel>
                <TabPanel value="account">
                    <TabAccount />
                </TabPanel>
                {
                    (user.role===Roles.ADMIN || user.permissions.includes(Permissions.GESTIRE_TURNI)) &&
                    <TabPanel value="turni">
                        <TabTurniDiLavoro />
                    </TabPanel>
                }
                {
                    (user.role===Roles.ADMIN || user.permissions.includes(Permissions.CREARE_FERIE)) &&
                    <TabPanel value="ferie">
                        <TabFerie />
                    </TabPanel>
                }
            </Tabs>
        </PageStandard>
    )

    function TabProfilo() {
        return (
            <Stack spacing={4}
                   sx={{
                       display: 'flex',
                       maxWidth: '800px',
                       mx: 'auto',
                       px: {xs: 2, md: 6},
                       py: {xs: 2, md: 3},
                   }}
            >
                {
                    data!==null ?
                        <Card>
                            <Box sx={{ mb: 1 }}>
                                <Typography level="title-md">Il mio profilo</Typography>
                                <Typography level="body-sm">
                                    Informazioni personali
                                </Typography>
                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                spacing={3}
                                sx={{ my: 1 }}
                            >
                                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                                    <Grid xs={12} md={6}>
                                        <FormControl>
                                            <FormLabel>Nome</FormLabel>
                                            <Input size="sm"
                                                   type="text"
                                                   placeholder="Mario"
                                                   value={data.name}
                                                   onChange={e => setData({...data, name: e.target.value})}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <FormControl>
                                            <FormLabel>Cognome</FormLabel>
                                            <Input size="sm"
                                                   type="text"
                                                   placeholder="Rossi"
                                                   value={data.surname}
                                                   onChange={e => setData({...data, surname: e.target.value})}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <FormControl>
                                            <FormLabel>Email</FormLabel>
                                            <Input size="sm"
                                                   type="email"
                                                   placeholder="nome@email.com"
                                                   value={data.email}
                                                   disabled
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <FormControl>
                                            <FormLabel>Telefono</FormLabel>
                                            <TelefonoInput size="sm"
                                                           valuePrefisso={data.phoneNumberPrefisso ?? "39"}
                                                           valuePhone={data.phoneNumber ?? ""}
                                                           onChangePrefisso={e => setData({...data, phoneNumberPrefisso: e})}
                                                           onChangePhone={e => setData({...data, phoneNumber: e})}
                                                           error={!isValidPhoneNumber(data.phoneNumberPrefisso + " " + data.phoneNumber)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <FormControl>
                                            <FormLabel>Data di nascita</FormLabel>
                                            <Input size="sm"
                                                   type="date"
                                                   value={data.birthdate}
                                                   onChange={e => setData({...data, birthdate: e.target.value})}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <FormControl>
                                            <FormLabel>Ruolo</FormLabel>
                                            <Select
                                                size="sm"
                                                placeholder="Scegli..."
                                                slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                                                value={data.role}
                                                disabled
                                            >
                                                <Option value="" disabled>Scegli...</Option>
                                                {
                                                    Object.keys(Roles).map(a => <Option value={a.toLowerCase()} key={a}>{capitalize(a.toLowerCase())}</Option>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <FormControl>
                                            <FormLabel>Permessi</FormLabel>
                                            <Select
                                                size="sm"
                                                placeholder="Scegli..."
                                                slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                                                multiple
                                                value={data.permissions}
                                                disabled
                                            >
                                                <Option value="" disabled>Scegli...</Option>
                                                {
                                                    Object.keys(Roles).map(a => <Option value={a.toLowerCase()} key={a}>{capitalize(a.toLowerCase())}</Option>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={12}>
                                        <FormControl>
                                            <FormLabel>Servizi</FormLabel>
                                            <Select
                                                size="sm"
                                                placeholder="Scegli..."
                                                slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                                                multiple
                                                renderValue={(selected) => (
                                                    <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                                                        {selected.map((selectedOption) => (
                                                            <div key={selectedOption.label!.toString()}>
                                                                <Chip variant="soft" color="primary">
                                                                    {selectedOption.label}
                                                                </Chip>
                                                            </div>
                                                        ))}
                                                    </Box>
                                                )}
                                                value={data.services}
                                                disabled
                                            >
                                                <Option value="" disabled>Scegli...</Option>
                                                {
                                                    Object.keys(Roles).map(a => <Option value={a.toLowerCase()} key={a}>{capitalize(a.toLowerCase())}</Option>)
                                                }
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Stack>
                            <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                                <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                                    <Button size="sm"
                                            variant="solid"
                                            disabled={data.name==="" || data.surname==="" || data.email==="" || loading || data===initialdata}
                                            onClick={() => updateUser()}
                                    >
                                        Aggiorna dati
                                    </Button>
                                </CardActions>
                            </CardOverflow>
                        </Card>
                        :
                        <CircularProgress />
                }
            </Stack>
        )
    }
    function TabPreferenze() {
        return (
            <Stack spacing={4}
                   sx={{
                       display: 'flex',
                       maxWidth: '800px',
                       mx: 'auto',
                       px: {xs: 2, md: 6},
                       py: {xs: 2, md: 3},
                   }}
            >
                {
                    data!==null ?
                        <Card>
                            <Box sx={{ mb: 1 }}>
                                <Typography level="title-md">Preferenze</Typography>
                                <Typography level="body-sm">
                                    Le tue personalizzazioni
                                </Typography>
                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                spacing={3}
                                sx={{ my: 1 }}
                            >
                                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                                    <Grid xs={12} md={12}>
                                        <FormControl>
                                            <FormLabel>Modalità</FormLabel>
                                            <ToggleButtonGroup
                                                value={mode}
                                                onChange={(event, newValue) => {
                                                    setMode(newValue)
                                                }}
                                            >
                                                <Button value="light"
                                                        startDecorator={<LightMode />}
                                                >
                                                    Chiaro
                                                </Button>
                                                <Button value="dark"
                                                        startDecorator={<DarkMode />}
                                                >
                                                    Scuro
                                                </Button>
                                                <Button value="system"
                                                        startDecorator={<Computer />}
                                                >
                                                    Dispositivo
                                                </Button>
                                            </ToggleButtonGroup>
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={12}>
                                        <FormControl>
                                            <FormLabel>Lingua</FormLabel>
                                            <Autocomplete
                                                size="sm"
                                                autoHighlight
                                                getOptionLabel={option => option.native}
                                                isOptionEqualToValue={(option, value) => option.code === value.code || option.native === value.native || option.name === value.name}
                                                value={language}
                                                onChange={(e, newValue) => setLanguage(newValue!)}
                                                options={languages}
                                                renderOption={(optionProps, option) => (
                                                    <AutocompleteOption {...optionProps}>
                                                        <ListItemDecorator>
                                                            <AspectRatio ratio="1" sx={{ minWidth: 20, borderRadius: '50%' }}>
                                                                <img
                                                                    loading="lazy"
                                                                    width="20"
                                                                    srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                                                    src={`https://flagcdn.com/w20/${option.code.toLowerCase()}.png`}
                                                                    alt=""
                                                                />
                                                            </AspectRatio>
                                                        </ListItemDecorator>
                                                        {option.native}
                                                    </AutocompleteOption>
                                                )}
                                                slotProps={{
                                                    input: {
                                                        autoComplete: 'new-password',
                                                    },
                                                }}
                                                error={!language || language.code === ""}
                                                disabled // todo
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Stack>
                        </Card>
                        :
                        <CircularProgress />
                }
            </Stack>
        )
    }
    function TabAccount() {
        return (
            <Stack spacing={4}
                   sx={{
                       display: 'flex',
                       maxWidth: '800px',
                       mx: 'auto',
                       px: {xs: 2, md: 6},
                       py: {xs: 2, md: 3},
                   }}
            >
                {
                    data!==null ?
                        <Card>
                            <Box sx={{ mb: 1 }}>
                                <Typography level="title-md">Account e modifica password</Typography>
                                <Typography level="body-sm">
                                    Informazioni relative alla sicurezza e all'account
                                </Typography>
                            </Box>
                            <Divider />
                            <Stack
                                direction="row"
                                spacing={3}
                                sx={{ my: 1 }}
                            >
                                <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                                    <Grid xs={12} md={6}>
                                        <FormControl>
                                            <FormLabel>Email</FormLabel>
                                            <Input size="sm"
                                                   type="email"
                                                   placeholder="Mario"
                                                   value={data.email}
                                                   disabled
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={6}>
                                        <FormControl>
                                            <FormLabel>ID utente</FormLabel>
                                            <Input size="sm"
                                                   type="text"
                                                   placeholder="Rossi"
                                                   value={data.id}
                                                   readOnly
                                                   endDecorator={<ContentCopy sx={{cursor:"pointer"}} />}
                                                   sx={{fontFamily:"monospace"}}
                                                   onClick={() => {
                                                       navigator.clipboard.writeText(data.id)
                                                           .then(() => setMessage("Copiato negli appunti"))
                                                           .catch(err => setError(err))
                                                   }}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <FormControl>
                                            <FormLabel>Password attuale *</FormLabel>
                                            <Input size="sm"
                                                   placeholder="********"
                                                   type={pswvisible ? "text" : "password"}
                                                   value={password}
                                                   onChange={(e) => setPassword(e.target.value)}
                                                   endDecorator={
                                                       pswvisible ?
                                                           <VisibilityOff onClick={() => setPswvisible(!pswvisible)} />
                                                           :
                                                           <Visibility onClick={() => setPswvisible(!pswvisible)} />
                                                   }
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <FormControl>
                                            <FormLabel>Nuova password *</FormLabel>
                                            <Input size="sm"
                                                   placeholder="********"
                                                   type={pswvisible ? "text" : "password"}
                                                   value={password1}
                                                   onChange={(e) => setPassword1(e.target.value)}
                                                   endDecorator={
                                                       pswvisible ?
                                                           <VisibilityOff onClick={() => setPswvisible(!pswvisible)} />
                                                           :
                                                           <Visibility onClick={() => setPswvisible(!pswvisible)} />
                                                   }
                                                   error={password1!==password2 || password1.length<8}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={12} md={4}>
                                        <FormControl>
                                            <FormLabel>Ripeti nuova password *</FormLabel>
                                            <Input size="sm"
                                                   placeholder="********"
                                                   type={pswvisible ? "text" : "password"}
                                                   value={password2}
                                                   onChange={(e) => setPassword2(e.target.value)}
                                                   endDecorator={
                                                       pswvisible ?
                                                           <VisibilityOff onClick={() => setPswvisible(!pswvisible)} />
                                                           :
                                                           <Visibility onClick={() => setPswvisible(!pswvisible)} />
                                                   }
                                                   error={password1!==password2 || password2.length<8}
                                            />
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Stack>
                            <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                                <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                                    <Button size="sm"
                                            variant="solid"
                                            disabled={loading || password.length<8 || password1.length<8 || password1!==password2}
                                            onClick={() => updatePassword(password, password1)}
                                    >
                                        Modifica password
                                    </Button>
                                </CardActions>
                            </CardOverflow>
                        </Card>
                        :
                        <CircularProgress />
                }
            </Stack>
        )
    }
    function TabTurniDiLavoro() {
        return <TurniDipendentiInner userId={firebase.auth().currentUser?.uid ?? ""} />
    }
    function TabFerie() {
        return <FerieDipendentiInner userId={firebase.auth().currentUser?.uid ?? ""} />
    }

    async function fetchUser(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getUser/${firebase.auth().currentUser?.uid}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                let data = await response.json()
                data.birthdate = moment(data.birthdate).format("YYYY-MM-DD")
                setInitialData(data)
                setData(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
        }
    }
    async function updatePassword(oldpsw: string, newpsw: string) {
        setLoading(true)
        try {
            const email = firebase.auth().currentUser?.email
            await firebase.auth().signInWithEmailAndPassword(email!, oldpsw)
            await firebase.auth().currentUser?.updatePassword(newpsw)
            setMessage("Password modificata")
            setPassword("")
            setPassword1("")
            setPassword2("")
        } catch (error) {
            setError(error)
        } finally {
            setLoading(false)
        }
    }
    async function updateUser() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            await fetch(`${APIurl}/updateUser/${data?.id}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(data)
            })
            setMessage("Dati aggiornati con successo")
            setInitialData(data)
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}