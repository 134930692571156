import {PageStandard} from "../../utils/PageStandard";
import {
    Avatar,
    Box,
    Button,
    Chip,
    Divider,
    Dropdown,
    FormControl,
    FormLabel,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    Modal,
    ModalClose,
    ModalDialog,
    Option,
    Select,
    Sheet,
    Table,
    Typography
} from "@mui/joy";
import {FilterAlt, MoreHorizRounded, Search} from "@mui/icons-material";
import {useContext, useEffect, useState} from "react";
import {CustomerModel} from "../../utils/Model";
import {Link} from "react-router-dom";
import {APIurl} from "../../utils/Utils";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";


export function LibroClienti() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [sort, setSort] = useState("");
    const [customersData, setCustomersData] = useState<CustomerModel[]>([]);
    const [customers, setCustomers] = useState<CustomerModel[]>([]);

    useEffect(() => {
        fetchCustomers().then(() => {})
    }, []);
    useEffect(() => {
        let res = customersData
        if (search.length >= 3) {
            res = res.filter(a => `${a.surname} ${a.name} ${a.surname} ${a.email} ${a.phoneNumber} ${a.id}`.toLowerCase().includes(search.toLowerCase()))
        }
        if (sort !== "") {
            switch (sort) {
                case "name_asc":
                    res = res.sort((a, b) => {
                        const name1 = a.surname + " " + a.name
                        const name2 = b.surname + " " + b.name
                        return name1 < name2 ? -1 : 1
                    })
                    break;
                case "name_desc":
                    res = res.sort((a, b) => {
                        const name1 = a.surname + " " + a.name
                        const name2 = b.surname + " " + b.name
                        return name1 > name2 ? -1 : 1
                    })
                    break;
                case "created_asc":
                    res = res.sort((a, b) => a.createdAt < b.createdAt ? -1 : 1)
                    break;
                case "created_desc":
                    res = res.sort((a, b) => a.createdAt > b.createdAt ? -1 : 1)
                    break;
                default:
                    break;
            }
        }
        setCustomers(res)
    }, [customersData, search, sort]);

    async function fetchCustomers(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getCustomers`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setCustomersData(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }


    function Filters() {
        return (
            <>
                <FormControl size="sm">
                    <FormLabel>Ordina</FormLabel>
                    <Select
                        size="sm"
                        placeholder="Ordina"
                        slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                        value={sort}
                        onChange={(e, newValue) => setSort(newValue!)}
                    >
                        <Option value="name_asc">Nome crescente</Option>
                        <Option value="name_desc">Nome decrescente</Option>
                        <Option value="created_asc">Data di creazione crescente</Option>
                        <Option value="created_desc">Data di creazione decrescente</Option>
                    </Select>
                </FormControl>
            </>
        )
    }

    return (
        <PageStandard title="Libro clienti"
                      sidebarVisible={true}
                      headerEnd={<Link to="/gestione-clienti/nuovo-cliente"><Button>Nuovo cliente</Button></Link>}
        >

            <Sheet
                className="SearchAndFilters-mobile"
                sx={{
                    display: {xs: 'flex', sm: 'none'},
                    my: 1,
                    gap: 1,
                }}
            >
                <Input
                    size="sm"
                    placeholder="Search"
                    startDecorator={<Search/>}
                    sx={{flexGrow: 1}}
                />
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    onClick={() => setOpen(true)}
                >
                    <FilterAlt/>
                </IconButton>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                        <ModalClose/>
                        <Typography id="filter-modal" level="h2">
                            Filters
                        </Typography>
                        <Divider sx={{my: 2}}/>
                        <Sheet sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                            <Filters/>
                            <Button color="primary" onClick={() => setOpen(false)}>
                                Invia
                            </Button>
                        </Sheet>
                    </ModalDialog>
                </Modal>
            </Sheet>
            <Box
                className="SearchAndFilters-tabletUp"
                sx={{
                    borderRadius: 'sm',
                    py: 2,
                    display: {xs: 'none', sm: 'flex'},
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: {xs: '120px', md: '160px'},
                    },
                }}
            >
                <FormControl sx={{flex: 1}} size="sm">
                    <FormLabel>Cerca</FormLabel>
                    <Input size="sm"
                           placeholder="Nome, email, telefono o ID"
                           startDecorator={<Search/>}
                           value={search}
                           onChange={(e) => setSearch(e.target.value)}
                    />
                </FormControl>
                <Filters/>
            </Box>
            <Sheet
                className="OrderTableContainer"
                variant="outlined"
                sx={{
                    display: {xs: 'none', sm: 'initial'},
                    width: '100%',
                    borderRadius: 'sm',
                    flexShrink: 1,
                    overflow: 'auto',
                    minHeight: 0,
                }}
            >
                <Table
                    aria-labelledby="tableTitle"
                    stickyHeader
                    hoverRow
                    sx={{
                        '--TableCell-headBackground': 'var(--joy-palette-background-level1)',
                        '--Table-headerUnderlineThickness': '1px',
                        '--TableRow-hoverBackground': 'var(--joy-palette-background-level1)',
                        '--TableCell-paddingY': '4px',
                        '--TableCell-paddingX': '8px',
                    }}
                >
                    <thead>
                    <tr>
                        {window.innerWidth >= 900 && <th style={{width: 25, textAlign: 'center', padding: '12px 6px'}}></th>}
                        <th style={{width: 150, padding: '12px 6px'}}>Nome</th>
                        {window.innerWidth >= 900 && <th style={{width: 140, padding: '12px 6px'}}>Email</th>}
                        {window.innerWidth >= 900 && <th style={{width: 140, padding: '12px 6px'}}>Telefono</th>}
                        {window.innerWidth >= 900 && <th style={{width: 140, padding: '12px 6px'}}>Consenso marketing</th>}
                        <th style={{width: 140, padding: '12px 6px'}}>Azioni</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        customers.map((customer) => (
                            <RowUser customer={customer} key={customer.id}/>
                        ))
                    }
                    </tbody>
                </Table>
            </Sheet>
        </PageStandard>
    )
}

export function RowUser(props: { customer: CustomerModel }) {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const customer = props.customer

    return (
        <tr key={customer.id}>
            {window.innerWidth >= 900 &&
                <td style={{textAlign: 'center', width: 25}}>
                    <div style={{width: "100%", display: "flex", justifyContent: "end"}}>
                        <Avatar size="sm">{customer.surname[0].toUpperCase()}{customer.name[0].toUpperCase()}</Avatar>
                    </div>
                </td>
            }
            <td>
                <Typography level="body-xs">{customer.surname.toUpperCase()} {customer.name}</Typography>
            </td>
            {window.innerWidth >= 900 &&
                <td>
                    <Typography level="body-xs">{customer.email}</Typography>
                </td>
            }
            {window.innerWidth >= 900 &&
                <td>
                    <Typography level="body-xs">{customer.phoneNumberPrefisso} {customer.phoneNumber}</Typography>
                </td>
            }
            {window.innerWidth >= 900 &&
                <td>
                    <Chip
                        color={customer.marketing ? "success" : "danger"}
                        size="sm"
                        variant="solid"
                    >
                        {customer.marketing ? "SI" : "NO"}
                    </Chip>
                </td>
            }
            <td>
                <IconButton>
                    <RowMenu/>
                </IconButton>
            </td>
        </tr>
    )

    function RowMenu() {
        return (
            <Dropdown>
                <MenuButton
                    slots={{root: IconButton}}
                    slotProps={{root: {variant: 'plain', color: 'neutral', size: 'sm'}}}
                >
                    <MoreHorizRounded/>
                </MenuButton>
                <Menu size="sm" sx={{minWidth: 140}}>
                    <Link to={"/gestione-clienti/cliente?customerId=" + customer.id}>
                        <MenuItem>Modifica</MenuItem>
                    </Link>
                    <Divider/>
                    <MenuItem color="danger"
                              onClick={() => {
                                  if(window.confirm("Eliminare definitvamente questo cliente?")) deleteCustomer()
                              }}
                    >
                        Elimina
                    </MenuItem>
                </Menu>
            </Dropdown>
        );
    }

    async function deleteCustomer() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/deleteCustomer/${customer.id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Cliente eliminato con successo")
                window.location.reload()
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}