import {PageStandard} from "../../utils/PageStandard";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardOverflow,
    Checkbox,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Stack,
    Typography
} from "@mui/joy";
import {CustomerModel} from "../../utils/Model";
import {APIurl, areObjectsEqual, isValidPhoneNumber, TelefonoInput} from "../../utils/Utils";
import React, {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";
import {useSearchParams} from "react-router-dom";
import moment from "moment/moment";

export function Cliente() {

    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)


    const [searchParams, setSearchParams] = useSearchParams();
    const customerId = searchParams.get("customerId")
    if(!customerId) window.location.replace("/gestione-clienti/libro-clienti")

    const [cliente, setCliente] = useState<CustomerModel | null>(null)
    const [clienteServer, setClienteServer] = useState<CustomerModel | null>(null)

    useEffect(() => {
        fetchCliente().then(() => {})
    }, []);
    async function fetchCliente(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getCustomer/${customerId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                const newData = {
                    ...data,
                    birthdate: moment(data.birthdate).format("YYYY-MM-DD")
                }
                setCliente(newData)
                setClienteServer(newData)
            }

        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    if(cliente === null) return null
    else return (
        <PageStandard title="Cliente" sidebarVisible={true}>
            <Stack sx={{
                display: 'flex',
                maxWidth: '800px',
                mx: 'auto',
                px: { xs: 2, md: 6 },
                py: { xs: 2, md: 3 },
            }}>
                <Card>
                <Box sx={{ mb: 1 }}>
                    <Typography level="title-md">Informazioni cliente</Typography>
                    <Typography level="body-sm">
                        Modifica i dati del cliente
                    </Typography>
                </Box>
                <Divider />
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{ my: 1 }}
                >
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Nome *</FormLabel>
                                <Input size="sm"
                                       type="text"
                                       placeholder="Mario"
                                       value={cliente.name}
                                       onChange={e => setCliente({...cliente, name: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Cognome *</FormLabel>
                                <Input size="sm"
                                       type="text"
                                       placeholder="Rossi"
                                       value={cliente.surname}
                                       onChange={e => setCliente({...cliente, surname: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Email *</FormLabel>
                                <Input size="sm"
                                       type="email"
                                       placeholder="nome@email.com"
                                       value={cliente.email}
                                       onChange={e => setCliente({...cliente, email: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Telefono</FormLabel>
                                <TelefonoInput valuePrefisso={cliente.phoneNumberPrefisso ?? "39"}
                                               valuePhone={cliente.phoneNumber ?? ""}
                                               onChangePrefisso={e => setCliente({...cliente, phoneNumberPrefisso: e})}
                                               onChangePhone={e => setCliente({...cliente, phoneNumber: e})}
                                               error={!isValidPhoneNumber(cliente.phoneNumberPrefisso + " " + cliente.phoneNumber)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <Checkbox label="Consenso trattamento dei dati"
                                          size="sm"
                                          checked={cliente.terms}
                                          onChange={() => setCliente({...cliente, terms: !cliente.terms})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <Checkbox label="Consenso marketing"
                                          size="sm"
                                          checked={cliente.marketing}
                                          onChange={() => setCliente({...cliente, marketing: !cliente.marketing})}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Stack>
                <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                    <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                        <Button size="sm"
                                variant="solid"
                                disabled={cliente.name==="" || cliente.surname==="" || cliente.email==="" || cliente.phoneNumber==="" || !cliente.terms || loading || areObjectsEqual(cliente, clienteServer!)}
                                onClick={() => updateCustomer()}
                        >
                            Aggiorna dati
                        </Button>
                    </CardActions>
                </CardOverflow>
            </Card>
            </Stack>
        </PageStandard>
    )


    async function updateCustomer() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/updateCustomer/${customerId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(cliente)
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Cliente aggiornato con successo")
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}