import {ReactNode} from "react";


export interface UserModel {
    id: string
    name: string
    surname: string
    email: string
    phoneNumber: string
    phoneNumberPrefisso: string
    birthdate: string
    role: Roles
    permissions: Permissions[]
    services: string[]
    createdAt: string
    workingHours: WorkingHoursModel
}
export interface SlotModel {
    id: string
    start: Date,
    end: Date,
    employeeId: string[] | string,
}

export interface ShiftModel {
    id: string
    uid: string
    start: Date
    end: Date
}
export interface HolidayModel {
    id: string
    uid: string
    start: Date
    end: Date
}

export enum Roles {
    ADMIN = "admin",
    USER = "user"
}
export enum Permissions {
    PRENOTAZIONI_ALTRI_UTENTI = "Gestire prenotazioni di altri utenti",
    EMAIL_CLIENTI = "Inviare email a clienti",
    STATISTICHE = "Vedere statistiche",
    UTILIZZO = "Vedere utilizzo",
    CREARE_FERIE = "Creare ferie",
    GESTIRE_TURNI = "Gestire orari di lavoro",
    EDIT_SERVIZI = "Gestire servizi",
    ASSIGN_SERVIZI = "Assegnare servizi a dipendenti",
    MANAGE_USERS = "Gestire dipendenti",
    MANAGE_CUSTOMERS = "Gestire clienti",
}

export interface Language {
    code: string,
    name: string,
    native: string,
}

export interface BookingModel {
    id: string,
    start: Date,
    end: Date,
    serviceId: string,
    customerId: string,
    employeeId: string,
    customerNotes?: string,
}

export interface BookingFormattedModel {
    id: string,
    date: string,
    start: string,
    end: string,
    service: string,
    customer: string,
    employee?: string,
}

export interface CalendarEventModel {
    id: string,
    start: Date,
    end: Date,
    title: string,
    serviceId: string,
    customerId: string,
    employeeId: string,
    customerNotes?: string,
}

export interface CustomerModel {
    id: string,
    name: string,
    surname: string,
    email?: string,
    phoneNumber?: string,
    phoneNumberPrefisso?: string,
    terms: boolean,
    marketing: boolean,
    createdAt: string
}

export interface ServiceModel {
    id: string,
    duration: number,
    title: string,
    description?: string,
    price?: number,
    imageUrl?: string,
}

export const Durations = [
    15,
    30,
    45,
    60,
    75,
    90,
    105,
    120,
    135,
    150,
    165,
    180,
]

export interface CompanyDetailsModel {
    id: string,
    shortName: string,
    longName: string,
    vatNumber: string,
    address: string,
    city: string,
    postalCode: string,
    state: string,
    country: string,
    phoneNumber?: string,
    phoneNumberPrefisso?: string,
    email?: string,
    website?: string,
    category?: string,
    workingHours?: WorkingHoursModel,
    gestionaleSettings?: GestionaleSettingsModel,
    plan?: CompanyPlanModel,
    subscriptionActive?: boolean,
    configuration?: boolean,
}

export interface GestionaleSettingsModel {
    prenotazioniAttive: boolean,
    reminderEmail: boolean,
    emailConfirmation: boolean,
    emailEdit: boolean,
    emailDelete: boolean,
    emailReminderOneDayBefore: boolean,
    emailReminderSameDay: boolean,
    reminderWhatsapp: boolean,
    whatsappConfirmation: boolean,
    whatsappEdit: boolean,
    whatsappDelete: boolean,
    whatsappReminderOneDayBefore: boolean,
    whatsappReminderSameDay: boolean,
    customerEditBooking: boolean,
    customerEditBookingDelay: number,
    customerDeleteBooking: boolean,
    customerDeleteBookingDelay: number,
    customerMinDelay: number,
    customerMaxDelay: number,
    turniAutomatici: boolean,
    turniDelay: number,
    slotMinimo: number,
}

export interface WorkingHoursModel {
    "0": {start: string, end: string}[],
    "1": {start: string, end: string}[],
    "2": {start: string, end: string}[],
    "3": {start: string, end: string}[],
    "4": {start: string, end: string}[],
    "5": {start: string, end: string}[],
    "6": {start: string, end: string}[]
}

export interface OctopusUser {
    uid: string
    company_id: string
}

export interface ServerSettingsModel {
    mainteinance: boolean,
}

export interface ChiusuraAziendaleModel {
    id: string,
    start: string,
    end: string,
}

export interface StatsModel {
    totalCustomers: number;
    newCustomersThisMonth: number;
    avgNewCustomersPerMonth: number;
    customersPerMonth: { [month: string]: number };
    marketingConsentPercentage: number; // Percentuale di clienti con consenso marketing
    marketingConsentCount: number; // Numero di clienti con consenso marketing

    totalBookings: number;
    bookingsThisMonth: number;
    avgBookingsPerMonth: number;
    bookingsPerMonth: { [month: string]: number };

    totalServices: number;
    bookingsPerService: {
        bookings_count: number;
        percentage: number;
        service_id: string;
        service_name: string;
    }[]; // Numero di prenotazioni per servizio
    averageServicePrice: number; // Prezzo medio dei servizi
    totalEstimatedEarnings: number; // Guadagni totali stimati

    workHoursPerEmployee: {
        [employeeName: string]: { [month: string]: number }; // Usa il nome e cognome dell'employee come chiave
    };

    holidaysPerEmployee: {
        [employeeName: string]: number; // Usa il nome e cognome dell'employee come chiave
    };

    companyClosures: number;
}

export interface UtilizzoStatsModel {
    plan: CompanyPlanModel,
    usage: {
        users: number,
        bookings: number,
        services: number,
    },
    whatsapp: {
        totalMessages: number,
        pricingPerMessage: number,
        extimatedPrice: number
    }
}


export interface FaqModel {
    id: string;
    question: string | ReactNode;
    answer: string | ReactNode;
}

export interface CompanyPlanModel {
    id: string,
    name: string,
    price: number,
    maxUsers: number,
    maxBookings: number,
    maxServices: number,
    turniAutomatici: boolean,
}

export interface CanCreateModel {
    result: boolean | null,
}


export interface AlertModel {
    id: string,
    text: string,
}
