import {
    Avatar,
    Box, DialogContent,
    Divider, Drawer,
    IconButton,
    List,
    ListItem,
    ListItemButton,
    listItemButtonClasses,
    ListItemContent, ModalClose,
    Sheet,
    Typography
} from "@mui/joy";
import {Close, KeyboardArrowDown, Logout, Menu, Settings, Support} from "@mui/icons-material";
import React, {useContext, useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useLocation} from "react-router";
import firebase from "firebase/compat/app";
import {AuthContext} from "./AuthProvider";
import {PageInterface, PAGES} from "./Pages";
import {FeedbackContext} from "./FeedbackContext";
import {LogoOctopus} from "../assets/Logo";
import {Supporto} from "../pages/Supporto";
import {Permissions, Roles} from "./Model";

function Toggler({
                     defaultExpanded = false,
                     renderToggle,
                     children,
                 }: {
    defaultExpanded?: boolean;
    children: React.ReactNode;
    renderToggle: (params: {
        open: boolean;
        setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    }) => React.ReactNode;
}) {
    const [open, setOpen] = React.useState(defaultExpanded);
    return (
        <React.Fragment>
            {renderToggle({ open, setOpen })}
            <Box
                sx={{
                    display: 'grid',
                    gridTemplateRows: open ? '1fr' : '0fr',
                    transition: '0.2s ease',
                    '& > *': {
                        overflow: 'hidden',
                    },
                }}
            >
                {children}
            </Box>
        </React.Fragment>
    );
}

export function Sidebar() {

    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany, user} = useContext(AuthContext)

    const {currentUser} = useContext(AuthContext)
    const location = useLocation();
    const pathname = location.pathname

    useEffect(() => {
        closeSidebar()
    }, [location]);

    const [supportoVisible, setSupportoVisible] = useState(false)


    return (
        <>
            <Box
                className="Sidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9997,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    opacity: 'var(--SideNavigation-slideIn)',
                    backgroundColor: 'var(--joy-palette-background-backdrop)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--Sidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                    display: {
                        xs: "block",
                        md: "none"
                    }
                }}
                onClick={() => closeSidebar()}
            />
            <Sheet
                className="Sidebar"
                sx={{
                    position: { xs: 'fixed', md: 'fixed' },
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1)))',
                        md: 'none',
                    },
                    transition: 'transform 0.4s, width 0.4s',
                    zIndex: 99998,
                    height: '100%',
                    minHeight: '100dvh',
                    width: {
                        xs: '100vw',
                        md: '20vw',
                        lg: '15vw',
                    },
                    top: 0,
                    p: 2,
                    flexShrink: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                    borderRight: '1px solid',
                    borderColor: 'divider',
                }}
            >
                <Box sx={{ display: 'flex', gap: 1, justifyContent:"center", alignItems: 'center' }}>
                    <IconButton
                        onClick={() => toggleSidebar()}
                        variant="plain"
                        color="neutral"
                        size="sm"
                        sx={{position:"absolute", top:"1rem", left:"1rem", display: {xs:"block", md:"none"}}}
                    >
                        <Close />
                    </IconButton>
                    <IconButton variant="soft" color="primary" size="sm">
                        <LogoOctopus width="20" height="20" color="var(--joy-palette-primary-500)" />
                    </IconButton>
                    <Typography level="title-lg">Octopus Desk</Typography>
                </Box>
                <Typography level="title-sm" textAlign="center" textTransform="uppercase" sx={{my: "-0.5rem"}}>{userCompany.longName}</Typography>
                <Divider />
                <Box
                    sx={{
                        minHeight: 0,
                        overflow: 'hidden auto',
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        [`& .${listItemButtonClasses.root}`]: {
                            gap: 1.5,
                        },
                    }}
                >
                    <List
                        size="sm"
                        sx={{
                            gap: 1,
                            '--List-nestedInsetStart': '30px',
                            '--ListItem-radius': (theme) => theme.vars.radius.sm,
                        }}
                    >

                        {
                            genPages(user.role, user.permissions)!
                                .filter(p => p.sidebarVisible && p.icon !== null)
                                .map(p => {
                                    if (p.subpages) {
                                        return (
                                            <ListItem nested key={p.link}>
                                                <Toggler
                                                    renderToggle={({open, setOpen}) => (
                                                        <ListItemButton onClick={() => setOpen(!open)} selected={pathname === p.link}>
                                                            {p.icon}
                                                            <ListItemContent>
                                                                <Typography level="title-sm">{p.title}</Typography>
                                                            </ListItemContent>
                                                            <KeyboardArrowDown
                                                                sx={{transform: open ? 'rotate(180deg)' : 'none'}}
                                                                onClick={() => {
                                                                }}
                                                            />
                                                        </ListItemButton>
                                                    )}
                                                >
                                                    <List sx={{ gap: 0.5 }}>
                                                        {
                                                            p.subpages
                                                                .filter(p2 => p2.sidebarVisible)
                                                                .map(p2 => {
                                                                    return <Link to={p.link + p2.link}
                                                                                 style={{width: "100%"}}
                                                                                 key={p.link + p2.link}>
                                                                        <ListItem>
                                                                            <ListItemButton
                                                                                selected={pathname === p.link + p2.link}>
                                                                                {p2.title}
                                                                            </ListItemButton>
                                                                        </ListItem>
                                                                    </Link>
                                                                })
                                                        }
                                                    </List>
                                                </Toggler>
                                            </ListItem>
                                        )
                                    } else {
                                        return (
                                            <ListItem key={p.link}>
                                                <Link to={p.link} style={{width:"100%"}}>
                                                    <ListItemButton>
                                                        {p.icon}
                                                        <ListItemContent>
                                                            <Typography level="title-sm">{p.title}</Typography>
                                                        </ListItemContent>
                                                    </ListItemButton>
                                                </Link>
                                            </ListItem>
                                        )
                                    }
                                })
                        }

                    </List>

                    <List
                        size="sm"
                        sx={{
                            mt: 'auto',
                            flexGrow: 0,
                            '--ListItem-radius': (theme) => theme.vars.radius.sm,
                            '--List-gap': '4px',
                            mb: 2,
                        }}
                    >
                        <ListItem>
                            <ListItemButton onClick={() => {
                                setSupportoVisible(!supportoVisible)
                                closeSidebar()
                            }}>
                                <Support />
                                <ListItemContent>
                                    <Typography level="title-sm">Supporto</Typography>
                                </ListItemContent>
                            </ListItemButton>
                        </ListItem>
                        <ListItem>
                            <Link to="/impostazioni" style={{width:"100%"}}>
                                <ListItemButton>
                                    <Settings />
                                    <ListItemContent>
                                        <Typography level="title-sm">Impostazioni</Typography>
                                    </ListItemContent>
                                </ListItemButton>
                            </Link>
                        </ListItem>
                    </List>
                </Box>
                <Divider />
                <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                    {
                        currentUser.photoUrl ?
                            <Avatar
                                variant="outlined"
                                size="sm"
                                src={currentUser.photoUrl}
                            />
                            :
                            <Avatar>{getInitials()}</Avatar>
                    }

                    <Box sx={{ minWidth: 0, flex: 1, overflow:"hidden" }}>
                        <Typography level="title-sm">{getDisplayName()}</Typography>
                        <Typography level="body-xs">{getUserEmail()}</Typography>
                    </Box>
                    <IconButton size="sm" variant="plain" color="neutral"
                                onClick={() => {
                                    if(window.confirm("Effettuare il logout?")) SignOut().then(() => {})
                                }}
                    >
                        <Logout />
                    </IconButton>
                </Box>
                <Drawer
                    anchor="right"
                    open={supportoVisible}
                    onClose={() => setSupportoVisible(false)}
                    sx={{zIndex:99999, ".MuiDrawer-content": { width: {xs:"100vw", md:"50vw", lg:"40vw", xl:"35vw"} }}}
                >
                    <DialogContent>
                        <ModalClose sx={{display: {xs:"block", md:"none"}}}/>
                        <Supporto />
                    </DialogContent>
                </Drawer>
            </Sheet>
        </>
    );

    async function SignOut() {
        try {
            await firebase.auth().signOut()
        } catch (e: unknown) {
            setError(e)
        }
    }

    function getUserEmail() {
        return currentUser.email
    }
    function getDisplayName() {
        return currentUser.displayName ?? "Nome sconosciuto"
    }
    function getInitials() {
        if(currentUser.displayName) {
            let words: string[] = currentUser.displayName.split(' ')
            let initials: string[] = words.map(word => word[0])
            return initials.join('').substring(0, 2).toUpperCase()
        } else return "NS"
    }

}

export function genPages(role: Roles, permissions: Permissions[]): PageInterface[] {
    // Funzione per filtrare le pagine in base ai permessi e al ruolo
    const filterPages = (pages: PageInterface[]): PageInterface[] => {
        return pages
            .filter(page => {
                // Condizioni per includere la pagina
                const isAdminPage = page.roles?.includes(Roles.ADMIN);
                const isUserPage = page.roles?.includes(Roles.USER);
                const hasPermission = page.permissions && page.permissions.length > 0
                    ? page.permissions.every(p => permissions.includes(p as Permissions))
                    : false;

                // Regole per la visibilità della pagina
                return (
                    (role === Roles.ADMIN && isAdminPage) ||         // L'admin vede le pagine con ruolo ADMIN
                    (role === Roles.USER && isUserPage) ||           // L'utente vede le pagine con ruolo USER
                    (role === Roles.USER && isAdminPage && hasPermission) // L'utente vede le pagine con ruolo ADMIN solo se ha il permesso
                );
            })
            .map(page => ({
                ...page,
                // Filtra eventuali sotto-pagine ricorsivamente
                subpages: page.subpages ? filterPages(page.subpages) : undefined,
            }));
    };

    // Restituisci le pagine filtrate
    return filterPages(PAGES);
}

export function openSidebar() {
    if (typeof window !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
}

export function closeSidebar() {
    if (typeof window !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
}

export function toggleSidebar() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
        const slideIn = window
            .getComputedStyle(document.documentElement)
            .getPropertyValue('--SideNavigation-slideIn');
        if (slideIn) {
            closeSidebar();
        } else {
            openSidebar();
        }
    }
}

/*
export function Sidebar() {


    const pathname = useLocation().pathname
    const {currentUser} = useContext(AuthContext)

    return (
        <>
            <Box
                className="SecondSidebar-overlay"
                sx={{
                    position: 'fixed',
                    zIndex: 9998,
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    maxHeight: '100vh',
                    bgcolor: 'background.body',
                    opacity: 'calc(var(--SideNavigation-slideIn, 0) - 0.2)',
                    transition: 'opacity 0.4s',
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
                        lg: 'translateX(-100%)',
                    },
                }}
                onClick={() => closeSidebar()}
            />
            <Sheet
                className="SecondSidebar"
                sx={{
                    position: {
                        xs: 'fixed',
                        lg: 'fixed', //'sticky',
                    },
                    transform: {
                        xs: 'translateX(calc(100% * (var(--SideNavigation-slideIn, 0) - 1) + var(--SideNavigation-slideIn, 0) * var(--FirstSidebar-width, 0px)))',
                        lg: 'none',
                    },
                    borderRight: '1px solid',
                    borderColor: 'divider',
                    transition: 'transform 0.4s',
                    zIndex: 9999,
                    height: '95dvh',
                    top: 0,
                    p: 2,
                    py: 3,
                    flexShrink: 0,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
            >
                <Stack direction="column" spacing={2}>
                    <ListSubheader role="presentation" sx={{
                        color: 'text.primary',
                        display: {
                            sx: "block",
                            lg: "none"
                        },
                        width:"100%"
                    }}>
                        CONTROL PANEL
                    </ListSubheader>


                    {
                        PAGES
                            .filter(p => p.sidebarVisible && p.icon !== null)
                            .map(p => {
                                return ( <ItemMenu label={p.title} icon={p.icon!} link={p.link} /> )
                            })
                    }


                </Stack>
            </Sheet>
        </>
    )

    function ItemMenu(props: { label: string, icon: ReactElement, link: string }) {

        const active = pathname===props.link

        return (
            <Link to={props.link} style={{width: "100%"}} target={props.link.includes("https://") || props.link.includes("http://") ? "_blank" : ""}>
                <Button variant={active ? "soft" : "plain"}
                        onClick={() => closeSidebar()}
                        color={active ? "primary" : "neutral"}
                        fullWidth
                >
                    <Stack direction="row" spacing={1} sx={{width: "100%", justifyContent:"start", alignItems:"center"}}>
                        <Box sx={{display:"flex", justifyContent:"center", alignItems:"center"}}>{props.icon}</Box>
                        <Box sx={{
                            display: {
                                sx:"block",
                                lg:"none",
                            }
                        }}>{props.label}</Box>
                    </Stack>
                </Button>
            </Link>
        )
    }
}

export const openSidebar = () => {
    if (typeof document !== 'undefined') {
        document.body.style.overflow = 'hidden';
        document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
};

export const closeSidebar = () => {
    if (typeof document !== 'undefined') {
        document.documentElement.style.removeProperty('--SideNavigation-slideIn');
        document.body.style.removeProperty('overflow');
    }
};
 */