import {ReactElement, ReactNode} from "react";
import {
    BarChart,
    Dashboard as DashboardIcon,
    Euro,
    Event,
    Group,
    ImportContacts,
    Inventory,
    Storefront
} from "@mui/icons-material";
import {Login} from "../pages/Login";
import {Dashboard} from "../pages/Dashboard";
import {Settings} from "../pages/Settings";
import {Dipendenti} from "../pages/users/Dipendenti";
import {NuovoUtente} from "../pages/users/NuovoUtente";
import {TutteLePrenotazioni} from "../pages/bookings/TutteLePrenotazioni";
import {ServiziOfferti} from "../pages/services/ServiziOfferti";
import {NuovoServizio} from "../pages/services/NuovoServizio";
import {AssegnaUtenti} from "../pages/services/AssegnaUtenti";
import {AssegnaServizi} from "../pages/users/AssegnaServizi";
import {LibroClienti} from "../pages/customers/LibroClienti";
import {NuovoCliente} from "../pages/customers/NuovoCliente";
import {Newsletter} from "../pages/customers/Newsletter";
import {DettagliAzienda} from "../pages/company/DettagliAzienda";
import {OrariDiLavoroSettimanali} from "../pages/company/OrariDiLavoroSettimanali";
import {ChiusuraAziendale} from "../pages/company/ChiusuraAziendale";
import {TurniDipendenti} from "../pages/users/TurniDipendenti";
import {FerieDipendenti} from "../pages/users/FerieDipendenti";
import {Prenotazione} from "../pages/bookings/Prenotazione";
import {Utente} from "../pages/users/Utente";
import {Cliente} from "../pages/customers/Cliente";
import {Servizio} from "../pages/services/Servizio";
import {Statistiche} from "../pages/Statistiche";
import {ImpostazioniGestionale} from "../pages/company/ImpostazioniGestionale";
import {Navigate} from "react-router-dom";
import {Utilizzo} from "../pages/Utilizzo";
import {Agenda} from "../pages/bookings/Agenda";
import {CercaPrenotazione} from "../pages/bookings/CercaPrenotazione";
import {NuovaPrenotazione} from "../pages/bookings/NuovaPrenotazione";
import {ModificaPrenotazione} from "../pages/bookings/ModificaPrenotazione";
import {FaqModel, Permissions, Roles} from "./Model";
import {ConfigurazioneIniziale} from "../pages/ConfigurazioneIniziale";


export interface PageInterface {
    title: string,
    link: string,
    icon?: ReactElement,
    privateRoute: boolean,
    sidebarVisible: boolean,
    routerVisible: boolean,
    element: ReactElement,
    subpages?: PageInterface[],
    roles?: Roles[],
    permissions?: Permissions[],
    supportoDescription?: string | ReactNode | null | undefined,
    supportoFaqs?: FaqModel[],
}

export const PAGES: PageInterface[] = [
    {
        title: "Login",
        link: "/login",
        privateRoute: false,
        sidebarVisible: false,
        routerVisible: false,
        element: <Login />,
        roles: [Roles.USER, Roles.ADMIN],
        permissions: []
    },
    {
        title: "Dashboard",
        link: "/",
        icon: <DashboardIcon />,
        privateRoute: true,
        sidebarVisible: true,
        routerVisible: true,
        element: <Dashboard />,
        roles: [Roles.USER, Roles.ADMIN],
        permissions: [],
        supportoDescription: "La Dashboard ti consente di avere una rapida occhiata sulle prenotazioni e sullo stato del gestionale.",
        supportoFaqs: [
            {id: "1", question: "Perché non ci sono prenotazioni?", answer: "I clienti non hanno prenotato nessun appuntamento con te nella giornata di oggi o di domani, verifica di aver inserito correttamente i turni di lavoro e verifica di essere stato assegnato ad almeno un servizio."},
            {id: "2", question: "Quali prenotazioni vedo in questa schermata?", answer: "In questa schermata vedi gli appuntamenti assegnati a te nella giornata di oggi o di domani. Non vedi quelli assegnati ad altre persone."},
            {id: "3", question: "Perché le prenotazioni non sono attive?", answer: "Se vedi un messaggio che ti avvisa che le prenotazioni sono disattivate, un admin ha disattivato le prenotazioni autonome da parte del cliente. Se pensi si tratti di un errore, rivolgiti a lui."},
        ]
    },
    {
        title: "Gestione azienda",
        link: "/gestione-azienda",
        icon: <Storefront />,
        privateRoute: true,
        sidebarVisible: true,
        routerVisible: true,
        element: <Navigate to="/gestione-azienda/dettagli-azienda" />,
        roles: [Roles.ADMIN],
        permissions: [],
        subpages: [
            {
                title: "Dettagli azienda",
                link: "/dettagli-azienda",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <DettagliAzienda />,
                roles: [Roles.ADMIN],
                permissions: [],
                supportoDescription: "Questa pagina ti consente di modificare i dettagli pubblici dell'azienda.",
                supportoFaqs: [
                    {id: "1", question: "Perché non posso modificare alcuni campi?", answer: "Alcuni campi, come il nome dell'attività e la Partita IVA, non possono essere modificati dall'utente. Se sono presenti errori e vuoi richiedere modifiche, contattaci e inviaci eventuale documentazione ausiliaria."},
                    {id: "2", question: "Dove saranno visibili queste informazioni?", answer: "Le informazioni sulla tua attività sono pubblicate nel sito in cui gli utenti possono prenotare gli appuntamenti."},
                ]
            },
            {
                title: "Orari di lavoro settimanali",
                link: "/orari-di-lavoro-settimanali",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <OrariDiLavoroSettimanali />,
                roles: [Roles.ADMIN],
                permissions: [],
                supportoDescription: "In questa pagina puoi specificare gli orari settimanali di apertura della tua attività. Queste informazioni sono slegate dal sistema di prenotazione: gli orari di apertura sono solo indicati nel sito web in cui gli utenti possono prenotare, con lo scopo di mostrare quando l'attività è aperta.",
                supportoFaqs: [
                    {id: "1", question: "Perché un cliente può prenotare fuori dall'orario di apertura?", answer: "Gli orari di lavoro settimanali hanno lo scopo di mostrare ai clienti quando l'attività è aperta al pubblico, non incidono sul funzionamento del sistema di prenotazione."},
                    {id: "2", question: "Posso impostare tutti i giorni su \"Chiuso\"?", answer: "Sì, è possibile impostare tutti i giorni della settimana su \"Chiuso\"."},
                ]
            },
            {
                title: "Chiusura aziendale",
                link: "/chiusura-aziendale",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <ChiusuraAziendale />,
                roles: [Roles.ADMIN],
                permissions: [],
                supportoDescription: "In questa pagina puoi specificare i periodi temporali occasionali in cui l'attività commerciale è chiusa. Durante i periodi di chiusura aziendale, non sarà possibile prenotare.",
                supportoFaqs: [
                    {id: "1", question: "I clienti possono prenotare nei periodi di chiusura aziendale?", answer: "No, i clienti non possono prenotare nei periodi di chiusura aziendale."},
                    {id: "2", question: "Cosa succede se ci sono già delle prenotazioni nel periodo di chiusura aziendale?", answer: "Il sistema non ti farà creare o modificare la chiusura aziendale. Dovrai modificare o annullare eventuali appuntamenti nel periodo interessato prima di creare o modificare la chiusura aziendale."},
                    {id: "3", question: "Perché non posso creare/modificare la chiusura aziendale?", answer: "Probabilmente ci sono prenotazioni confermate nel periodo interessato, oppure la chiusura aziendale si sovrappone con un'altra chiusura aziendale."},
                ]
            },
            {
                title: "Impostazioni gestionale",
                link: "/impostazioni-gestionale",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <ImpostazioniGestionale />,
                roles: [Roles.ADMIN],
                permissions: [],
                supportoDescription: "Questa pagina ti consente di specificare tutte le impostazioni sul funzionamento del gestionale e del sistema di prenotazione. Se vuoi, puoi rieseguire la configurazione guidata, cliccando sull'apposito link.",
                supportoFaqs: [
                    {id: "1", question: "Cosa succede se disabilito \"Prenotazioni attive\"?", answer: "Dal momento in cui disabiliti l'opzione \"Prenotazioni attive\" i clienti non potranno più prenotare autonomamente."},
                    {id: "2", question: "Cos'è lo \"Slot minimo\"?", answer: "E' la base della struttura dei calendari nel gestionale. Indica, ad esempio, ogni quanto il cliente può prenotare uno slot."},
                    {id: "3", question: "Cosa sono \"Delay minimo\" e \"Delay massimo\" per le prenotazioni?", answer: "Indicano l'intervallo di tempo in cui i clienti possono prenotare uno slot."},
                    {id: "4", question: "Cosa succede se disabilito \"Modifica prenotazione\"?", answer: "Dal momento in cui disabiliti l'opzione \"Modifica prenotazione\" i clienti non potranno più modificare la propria prenotazione."},
                    {id: "5", question: "Cosa succede se disabilito \"Annullamento prenotazione\"?", answer: "Dal momento in cui disabiliti l'opzione \"Annullamento prenotazione\" i clienti non potranno più annullare la propria prenotazione."},
                    {id: "6", question: "Cosa sono i reminder via email?", answer: "I reminder via email sono dei messaggi email che vengono inviati al cliente per aggiornamenti relativi alla propria prenotazione. Puoi scegliere quali tipi di reminder email inviare. I reminder via email sono gratuiti."},
                    {id: "7", question: "Cosa sono i reminder via WhatsApp?", answer: "I reminder via WhatsApp sono dei messaggi WhatsApp che vengono inviati al cliente per aggiornamenti relativi alla propria prenotazione. Puoi scegliere quali tipi di reminder WhatsApp inviare. I reminder WhatsApp sono soggetti a tariffazione a consumo."},
                    {id: "8", question: "I reminder via email sono a pagamento?", answer: "No, i reminder via email sono sempre inclusi nel piano di abbonamento."},
                    {id: "9", question: "I reminder via WhatsApp sono a pagamento?", answer: "Sì, i reminder WhatsApp sono soggetti a tariffazione a consumo. Consulta le tariffe sul nostro sito."},
                    {id: "10", question: "Cosa sono i turni automatici?", answer: "Se abiliti l'opzione, i turni di lavoro dei singoli dipendenti verranno generati automaticamente sulla base dei turni di default specificati per ogni dipendente. I turni di lavoro automatici vengono generati ogni lunedì a mezzanotte per la settimana lavorativa specificata nel campo \"Delay generazione turni automatici\"."},
                ]
            },
        ]
    },
    {
        title: "Servizi",
        link: "/servizi",
        icon: <Inventory />,
        privateRoute: true,
        sidebarVisible: true,
        routerVisible: true,
        element: <Navigate to="/servizi/servizi-offerti" />,
        roles: [Roles.ADMIN],
        permissions: [Permissions.EDIT_SERVIZI],
        subpages: [
            {
                title: "Servizi offerti",
                link: "/servizi-offerti",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <ServiziOfferti />,
                roles: [Roles.ADMIN],
                permissions: [Permissions.EDIT_SERVIZI],
                supportoDescription: "Questa pagina ti consente di gestire tutti i servizi che offri ai clienti.",
                supportoFaqs: [
                    {id: "1", question: "Cosa succede se elimino un servizio?", answer: "Il servizio verrà eliminato dal tuo negozio. Le prenotazioni con quel servizio non verranno eliminate, ma non potrai più vedere quale servizio è associato alla prenotazione."},
                    {id: "2", question: "Cosa significa \"Assegna utenti\"?", answer: "\"Assegna utenti\" ti consente di abilitare uno o più utenti all'erogazione del singolo servizio. Il cliente potrà, quindi, prenotare un servizio solo con gli utenti assegnati allo specifico servizio."},
                    {id: "3", question: "Quanti servizi posso aggiungere?", answer: "Il numero massimo di servizi che puoi erogare è specificato nel tuo piano di abbonamento. Se superi la soglia, potremmo doverti addebitare una somma di denaro aggiuntiva."},
                ]
            },
            {
                title: "Servizio",
                link: "/servizio",
                privateRoute: true,
                sidebarVisible: false,
                routerVisible: true,
                element: <Servizio />,
                roles: [Roles.ADMIN],
                permissions: [Permissions.EDIT_SERVIZI],
                supportoDescription: "Da questa pagina puoi modificare i dettagli relativi al singolo servizio e vedere un'anteprima di come verrà visualizzato il servizio ai clienti.",
                supportoFaqs: [
                    {id: "1", question: "Perché la durata è obbligatoria?", answer: "La durata del servizio è necessaria al fine di poter generare un appuntamento che non si vada a sovrapporre con altri appuntamenti."},
                    {id: "2", question: "Posso non specificare il prezzo?", answer: "Sì, puoi lasciare il campo vuoto."},
                    {id: "3", question: "E' necessario inserire un'immagine?", answer: "No, non è obbligatorio, ma te lo consigliamo caldamente."},
                ]
            },
            {
                title: "Nuovo servizio",
                link: "/nuovo-servizio",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <NuovoServizio />,
                roles: [Roles.ADMIN],
                permissions: [Permissions.EDIT_SERVIZI],
                supportoDescription: "Da questa pagina puoi inserire i dettagli relativi ad un nuovo servizio e vedere un'anteprima di come verrà visualizzato il servizio ai clienti.",
                supportoFaqs: [
                    {id: "1", question: "Perché la durata è obbligatoria?", answer: "La durata del servizio è necessaria al fine di poter generare un appuntamento che non si vada a sovrapporre con altri appuntamenti."},
                    {id: "2", question: "Posso non specificare il prezzo?", answer: "Sì, puoi lasciare il campo vuoto."},
                    {id: "3", question: "E' necessario inserire un'immagine?", answer: "No, non è obbligatorio, ma te lo consigliamo caldamente."},
                    {id: "4", question: "Cosa succede se creo un servizio quando ho già raggiunto la soglia massima di servizi?", answer: "Se il numero di servizi che eroghi supera la soglia specificata nel tuo piano di abbonamento, potremmo doverti addebitare una somma di denaro aggiuntiva."},
                ]
            },
            {
                title: "Assegna utenti a servizio",
                link: "/assegna-utenti-a-servizio",
                privateRoute: true,
                sidebarVisible: false,
                routerVisible: true,
                element: <AssegnaUtenti />,
                roles: [Roles.ADMIN],
                permissions: [Permissions.ASSIGN_SERVIZI],
                supportoDescription: "Questa pagina ti consente di assegnare il servizio ad uno o più utenti.",
                supportoFaqs: [
                    {id: "1", question: "Perché devo assegnare il servizio agli utenti?", answer: "Per abilitare uno o più utenti ad eseguire il singolo servizio. Quando il cliente prenota, sceglie il servizio e con quale dipendente farlo."},
                    {id: "2", question: "Cosa succede se non specifico nessun utente?", answer: "Il servizio non sarà prenotabile dagli utenti e dai clienti."},
                ]
            },
        ]
    },
    {
        title: "Gestione utenti",
        link: "/gestione-utenti",
        icon: <Group />,
        privateRoute: true,
        sidebarVisible: true,
        routerVisible: true,
        element: <Navigate to="/gestione-utenti/dipendenti" />,
        roles: [Roles.ADMIN],
        permissions: [Permissions.MANAGE_USERS],
        subpages: [
            {
                title: "Dipendenti",
                link: "/dipendenti",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <Dipendenti />,
                roles: [Roles.ADMIN],
                permissions: [Permissions.MANAGE_USERS],
                supportoDescription: "Questa pagina ti consente di gestire tutti i dipendenti della tua azienda.",
                supportoFaqs: [
                    {id: "1", question: "Cosa succede se elimino un utente?", answer: "L'utente verrà eliminato dalla tua attività. Le prenotazioni assegnate al dipendente non verranno eliminate, ma non potrai più vedere quale dipendente è associato alla prenotazione. Ti consigliamo di modificare o eliminare prima tutte le prenotazioni che lo interessano."},
                    {id: "2", question: "Cosa significa \"Assegna servizi\"?", answer: "\"Assegna servizi\" ti consente di abilitare l'utente all'erogazione di uno o più servizi. Il cliente potrà, quindi, prenotare un servizio solo con gli utenti assegnati allo specifico servizio."},
                    {id: "3", question: "Quanti utenti posso aggiungere?", answer: "Il numero massimo di utenti che puoi aggiungere è specificato nel tuo piano di abbonamento. Se superi la soglia, potremmo doverti addebitare una somma di denaro aggiuntiva."},
                    {id: "4", question: "Qual è la differenza tra \"Admin\" e \"User\"?", answer: "Gli utenti di tipo \"Admin\" hanno accesso a tutte le funzionalità del gestionale, mentre gli utenti di tipo \"User\" hanno accesso alle prenotazioni e alle funzionalità indicate nei permessi."},
                    {id: "5", question: "Cosa sono gli orari di lavoro?", answer: "Gli orari di lavoro indicano la disponibilità dei singoli dipendenti all'erogazione dei servizi. Gli slot disponibili per la prenotazione vengono generati sulla base degli orari di lavoro del dipendente."},
                    {id: "6", question: "Cosa sono le ferie?", answer: "Le ferie indicano i periodi temporali in cui non è possibile prenotare appuntamenti con il singolo dipendente."},
                ]
            },
            {
                title: "Utente",
                link: "/utente",
                privateRoute: true,
                sidebarVisible: false,
                routerVisible: true,
                element: <Utente />,
                roles: [Roles.ADMIN],
                permissions: [Permissions.MANAGE_USERS],
                supportoDescription: "In questa pagina puoi modificare i dettagli del singolo dipendente.",
                supportoFaqs: [
                    {id: "1", question: "Qual è la differenza tra \"Admin\" e \"User\"?", answer: "Gli utenti di tipo \"Admin\" hanno accesso a tutte le funzionalità del gestionale, mentre gli utenti di tipo \"User\" hanno accesso alle prenotazioni e alle funzionalità indicate nei permessi."},
                    {id: "2", question: "Cosa significa \"Assegna servizi\"?", answer: "\"Assegna servizi\" ti consente di abilitare l'utente all'erogazione di uno o più servizi. Il cliente potrà, quindi, prenotare un servizio solo con gli utenti assegnati allo specifico servizio."},
                ]
            },
            {
                title: "Turni dipendenti",
                link: "/turni-dipendenti",
                privateRoute: true,
                sidebarVisible: false,
                routerVisible: true,
                element: <TurniDipendenti />,
                roles: [Roles.ADMIN],
                permissions: [Permissions.MANAGE_USERS],
                supportoDescription: "Questa pagina ti consente di specificare i turni di lavoro del dipendente, ovvero gli orari di disponibilità in cui i clienti possono prenotare gli appuntamenti.",
                supportoFaqs: [
                    {id: "1", question: "Cosa sono i \"Turni di lavoro futuri\"?", answer: "I turni di lavoro futuri sono le disponibilità nel futuro del singolo dipendente."},
                    {id: "2", question: "Cos'è il \"Turno settimanale di default\"?", answer: "Il Turno settimanale di default indica gli orari di disponibilità di default del dipendente. Sulla base di questi orari, verranno generati automaticamente i turni di lavoro settimanali, se la funzionalità è attiva."},
                    {id: "3", question: "Cosa succede se elimino o modifico un turno in cui ci sono già delle prenotazioni?", answer: "Il sistema ti darà un errore. Devi prima modificare o annullare eventuali appuntamenti che interessano l'utente nel periodo interessato e poi modificare o eliminare il turno."},
                ]
            },
            {
                title: "Ferie dipendenti",
                link: "/ferie-dipendenti",
                privateRoute: true,
                sidebarVisible: false,
                routerVisible: true,
                element: <FerieDipendenti />,
                roles: [Roles.ADMIN],
                permissions: [Permissions.MANAGE_USERS],
                supportoDescription: "Questa pagina ti consente di specificare dei periodi temporali in cui i clienti non possono prenotare un appuntamento con il dipendente.",
                supportoFaqs: [
                    {id: "1", question: "I clienti possono prenotare nei periodi di ferie del dipendente?", answer: "No, i clienti non possono fissare appuntamenti con il dipendente nei periodi di ferie."},
                    {id: "2", question: "Cosa succede se ci sono già delle prenotazioni nel periodo di ferie?", answer: "Il sistema non ti farà creare o modificare il singolo periodo di ferie. Dovrai modificare o annullare eventuali appuntamenti nel periodo interessato prima di creare o modificare il periodo di ferie."},
                    {id: "3", question: "Perché non posso creare/modificare il periodo di ferie?", answer: "Probabilmente ci sono prenotazioni confermate nel periodo interessato, oppure il periodo di ferie si sovrappone con un altro periodo già presente."},
                ]
            },
            {
                title: "Nuovo utente",
                link: "/nuovo-utente",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <NuovoUtente />,
                roles: [Roles.ADMIN],
                permissions: [Permissions.MANAGE_USERS],
                supportoDescription: "Da questa pagina puoi inserire i dettagli relativi ad un nuovo dipendente. Il nuovo utente, riceverà una mail con i dettagli di accesso al gestionale.",
                supportoFaqs: [
                    {id: "1", question: "Qual è la differenza tra \"Admin\" e \"User\"?", answer: "Gli utenti di tipo \"Admin\" hanno accesso a tutte le funzionalità del gestionale, mentre gli utenti di tipo \"User\" hanno accesso alle prenotazioni e alle funzionalità indicate nei permessi."},
                    {id: "2", question: "Cosa significa \"Assegna servizi\"?", answer: "\"Assegna servizi\" ti consente di abilitare l'utente all'erogazione di uno o più servizi. Il cliente potrà, quindi, prenotare un servizio solo con gli utenti assegnati allo specifico servizio."},
                    {id: "3", question: "Cosa succede se creo un dipendente quando ho già raggiunto la soglia massima di utenti?", answer: "Se il numero di utenti presenti supera la soglia specificata nel tuo piano di abbonamento, potremmo doverti addebitare una somma di denaro aggiuntiva."},
                    {id: "4", question: "Il nuovo utente potrà accedere al gestionale?", answer: "Sì, quando aggiungi un nuovo utente, invieremo una mail all'utente con i dettagli di accesso alla piattaforma."},
                ]
            },
            {
                title: "Assegna servizi a utente",
                link: "/assegna-servizi-a-utente",
                privateRoute: true,
                sidebarVisible: false,
                routerVisible: true,
                element: <AssegnaServizi />,
                roles: [Roles.ADMIN],
                permissions: [Permissions.ASSIGN_SERVIZI],
                supportoDescription: "Questa pagina ti consente di assegnare uno o più servizi all'utente.",
                supportoFaqs: [
                    {id: "1", question: "Perché devo assegnare il servizio agli utenti?", answer: "Per abilitare gli utenti ad eseguire i servizi. Quando il cliente prenota, sceglie il servizio e con quale dipendente farlo."},
                    {id: "2", question: "Cosa succede se non specifico nessun servizio?", answer: "Il servizio non sarà prenotabile con il singolo utente dai clienti."},
                ]
            },
        ]
    },
    {
        title: "Gestione clienti",
        link: "/gestione-clienti",
        icon: <ImportContacts />,
        privateRoute: true,
        sidebarVisible: true,
        routerVisible: true,
        element: <Navigate to="/gestione-clienti/libro-clienti" />,
        roles: [Roles.ADMIN],
        permissions: [Permissions.MANAGE_CUSTOMERS],
        subpages: [
            {
                title: "Libro clienti",
                link: "/libro-clienti",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <LibroClienti />,
                roles: [Roles.ADMIN],
                permissions: [Permissions.MANAGE_CUSTOMERS],
                supportoDescription: "Questa pagina ti consente di gestire tutti i clienti della tua azienda.",
                supportoFaqs: [
                    {id: "1", question: "Cosa succede se elimino un cliente?", answer: "Il cliente verrà eliminato dalla tua attività. Le prenotazioni che interessano il cliente non verranno eliminate, ma non potrai più vedere quale cliente è associato alla prenotazione."},
                    {id: "2", question: "Cosa significa \"Consenso marketing\"?", answer: "Il termine indica se il cliente ha espresso o meno il proprio consenso a ricevere comunicazioni di carattere promozionale."},
                ]
            },
            {
                title: "Cliente",
                link: "/cliente",
                privateRoute: true,
                sidebarVisible: false,
                routerVisible: true,
                element: <Cliente />,
                roles: [Roles.ADMIN],
                permissions: [Permissions.MANAGE_CUSTOMERS],
                supportoDescription: "In questa pagina puoi modificare i dettagli del singolo cliente.",
                supportoFaqs: [
                    {id: "1", question: "Il numero di telefono è obbligatorio?", answer: "No, ma ti consigliamo di inserirlo."},
                    {id: "2", question: "L'indirizzo email è obbligatorio?", answer: "Sì, abbiamo bisogno della mail per poter assegnare correttamente il cliente alle sue prenotazioni."},
                ]
            },
            {
                title: "Nuovo cliente",
                link: "/nuovo-cliente",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <NuovoCliente />,
                roles: [Roles.ADMIN],
                permissions: [Permissions.MANAGE_CUSTOMERS],
                supportoDescription: "In questa pagina puoi inserire i dettagli di un nuovo cliente.",
                supportoFaqs: [
                    {id: "1", question: "Il numero di telefono è obbligatorio?", answer: "No, ma ti consigliamo di inserirlo."},
                    {id: "2", question: "L'indirizzo email è obbligatorio?", answer: "Sì, abbiamo bisogno della mail per poter assegnare correttamente il cliente alle sue prenotazioni."},
                ]
            },
            {
                title: "Newsletter",
                link: "/newsletter",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <Newsletter />,
                roles: [Roles.ADMIN],
                permissions: [Permissions.EMAIL_CLIENTI],
                supportoDescription: "Questa pagina ti consente di inviare una mail ai tuoi clienti.",
                supportoFaqs: [
                    {id: "1", question: "Perché non posso inviare una mail promozionale a tutti gli utenti?", answer: "Perché puoi inviare comunicazioni promozionali solo agli utenti che hanno espresso il proprio consenso a ricevere comunicazioni promozionali."},
                    {id: "2", question: "Perché non posso cancellare il link per la revoca del consenso?", answer: "Perché tutti gli utenti hanno il diritto di potersi disiscrivere dalla newsletter."},
                ]
            },
        ]
    },
    {
        title: "Prenotazioni",
        link: "/prenotazioni",
        icon: <Event />,
        privateRoute: true,
        sidebarVisible: true,
        routerVisible: true,
        element: <Navigate to="/prenotazioni/tutte-le-prenotazioni" />,
        roles: [Roles.USER, Roles.ADMIN],
        permissions: [],
        subpages: [
            {
                title: "Tutte le prenotazioni",
                link: "/tutte-le-prenotazioni",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <TutteLePrenotazioni />,
                roles: [Roles.USER, Roles.ADMIN],
                permissions: [],
                supportoDescription: "Questa pagina ti mostra tutte le prenotazioni passate e future.",
                supportoFaqs: [
                    {id: "1", question: "Perché non ci sono prenotazioni?", answer: "I clienti non hanno prenotato nessun appuntamento, verifica di aver inserito correttamente i turni di lavoro e verifica di essere stato assegnato ad almeno un servizio."},
                    {id: "2", question: "Perché non vedo le prenotazioni di altri dipendenti?", answer: "Probabilmente, sei stato abilitato a vedere solo le prenotazioni che interessano te. Contatta un admin per vedere anche le prenotazioni di altri utenti."},
                    {id: "3", question: "Quante prenotazioni mensili posso creare?", answer: "Il numero massimo di prenotazioni al mese che puoi aggiungere è specificato nel tuo piano di abbonamento. Se la soglia viene superata, potremmo doverti addebitare una somma di denaro aggiuntiva."},
                ]
            },
            {
                title: "Agenda",
                link: "/agenda",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <Agenda />,
                roles: [Roles.USER, Roles.ADMIN],
                permissions: [],
                supportoDescription: "Questa schermata ti mostra gli appuntamenti che interessano te nella giornata selezionata.",
                supportoFaqs: [
                    {id: "1", question: "Perché non ci sono prenotazioni?", answer: "I clienti non hanno prenotato nessun appuntamento con te nella giornata selezionata, verifica di aver inserito correttamente i turni di lavoro e verifica di essere stato assegnato ad almeno un servizio."},
                    {id: "2", question: "Quali prenotazioni vedo in questa schermata?", answer: "In questa schermata vedi gli appuntamenti assegnati a te nella giornata selezionata. Non vedi quelli assegnati ad altre persone."},
                ]
            },
            {
                title: "Prenotazione",
                link: "/prenotazione",
                privateRoute: true,
                sidebarVisible: false,
                routerVisible: true,
                element: <Prenotazione />,
                roles: [Roles.USER, Roles.ADMIN],
                permissions: [],
                supportoDescription: "Questa pagina ti mostra i dettagli della singola prenotazione.",
                supportoFaqs: [
                    {id: "1", question: "Perché non posso modificare/annullare la prenotazione?", answer: "Se non puoi modificare o annullare la prenotazione, significa che non hai i permessi per farlo oppure che l'appuntamento si è già svolto."},
                    {id: "2", question: "Perché non posso vedere alcuni campi?", answer: "Probabilmente perché sono stati eliminati i dati relativi al cliente, al servizio o al dipendente."},
                    {id: "3", question: "Cosa vuol dire che il servizio di reminder email non è attivo?", answer: "Significa che non verranno inviate notifiche email al cliente, poiché un admin ha disattivato la funzionalità."},
                    {id: "4", question: "Cosa vuol dire che il servizio di reminder WhatsApp non è attivo?", answer: "Significa che non verranno inviate notifiche WhatsApp al cliente, poiché un admin ha disattivato la funzionalità."},
                ]
            },
            {
                title: "Nuova prenotazione",
                link: "/nuova-prenotazione",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <NuovaPrenotazione />,
                roles: [Roles.USER, Roles.ADMIN],
                permissions: [],
                supportoDescription: "Questa pagina ti consente di creare una prenotazione.",
                supportoFaqs: [
                    {id: "1", question: "Perché non ci sono servizi?", answer: "Perché non sono stati creati servizi disponibili ad essere prenotati."},
                    {id: "2", question: "Perché non ci sono dipendenti?", answer: "Perché il servizio non è stato assegnato a nessun utente, oppure tu non puoi creare prenotazioni per altri utenti. In tal caso, prova a selezionare un servizio che puoi erogare."},
                    {id: "3", question: "Perché non ci sono slot disponibili per la prenotazione?", answer: "Perché il dipendente che hai selezionato non ha turni di lavoro disponibili alla prenotazione."},
                ]
            },
            {
                title: "Modifica prenotazione",
                link: "/modifica-prenotazione",
                privateRoute: true,
                sidebarVisible: false,
                routerVisible: true,
                element: <ModificaPrenotazione />,
                roles: [Roles.USER, Roles.ADMIN],
                permissions: [],
                supportoDescription: "Questa pagina ti consente di modificare una prenotazione.",
                supportoFaqs: [
                    {id: "1", question: "Perché non ci sono servizi?", answer: "Perché non sono stati creati servizi disponibili ad essere prenotati."},
                    {id: "2", question: "Perché non ci sono dipendenti?", answer: "Perché il servizio non è stato assegnato a nessun utente, oppure tu non puoi creare prenotazioni per altri utenti. In tal caso, prova a selezionare un servizio che puoi erogare."},
                    {id: "3", question: "Perché non ci sono slot disponibili per la prenotazione?", answer: "Perché il dipendente che hai selezionato non ha turni di lavoro disponibili alla prenotazione."},
                ]
            },
            {
                title: "Cerca prenotazione",
                link: "/cerca-prenotazione",
                privateRoute: true,
                sidebarVisible: true,
                routerVisible: true,
                element: <CercaPrenotazione />,
                roles: [Roles.USER, Roles.ADMIN],
                permissions: [],
                supportoDescription: "Questa pagina ti consente di ricercare una prenotazione.",
                supportoFaqs: [
                    {id: "1", question: "Perché non trovo la prenotazione?", answer: "Ricontrolla i dati che hai inserito. Se non trovi ancora la tua prenotazione, probabilmente sei abilitato a vedere solo le prenotazioni che interessano te e non quelle di altri dipendenti."},
                ]
            },
        ]
    },
    {
        title: "Statistiche",
        link: "/statistiche",
        icon: <BarChart />,
        privateRoute: true,
        sidebarVisible: true,
        routerVisible: true,
        element: <Statistiche />,
        roles: [Roles.ADMIN],
        permissions: [Permissions.STATISTICHE],
        supportoDescription: "Questa pagina ti fornisce le statistiche in tempo reale sull'andamento della tua attività.",
        supportoFaqs: []
    },
    {
        title: "Utilizzo",
        link: "/utilizzo",
        icon: <Euro />,
        privateRoute: true,
        sidebarVisible: true,
        routerVisible: true,
        element: <Utilizzo />,
        roles: [Roles.ADMIN],
        permissions: [Permissions.UTILIZZO],
        supportoDescription: "Questa pagina ti fornisce tutte le informazioni relative al tuo piano di abbonamento, all'utilizzo e al consumo.",
        supportoFaqs: [
            {id: "1", question: "Cosa significa \"È stato superato il limite di utilizzo\"?", answer: "Significa che hai raggiunto e/o superato la soglia prevista dal tuo piano di abbonamento. Potremmo doverti addebitare una somma di denaro aggiuntiva."},
            {id: "2", question: "Come posso fare un upgrade o un downgrade del piano di abbonamento?", answer: "Per richiedere un upgrade o un downgrade del piano di abbonamento contattaci via mail."},
            {id: "3", question: "A che periodo temporale fanno riferimento le statistiche in questa pagina?", answer: "Al mese corrente."},
        ]
    },
    {
        title: "Impostazioni",
        link: "/impostazioni",
        privateRoute: true,
        sidebarVisible: false,
        routerVisible: true,
        element: <Settings />,
        roles: [Roles.USER, Roles.ADMIN],
        permissions: [],
        supportoDescription: "Questa pagina è dedicata alle impostazioni del tuo profilo e del tuo account.",
        supportoFaqs: [
            {id: "1", question: "Perché non posso modificare alcune informazioni?", answer: "Perché non hai i permessi per farlo. Contatta un admin."},
            {id: "2", question: "Perché non posso modificare la lingua?", answer: "Al momento l'unica lingua disponibile è quella italiana. Implementeremo in futuro la possibilità di cambiare lingua."},
            {id: "3", question: "Cosa sono i \"Turni di lavoro futuri\"?", answer: "I turni di lavoro futuri sono le disponibilità nel futuro del singolo dipendente."},
            {id: "4", question: "Cos'è il \"Turno settimanale di default\"?", answer: "Il Turno settimanale di default indica gli orari di disponibilità di default del dipendente. Sulla base di questi orari, verranno generati automaticamente i turni di lavoro settimanali, se la funzionalità è attiva."},
            {id: "5", question: "Cosa succede se elimino o modifico un turno in cui ci sono già delle prenotazioni?", answer: "Il sistema ti darà un errore. Devi prima modificare o annullare eventuali appuntamenti che interessano l'utente nel periodo interessato e poi modificare o eliminare il turno."},
            {id: "6", question: "I clienti possono prenotare nei periodi di ferie del dipendente?", answer: "No, i clienti non possono fissare appuntamenti con il dipendente nei periodi di ferie."},
            {id: "7", question: "Cosa succede se ci sono già delle prenotazioni nel periodo di ferie?", answer: "Il sistema non ti farà creare o modificare il singolo periodo di ferie. Dovrai modificare o annullare eventuali appuntamenti nel periodo interessato prima di creare o modificare il periodo di ferie."},
            {id: "8", question: "Perché non posso creare/modificare il periodo di ferie?", answer: "Probabilmente ci sono prenotazioni confermate nel periodo interessato, oppure il periodo di ferie si sovrappone con un altro periodo già presente."},
        ]
    },
    {
        title: "Configurazione iniziale",
        link: "/configurazione-guidata",
        privateRoute: true,
        sidebarVisible: false,
        routerVisible: true,
        element: <ConfigurazioneIniziale />,
        roles: [Roles.ADMIN],
        permissions: [],
    },
]

export function getPageTitleByUrl(url: string): string | null {
    // 1. Fai uno split dell'url in base a "/"
    let parts = url.split("/").filter(Boolean); // Rimuove i valori vuoti
    if(url === "/") parts = [""]

    if (parts.length === 1) {
        // 2. Trova la pagina di primo livello
        const page = PAGES.find(p => p.link === `/${parts[0]}`);
        return page ? page.title : null;
    } else if (parts.length === 2) {
        // 3. Trova la pagina di primo livello e successivamente quella di secondo livello
        const parentPage = PAGES.find(p => p.link === `/${parts[0]}`);
        if (parentPage && parentPage.subpages) {
            const subpage = parentPage.subpages.find(sp => sp.link === `/${parts[1]}`);
            return subpage ? subpage.title : null;
        }
    }

    return null;
}

export function getSupportoDescriptionByUrl(url: string): string | ReactNode | null | undefined {
    // 1. Fai uno split dell'url in base a "/"
    let parts = url.split("/").filter(Boolean); // Rimuove i valori vuoti
    if(url === "/") parts = [""]

    if (parts.length === 1) {
        // 2. Trova la pagina di primo livello
        const page = PAGES.find(p => p.link === `/${parts[0]}`);
        return page ? page.supportoDescription : null;
    } else if (parts.length === 2) {
        // 3. Trova la pagina di primo livello e successivamente quella di secondo livello
        const parentPage = PAGES.find(p => p.link === `/${parts[0]}`);
        if (parentPage && parentPage.subpages) {
            const subpage = parentPage.subpages.find(sp => sp.link === `/${parts[1]}`);
            return subpage ? subpage.supportoDescription : null;
        }
    }

    return null;
}

export function getSupportoFaqsByUrl(url: string): FaqModel[] {
    // 1. Fai uno split dell'url in base a "/"
    let parts = url.split("/").filter(Boolean); // Rimuove i valori vuoti
    if(url === "/") parts = [""]

    if (parts.length === 1) {
        // 2. Trova la pagina di primo livello
        const page = PAGES.find(p => p.link === `/${parts[0]}`);
        return page && page.supportoFaqs ? page.supportoFaqs : [];
    } else if (parts.length === 2) {
        // 3. Trova la pagina di primo livello e successivamente quella di secondo livello
        const parentPage = PAGES.find(p => p.link === `/${parts[0]}`);
        if (parentPage && parentPage.subpages) {
            const subpage = parentPage.subpages.find(sp => sp.link === `/${parts[1]}`);
            return subpage && subpage.supportoFaqs ? subpage.supportoFaqs : [];
        }
    }

    return [];
}