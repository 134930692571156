import {PageStandard} from "../../utils/PageStandard";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardOverflow,
    Checkbox,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Stack,
    Typography
} from "@mui/joy";
import {CustomerModel} from "../../utils/Model";
import {APIurl, isValidPhoneNumber, TelefonoInput} from "../../utils/Utils";
import React, {useContext, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";

export function NuovoCliente() {

    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const initialData : CustomerModel = {
        id: "",
        name: "",
        surname: "",
        email: "",
        phoneNumberPrefisso: "39",
        phoneNumber: "",
        terms: true,
        marketing: true,
        createdAt: "",
    }
    const [data, setData] = useState<CustomerModel>(initialData)

    return (
        <PageStandard title="Nuovo cliente" sidebarVisible={true}>
            <Stack sx={{
                display: 'flex',
                maxWidth: '800px',
                mx: 'auto',
                px: { xs: 2, md: 6 },
                py: { xs: 2, md: 3 },
            }}>
                <Card>
                <Box sx={{ mb: 1 }}>
                    <Typography level="title-md">Informazioni nuovo cliente</Typography>
                    <Typography level="body-sm">
                        Compila i campi per creare il profilo del nuovo cliente
                    </Typography>
                </Box>
                <Divider />
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{ my: 1 }}
                >
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Nome *</FormLabel>
                                <Input size="sm"
                                       type="text"
                                       placeholder="Mario"
                                       value={data.name}
                                       onChange={e => setData({...data, name: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Cognome *</FormLabel>
                                <Input size="sm"
                                       type="text"
                                       placeholder="Rossi"
                                       value={data.surname}
                                       onChange={e => setData({...data, surname: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Email *</FormLabel>
                                <Input size="sm"
                                       type="email"
                                       placeholder="nome@email.com"
                                       value={data.email}
                                       onChange={e => setData({...data, email: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Telefono</FormLabel>
                                <TelefonoInput valuePrefisso={data.phoneNumberPrefisso ?? "39"}
                                               valuePhone={data.phoneNumber ?? ""}
                                               onChangePrefisso={e => setData({...data, phoneNumberPrefisso: e})}
                                               onChangePhone={e => setData({...data, phoneNumber: e})}
                                               error={!isValidPhoneNumber(data.phoneNumberPrefisso + " " + data.phoneNumber)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <Checkbox label="Consenso trattamento dei dati"
                                          size="sm"
                                          checked={data.terms}
                                          onChange={() => setData({...data, terms: !data.terms})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <Checkbox label="Consenso marketing"
                                          size="sm"
                                          checked={data.marketing}
                                          onChange={() => setData({...data, marketing: !data.marketing})}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                </Stack>
                <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                    <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                        <Button size="sm"
                                variant="outlined"
                                color="neutral"
                                onClick={() => {
                                    if(window.confirm("Cancellare tutti i campi?")) setData(initialData)
                                }}
                                disabled={data === initialData}
                        >
                            Cancella campi
                        </Button>
                        <Button size="sm"
                                variant="solid"
                                disabled={data.name==="" || data.surname==="" || data.email==="" || data.phoneNumber==="" || !data.terms ||loading}
                                onClick={() => addCustomer()}
                        >
                            Aggiungi
                        </Button>
                    </CardActions>
                </CardOverflow>
            </Card>
            </Stack>
        </PageStandard>
    )


    async function addCustomer() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/addCustomer`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(data)
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Cliente creato con successo")
                setData(initialData)
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}