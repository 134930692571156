import {PageStandard} from "../utils/PageStandard";
import {ReactNode, useContext, useEffect, useState} from "react";
import {AuthContext} from "../utils/AuthProvider";
import {FeedbackContext} from "../utils/FeedbackContext";
import {Alert, Card, CardContent, Grid, Stack, Typography} from "@mui/joy";
import {ArrowForward, CalendarMonth, Warning} from "@mui/icons-material";
import {AlertModel, BookingFormattedModel} from "../utils/Model";
import firebase from "firebase/compat/app";
import {APIurl} from "../utils/Utils";
import {Link} from "react-router-dom";
import moment from "moment";
import {CardPrenotazione} from "./bookings/Agenda";

export function Dashboard() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const [alert, setAlert] = useState<AlertModel | null>(null)
    const [bookingsToday, setBookingsToday] = useState<BookingFormattedModel[]>([])
    const [bookingsTomorrow, setBookingsTomorrow] = useState<BookingFormattedModel[]>([])

    useEffect(() => {
        fetchAlert().then(() => {})
        fetchBookingsAgendaToday().then(() => {})
        fetchBookingsAgendaTomorrow().then(() => {})
    }, []);
    async function fetchAlert(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getAlert`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (response.ok) {
                const data = await response.json()
                setAlert(data)
            }

        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }
    async function fetchBookingsAgendaToday(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getBookingsAgenda/${moment().format("YYYY-MM-DD")}/${user.uid}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setBookingsToday(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }
    async function fetchBookingsAgendaTomorrow(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getBookingsAgenda/${moment().add(1, "day").format("YYYY-MM-DD")}/${user.uid}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setBookingsTomorrow(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    return (
        <PageStandard title="Dashboard" sidebarVisible={true}>

            <Stack direction="row" spacing={3} mb={3}>
                {
                    !userCompany.gestionaleSettings.prenotazioniAttive &&
                    <Alert color="warning" startDecorator={<Warning />}>
                        <Stack>
                            <Typography level="title-md">Attenzione!</Typography>
                            <Typography level="body-sm">Le prenotazioni sono disabilitate. I clienti non possono prenotare autonomamente. Attiva le prenotazioni in <i>Impostazioni gestionale</i></Typography>
                        </Stack>
                    </Alert>
                }
                {
                    alert &&
                    <Alert color="warning" startDecorator={<Warning />}>
                        <Stack>
                            <Typography level="title-md">Avviso!</Typography>
                            <Typography level="body-sm">{alert.text}</Typography>
                        </Stack>
                    </Alert>
                }
            </Stack>

            <Grid container spacing={2} mb={3}>
                <CardDash label="Prenotazioni di oggi in cui sei coinvolto" value={bookingsToday.length} />
                <CardDash label="Prenotazioni di domani in cui sei coinvolto" value={bookingsTomorrow.length} />
                <CardDashLink title="Calendario" label="Tutte le prenotazioni" link="/prenotazioni/tutte-le-prenotazioni" variant="solid" color="primary"
                              icon={<CalendarMonth sx={{position:"absolute", fontSize:"8rem", opacity:0.2, bottom:"-2rem", right:"-2rem", mixBlendMode:"overlay"}}/>}
                />
                <CardDashLink title="Nuova prenotazione" label="Aggiungi una nuova prenotazione" link="/prenotazioni/nuova-prenotazione" variant="outlined" color="primary" />
            </Grid>

            <Stack direction={{xs:"column", sm:"row"}} spacing={5} pb={4}>
                <Stack spacing={2} flex={1}>
                    <Typography level="title-lg" fontWeight="bold">Agenda di oggi</Typography>
                    {
                        bookingsToday.length > 0 ?
                            <Stack spacing={1}>
                                {
                                    bookingsToday.map(a => <CardPrenotazione key={a.id} booking={a} dateVisible={false} />)
                                }
                            </Stack>
                            :
                            <Typography level="title-sm" fontStyle="italic">Non ci sono eventi che ti coinvolgono per oggi.</Typography>
                    }
                </Stack>
                <Stack spacing={2} flex={1}>
                    <Typography level="title-lg" fontWeight="bold">Agenda di domani</Typography>
                    {
                        bookingsTomorrow.length > 0 ?
                            <Stack spacing={1}>
                                {
                                    bookingsTomorrow.map(a => <CardPrenotazione key={a.id} booking={a} dateVisible={false} />)
                                }
                            </Stack>
                            :
                            <Typography level="title-sm" fontStyle="italic">Non ci sono eventi che ti coinvolgono per domani.</Typography>
                    }
                </Stack>
            </Stack>

        </PageStandard>
    )

    function CardDash(props: {label: string, value: number | string | undefined}) {
        return (
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Card sx={{textAlign:"center", height:"100%", width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", p:2}}>
                    <CardContent sx={{textAlign:"center", height:"100%", width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", p:2}}>
                        <Typography level="title-sm">{props.label}</Typography>
                        <Typography level="h2" fontWeight="bold">{props.value}</Typography>
                    </CardContent>
                </Card>
            </Grid>
        )
    }
    function CardDashLink(props: {title: string, label: string, variant: "plain" | "outlined" | "soft" | "solid" | undefined, color: "primary" | "neutral" | "danger" | "success" | "warning" | undefined, link: string, icon?: ReactNode}) {
        return (
            <Grid xs={12} sm={6} md={6} lg={3}>
                <Link to={props.link}>
                    <Card sx={{textAlign:"center", height:"100%", width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", p:2, overflow:"hidden"}}
                          className="hoverScale"
                          invertedColors
                          color={props.color}
                          variant={props.variant}>
                        <CardContent sx={{textAlign:"center", height:"100%", width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", p:2}}>
                            <Typography level="h2" fontWeight="bold">{props.title}</Typography>
                            <Typography level="title-sm">{props.label}</Typography>
                            {
                                props.icon ?
                                    props.icon
                                    :
                                    <ArrowForward sx={{position:"absolute", fontSize:"8rem", opacity:0.2, bottom:"-2rem", right:"-2rem", mixBlendMode:"overlay"}}/>
                            }
                        </CardContent>
                    </Card>
                </Link>
            </Grid>
        )
    }

}