import {PageStandard} from "../../utils/PageStandard";
import {
    Autocomplete,
    Box,
    Button,
    Card,
    CardActions,
    CardOverflow, CircularProgress,
    Divider,
    FormControl, FormHelperText,
    FormLabel,
    Grid,
    Input,
    Stack,
    Typography
} from "@mui/joy";
import {CompanyDetailsModel} from "../../utils/Model";
import {APIurl, isValidPhoneNumber, TelefonoInput} from "../../utils/Utils";
import React, {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {countries} from "../../utils/Countries";
import {companyCategories} from "../../utils/CompanyCategories";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";

export function DettagliAzienda() {

    const {loading, bigLoading, error, message, setLoading, setBigLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany, logo} = useContext(AuthContext)

    const companyDetailsInitial = {
        id: "",
        shortName: "",
        longName: "",
        vatNumber: "",
        country: "",
        city: "",
        postalCode: "",
        state: "",
        address: "",
        phoneNumber: "",
        email: "",
        website: "",
        category: "",
    }
    const [serverCompanyDetails, setServerCompanyDetails] = useState<CompanyDetailsModel | undefined>()
    const [companyDetails, setCompanyDetails] = useState<CompanyDetailsModel>(companyDetailsInitial)
    const [newLogo, setNewLogo] = useState<File | null | undefined>()

    useEffect(() => {
        fetchCompanyDetails().then(() => {})
    }, []);

    async function fetchCompanyDetails(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getCompany`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setServerCompanyDetails(data)
                setCompanyDetails(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    if(loading || bigLoading || companyDetails === companyDetailsInitial) return <CircularProgress />
    return (
        <PageStandard title="Dettagli azienda" sidebarVisible={true}>
            <Stack spacing={3} sx={{
                display: 'flex',
                maxWidth: '1000px',
                mx: 'auto',
                px: { xs: 2, md: 6 },
                py: { xs: 2, md: 3 },
            }}>
                <Card>
                <Box sx={{ mb: 1 }}>
                    <Typography level="title-md">Informazioni azienda</Typography>
                    <Typography level="body-sm">
                        Modifica e controlla i dati pubblici della tua azienda
                    </Typography>
                </Box>
                <Divider />
                <Grid container spacing={2} sx={{ flexGrow: 1, p:1 }}>
                    <Grid xs={12} md={12}>
                        <FormControl>
                            <FormLabel>Nome completo *</FormLabel>
                            <Input size="sm"
                                   type="text"
                                   placeholder="Nome completo"
                                   value={companyDetails.longName}
                                   onChange={e => setCompanyDetails({...companyDetails, longName: e.target.value})}
                                   disabled
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <FormControl>
                            <FormLabel>Nome breve *</FormLabel>
                            <Input size="sm"
                                   type="text"
                                   placeholder="Nome breve"
                                   value={companyDetails.shortName}
                                   onChange={e => setCompanyDetails({...companyDetails, shortName: e.target.value})}
                                   disabled
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <FormControl>
                            <FormLabel>Partita IVA o Codice Fiscale *</FormLabel>
                            <Input size="sm"
                                   type="text"
                                   placeholder="Partita IVA o Codice Fiscale"
                                   value={companyDetails.vatNumber}
                                   onChange={e => setCompanyDetails({...companyDetails, vatNumber: e.target.value})}
                                   disabled
                            />
                        </FormControl>
                    </Grid>
                </Grid>
                <Divider />
                <Grid container spacing={2} sx={{ flexGrow: 1, p:1 }}>
                    <Grid xs={12} md={4}>
                        <FormControl>
                            <FormLabel>Nazione *</FormLabel>
                            <Autocomplete
                                size="sm"
                                autoHighlight
                                value={companyDetails.country}
                                onChange={(e, newValue) => setCompanyDetails({...companyDetails, country: newValue!})}
                                options={countries}
                                slotProps={{
                                    input: {
                                        autoComplete: 'new-password',
                                    },
                                }}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <FormControl>
                            <FormLabel>Città *</FormLabel>
                            <Input size="sm"
                                   type="text"
                                   placeholder="Città"
                                   value={companyDetails.city}
                                   onChange={e => setCompanyDetails({...companyDetails, city: e.target.value})}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={2}>
                        <FormControl>
                            <FormLabel>Codice postale *</FormLabel>
                            <Input size="sm"
                                   type="text"
                                   placeholder="Codice postale"
                                   value={companyDetails.postalCode}
                                   onChange={e => setCompanyDetails({...companyDetails, postalCode: e.target.value})}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={2}>
                        <FormControl>
                            <FormLabel>Provincia *</FormLabel>
                            <Input size="sm"
                                   type="text"
                                   placeholder="Provincia"
                                   value={companyDetails.state}
                                   onChange={e => setCompanyDetails({...companyDetails, state: e.target.value})}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={12}>
                        <FormControl>
                            <FormLabel>Indirizzo completo *</FormLabel>
                            <Input size="sm"
                                   type="text"
                                   placeholder="Indirizzo completo"
                                   value={companyDetails.address}
                                   onChange={e => setCompanyDetails({...companyDetails, address: e.target.value})}
                            />
                        </FormControl>
                    </Grid>
                </Grid><Divider />
                    <Grid container spacing={2} sx={{ flexGrow: 1, p:1 }}>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Telefono</FormLabel>
                                <TelefonoInput valuePrefisso={companyDetails.phoneNumberPrefisso ?? "39"}
                                               valuePhone={companyDetails.phoneNumber ?? ""}
                                               onChangePrefisso={e => setCompanyDetails({...companyDetails, phoneNumberPrefisso: e})}
                                               onChangePhone={e => setCompanyDetails({...companyDetails, phoneNumber: e})}
                                               error={!isValidPhoneNumber(companyDetails.phoneNumberPrefisso + " " + companyDetails.phoneNumber)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input size="sm"
                                       type="email"
                                       placeholder="Email"
                                       value={companyDetails.email}
                                       onChange={e => setCompanyDetails({...companyDetails, email: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Sito web</FormLabel>
                                <Input size="sm"
                                       type="url"
                                       placeholder="Sito web"
                                       value={companyDetails.website}
                                       onChange={e => setCompanyDetails({...companyDetails, website: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Categoria</FormLabel>
                                <Autocomplete
                                    size="sm"
                                    autoHighlight
                                    value={companyDetails.category}
                                    onChange={(e, newValue) => setCompanyDetails({...companyDetails, category: newValue!})}
                                    options={companyCategories}
                                    slotProps={{
                                        input: {
                                            autoComplete: 'new-password',
                                        },
                                    }}
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                        <CardActions sx={{alignSelf: 'flex-end', pt: 2 }}>
                        <Button size="sm"
                                variant="solid"
                                disabled={loading || companyDetails.shortName==="" || companyDetails.longName==="" || companyDetails.vatNumber==="" ||
                                    companyDetails.country==="" || companyDetails.country===null || companyDetails.city==="" || companyDetails.postalCode==="" || companyDetails.state==="" || companyDetails.address==="" ||
                                    Object.values(serverCompanyDetails ?? {}).sort().join(',')===Object.values(companyDetails).sort().join(',')}
                                onClick={() => updateCompanyDetails()}
                        >
                            Aggiorna dati
                        </Button>
                    </CardActions>
                </CardOverflow>
            </Card>

                <Card>
                    <Box sx={{ mb: 1 }}>
                        <Typography level="title-md">Logo</Typography>
                        <Typography level="body-sm">
                            Modifica il logo della tua azienda
                        </Typography>
                    </Box>
                    <Divider />
                    <Grid container spacing={2} sx={{ flexGrow: 1, p:1 }}>
                        <Grid xs={12} md={3}>
                            <FormControl>
                                <FormLabel>Logo attuale</FormLabel>
                                <img
                                    src={logo}
                                    alt="Logo"
                                    width="100"
                                    height="auto"
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Logo</FormLabel>
                                <input type="file"
                                       onChange={(e) => {
                                           if (e.target.files) {
                                               if(e.target.files[0].size > 2097152) {
                                                   alert("L'immagine pesa più di 2MB")
                                                   setNewLogo(null)
                                               } else {
                                                   setNewLogo(e.target.files ? e.target.files[0] : null)
                                               }
                                           }
                                       }}
                                       multiple={false}
                                       accept="image/*"
                                />
                                <FormHelperText>Massimo 2MB</FormHelperText>
                                <FormHelperText>Dimensoni minime: 250x250px</FormHelperText>
                                <FormHelperText>Dimensoni massime: 1000x1000px</FormHelperText>
                                <FormHelperText>Solo immagini quadrate</FormHelperText>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={3}>
                            {
                                newLogo ?
                                    <FormControl>
                                        <FormLabel>Nuovo logo</FormLabel>

                                        <img
                                            src={URL.createObjectURL(newLogo)}
                                            alt="Logo"
                                            onLoad={(e: React.SyntheticEvent<HTMLImageElement>) => {
                                                const {naturalWidth, naturalHeight} = e.currentTarget
                                                if (naturalWidth < 250 || naturalHeight < 250) {
                                                    setNewLogo(null)
                                                    alert("L'immagine ha dimensioni minori di 250px")
                                                } else if (naturalWidth > 1000 || naturalHeight > 1000) {
                                                    setNewLogo(null)
                                                    alert("L'immagine ha dimensioni maggiori di 1000px")
                                                } else if (naturalWidth !== naturalHeight) {
                                                    setNewLogo(null)
                                                    alert("L'immagine non è quadrata")
                                                }
                                            }}
                                            width="100"
                                            height="auto"
                                        />
                                    </FormControl>
                                    :
                                    null
                            }
                        </Grid>
                    </Grid>
                    <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                        <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                            <Button size="sm"
                                    variant="solid"
                                    disabled={newLogo === null || newLogo === undefined}
                                    onClick={() => uploadLogo(newLogo!)}
                            >
                                Carica logo
                            </Button>
                        </CardActions>
                    </CardOverflow>
                </Card>
            </Stack>
        </PageStandard>
    )

    async function updateCompanyDetails() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/updateCompanyInfo`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(companyDetails)
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Dati modificati con successo")
                window.location.reload()
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }

    async function uploadLogo(file: File) {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('filename', "logo");

        const response = await fetch(`${APIurl}/upload`, {
            method: 'POST',
            body: formData,
        });

        if (!response.ok) {
            setError('Si è verificato un errore nel caricamento del logo');
        } else {
            setMessage("Logo caricato con successo")
            setNewLogo(null)
            window.location.reload()
        }
    }
}