import {PageStandard} from "../../utils/PageStandard";
import {
    AspectRatio,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardOverflow,
    Chip,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Option,
    Select,
    Stack,
    Textarea,
    Typography
} from "@mui/joy";
import {Durations, ServiceModel} from "../../utils/Model";
import {APIurl, formatPriceEuro, genUUID, minutesToHoursString} from "../../utils/Utils";
import {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";
import {useSearchParams} from "react-router-dom";
import moment from "moment";

export function Servizio() {

    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const [searchParams, setSearchParams] = useSearchParams();
    const serviceId = searchParams.get("serviceId")
    if(!serviceId) window.location.replace("/servizi/servizi-offerti")

    const [service, setService] = useState<ServiceModel | null>(null)
    const [serviceServer, setServiceServer] = useState<ServiceModel | null>(null)

    useEffect(() => {
        fetchService().then(() => {})
    }, []);
    async function fetchService(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getService/${serviceId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setService(data)
                setServiceServer(data)
            }

        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    if(service === null) return null
    else return (
        <PageStandard title="Servizio" sidebarVisible={true}>
            <Stack
                direction={{xs:"column", md:"row"}}
                spacing={5}
                sx={{
                    display: 'flex',
                    mx: 'auto',
                    px: {xs: 2, md: 6},
                    py: {xs: 2, md: 3},
                }}
            >
                <Card>
                    <Box sx={{mb: 1}}>
                        <Typography level="title-md">Informazioni servizio</Typography>
                        <Typography level="body-sm">
                            Modifica i dati del servizio
                        </Typography>
                    </Box>
                    <Divider/>
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{my: 1}}
                    >
                        <Grid container spacing={2} sx={{flexGrow: 1}}>
                            <Grid xs={12} md={12}>
                                <FormControl>
                                    <FormLabel>Titolo *</FormLabel>
                                    <Input size="sm"
                                           type="text"
                                           placeholder="Il titolo del servizio"
                                           value={service.title}
                                           onChange={e => setService({...service, title: e.target.value})}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={12}>
                                <FormControl>
                                    <FormLabel>Descrizione</FormLabel>
                                    <Textarea size="sm"
                                              minRows={3}
                                              placeholder="Descrivi il nuovo servizio"
                                              value={service.description}
                                              onChange={e => setService({...service, description: e.target.value})}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <FormLabel>Durata *</FormLabel>
                                    <Select
                                        size="sm"
                                        placeholder="Scegli..."
                                        slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                                        value={service.duration}
                                        onChange={(e, newValue) => setService({...service, duration: newValue!})}
                                    >
                                        <Option value="" disabled>Scegli...</Option>
                                        {
                                            Durations.map(a => <Option value={a}
                                                                       key={a}>{minutesToHoursString(a)}</Option>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <FormLabel>Prezzo (€)</FormLabel>
                                    <Input size="sm"
                                           type="number"
                                           placeholder="10,00"
                                           slotProps={{
                                               input: {
                                                   min: 1,
                                                   step: 1,
                                               },
                                           }}
                                           value={service.price}
                                           onChange={e => setService({
                                               ...service,
                                               price: Number(e.target.value) !== 0 ? Number(e.target.value) : undefined
                                           })}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={12}>
                                <FormControl>
                                    <FormLabel>URL immagine</FormLabel>
                                    <Input size="sm"
                                           type="url"
                                           placeholder="https://www.example.com/image.jpg"
                                           value={service.imageUrl}
                                           onChange={e => setService({...service, imageUrl: e.target.value})}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Stack>
                    <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                        <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                            <Button size="sm"
                                    variant="solid"
                                    disabled={service.title === "" || loading}
                                    onClick={() => updateService()}
                            >
                                Aggiorna dati
                            </Button>
                        </CardActions>
                    </CardOverflow>
                </Card>
                <Stack spacing={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                    <Typography level="h3">Anteprima</Typography>
                    <CardServiceAnteprima service={service} />
                </Stack>
            </Stack>
        </PageStandard>
    )


    async function updateService() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/updateService/${serviceId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(service)
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Servizio aggiornato con successo")
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}

export function CardServiceAnteprima(props: {service: ServiceModel}) {
    const service = props.service

    return (
        <Card variant="outlined" sx={{ height:"100%", width:380 }}>
            <CardOverflow>
                <AspectRatio ratio="2" color="neutral" variant="soft">
                    {
                        service.imageUrl ?
                            <img
                                src={service.imageUrl}
                                loading="lazy"
                                alt={service.title}
                            />
                            :
                            null
                    }
                </AspectRatio>
            </CardOverflow>
            <CardContent>
                <Typography level="title-md">
                    {service.title}
                </Typography>
                <Typography level="body-sm">
                    {service.description}
                </Typography>
            </CardContent>
            <CardOverflow variant="soft">
                <Divider inset="context" />
                <CardContent orientation="horizontal" sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <Stack direction="row" spacing={1}>
                        <Chip variant="soft"
                              color="primary"
                        >
                            {minutesToHoursString(service.duration)}
                        </Chip>
                        {
                            service.price ?
                                <Chip variant="soft"
                                      color="success"
                                >
                                    {formatPriceEuro(service.price)}
                                </Chip>
                                :
                                null
                        }
                    </Stack>
                </CardContent>
            </CardOverflow>
        </Card>
    )
}