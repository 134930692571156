import {PageStandard} from "../utils/PageStandard";
import React, {useContext, useEffect, useState} from "react";
import {AuthContext} from "../utils/AuthProvider";
import {FeedbackContext} from "../utils/FeedbackContext";
import firebase from "firebase/compat/app";
import {APIurl} from "../utils/Utils";
import {UtilizzoStatsModel} from "../utils/Model";
import {
    Alert,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    CircularProgress,
    Grid,
    IconButton,
    Stack,
    Typography
} from "@mui/joy";
import {Info, Warning} from "@mui/icons-material";
import {LogoOctopus} from "../assets/Logo";
import moment from "moment";


export function Utilizzo() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const initialStats: UtilizzoStatsModel = {
        plan: {
            id: "",
            name: "",
            price: 0,
            maxUsers: 0,
            maxBookings: 0,
            maxServices: 0,
            turniAutomatici: false,
        },
        usage: {
            users: 0,
            bookings: 0,
            services: 0,
        },
        whatsapp: {
            totalMessages: 0,
            pricingPerMessage: 0,
            extimatedPrice: 0
        }
    };

    const [stats, setStats] = useState<UtilizzoStatsModel>(initialStats)

    useEffect(() => {
        fetchStats().then(() => {})
    }, []);
    async function fetchStats(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getUtilizzoStats`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setStats(data)
            }

        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    if(stats === initialStats || loading) return null
    return (
        <PageStandard title="Utilizzo" sidebarVisible={true} headerEnd={<Chip color="primary" variant="soft">Dal {moment().startOf("month").format("DD/MM/YYYY")} al {moment().endOf("month").format("DD/MM/YYYY")}</Chip>}>
            <Stack spacing={3} pb={5}>
                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        <Card sx={{textAlign:"center", height:"100%", width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", p:1}} variant="soft">
                            <CardContent sx={{textAlign:"center", height:"100%", width:"100%", display:"flex", flexDirection:"row", justifyContent:"space-between", alignItems:"center", p:1}}>
                                <Stack direction="column" spacing={1} justifyContent="start" alignItems="start" textAlign="start" sx={{width:'100%'}}>
                                    <Typography level="body-sm" mb={1}>Il tuo piano</Typography>
                                    <Box sx={{ display: 'flex', gap: 1, justifyContent:"center", alignItems: 'center' }}>
                                        <IconButton variant="soft" color="primary" size="sm">
                                            <LogoOctopus width="20" height="20" color="var(--joy-palette-primary-500)" />
                                        </IconButton>
                                        <Typography level="title-lg">{stats.plan.name}</Typography>
                                    </Box>
                                </Stack>
                                <Stack direction="column" spacing={1} justifyContent="start" alignItems="start" textAlign="start" sx={{width:'100%'}}>
                                    <Typography level="body-sm" mb={1}>Tariffa</Typography>
                                    <Typography level="title-lg">{stats.plan.price.toFixed(2)} € <Typography level="body-xs">/ mese</Typography></Typography>
                                </Stack>
                                <Stack direction="column" spacing={1} justifyContent="start" alignItems="start" textAlign="start" sx={{width:'100%'}}>
                                    <a href="mailto:supporto@octopusdesk.it">
                                        <Button>Richiedi upgrade o downgrade del piano</Button>
                                    </a>
                                </Stack>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid xs={12} md={6}>
                    </Grid>
                    <Grid xs={12} md={4}>
                        <CardStats label="Utenti" value={stats.usage.users} valueMax={stats.plan.maxUsers} />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <CardStats label="Servizi" value={stats.usage.services} valueMax={stats.plan.maxServices} />
                    </Grid>
                    <Grid xs={12} md={4}>
                        <CardStats label="Prenotazioni" value={stats.usage.bookings} valueMax={stats.plan.maxBookings} />
                    </Grid>
                </Grid>
                <Stack spacing={1}>
                    <Typography level="h3">Reminder Whatsapp</Typography>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={4}>
                            <CardStatsWhatsapp label="Messaggi inviati" value={stats.whatsapp.totalMessages} helper="nel mese corrente" />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <CardStatsWhatsapp label="Costo singolo messaggio" value={stats.whatsapp.pricingPerMessage.toFixed(5) + " €"} helper="basato sul numero di messaggi inviati" />
                        </Grid>
                        <Grid xs={12} md={4}>
                            <CardStatsWhatsapp label="Costo stimato" value={stats.whatsapp.extimatedPrice.toFixed(2) + " €"} />
                        </Grid>
                    </Grid>
                    <Typography level="body-xs">Il servizio WhatsApp non è incluso nell'abbonamento a Octopus Desk, consulta le <a href="https://www.octopusdesk.it/reminder-whatsapp" rel="noreferrer" target="_blank">tariffe</a>.</Typography>
                </Stack>

            </Stack>
        </PageStandard>
    )

    function CardStats(props: {label: string, value: number, valueMax: number}) {

        const fraction = (props.value / props.valueMax) * 100
        const isOver = fraction > 100
        const isFull = fraction === 100
        const percentage = isOver ? 100 : fraction

        return (
            <Card sx={{textAlign:"center", height:"100%", width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", p:1}} color={isOver ? "danger" : "neutral"}>
                <CardContent sx={{textAlign:"center", height:"100%", width:"100%", display:"flex", flexDirection:"column", justifyContent:"space-evenly", alignItems:"center", p:1}}>
                    <Typography level="title-md" mb={1} fontWeight="bolder">{props.label}</Typography>
                    <Stack direction="row" spacing={2} alignItems="center">
                        <CircularProgress size="lg" determinate value={percentage} color={isOver ? "danger" : "primary"}>
                            {percentage.toFixed(0)} %
                        </CircularProgress>
                        <Typography><b>{props.value}</b> / {props.valueMax}</Typography>
                    </Stack>
                    {
                        isOver ?
                            <Alert color="danger" variant="soft" startDecorator={<Warning />} sx={{mt:2}}>
                                È stato superato il limite di utilizzo
                            </Alert>
                            :
                            isFull &&
                                <Alert color="neutral" variant="soft" startDecorator={<Info />} sx={{mt:2}}>
                                    Hai raggiunto il limite massimo di utilizzo
                                </Alert>
                    }
                </CardContent>
            </Card>
        )
    }

    function CardStatsWhatsapp(props: {label: string, value: number | string, helper?: string}) {

        return (
            <Card sx={{textAlign:"center", height:"100%", width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", p:1}}>
                <CardContent sx={{textAlign:"center", height:"100%", width:"100%", display:"flex", flexDirection:"column", justifyContent:"space-evenly", alignItems:"center", p:1}}>
                    <Typography level="title-md" mb={1} fontWeight="bolder">{props.label}</Typography>
                    <Typography level="h3" mb={1} fontWeight="bolder">{props.value}</Typography>
                    {
                        props.helper &&
                        <Typography level="body-xs">{props.helper}</Typography>
                    }
                </CardContent>
            </Card>
        )
    }
}
