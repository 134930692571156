import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {ReactElement, useContext, useEffect, useState} from "react";
import {AuthContext, AUTHSTATES} from "./AuthProvider";
import "firebase/compat/firestore"
import {Login} from "../pages/Login";
import {Box, CircularProgress} from "@mui/joy";
import {Dashboard} from "../pages/Dashboard";
import {PAGES} from "./Pages";
import {Page404} from "../pages/Page404";
import {ServerSettingsModel} from "./Model";
import {APIurl} from "./Utils";
import {FeedbackContext} from "./FeedbackContext";
import {MainteinancePage} from "../pages/MainteinancePage";
import {SubscriptionInactive} from "../pages/SubscriptionInactive";
import {genPages} from "./Sidebar";
import {ConfigurazioneIniziale} from "../pages/ConfigurazioneIniziale";

export function Router() {

    const {bigLoading, setBigLoading, setError} = useContext(FeedbackContext)

    const {authState, userCompany, user} = useContext(AuthContext)

    const [serverSettings, setServerSettings] = useState<ServerSettingsModel | undefined>()
    useEffect(() => {
        fetchServerSettings().then(() => {})
    }, []);
    async function fetchServerSettings(): Promise<void> {
        setBigLoading(true)
        try {
            const response = await fetch(`${APIurl}/getServerSettings`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json"
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setServerSettings(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setBigLoading(false)
        }
    }

    return (
        <>
            {
                authState === AUTHSTATES.UNKNOWN || bigLoading ?
                    <Box sx={{display:"flex", justifyContent:"center", alignItems:"center", width:"100vw", height:"100vh"}}>
                        <CircularProgress />
                    </Box>
                    :
                    serverSettings?.mainteinance ?
                        <MainteinancePage />
                    :
                    userCompany && !userCompany?.subscriptionActive ?
                        <SubscriptionInactive />
                    :
                    userCompany && userCompany?.configuration ?
                        <ConfigurazioneIniziale />
                    :
                    <BrowserRouter>
                        <Routes>
                            <Route path="/login" element={authState === AUTHSTATES.LOGGEDIN ? <Navigate to="/" /> : <Login />} />
                            <Route path="/" element={authState === AUTHSTATES.LOGGEDOUT ? <Navigate to="/login"/> : <Dashboard />}/>
                            {
                                // public routes
                                generatePublicRoutes().map(p => p)
                            }
                            {
                                authState === AUTHSTATES.LOGGEDIN ?
                                    <>
                                        {
                                            // private routes
                                            generatePrivateRoutes().map(p => p)
                                        }
                                        <Route path="*" element={<Page404 />} />
                                    </>
                                    :
                                    <>
                                        <Route path="*" element={<Navigate to="/login" state={{ from: window.location.pathname }} />} />
                                    </>
                            }
                        </Routes>
                    </BrowserRouter>
            }
        </>
    )

    function generatePublicRoutes() {
        let routes : ReactElement[] = []
        PAGES
            .filter(p => p.routerVisible && !p.privateRoute)
            .map(p => {
                if(p.subpages) {
                    p.subpages.filter(p => p.routerVisible && !p.privateRoute)
                        .map(p2 => routes.push(<Route path={p.link + p2.link} element={p2.element} key={p.link + p2.link}/>))
                }

                routes.push(<Route path={p.link} element={p.element} key={p.link} />)
            })
        return routes
    }

    function generatePrivateRoutes() {
        let routes : ReactElement[] = []
        genPages(user.role, user.permissions)
            .filter(p => p.routerVisible && p.privateRoute)
            .map(p => {
                if(p.subpages) {
                    p.subpages.filter(p => p.routerVisible && p.privateRoute)
                        .map(p2 => routes.push(<Route path={p.link + p2.link} element={p2.element} key={p.link + p2.link}/>))
                }

                routes.push(<Route path={p.link} element={p.element} key={p.link} />)
            })
        return routes
    }
}