import {PageStandard} from "../../utils/PageStandard";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardOverflow,
    CircularProgress,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Input,
    Stack,
    Switch,
    Typography
} from "@mui/joy";
import {HolidayModel, ShiftModel, WorkingHoursModel} from "../../utils/Model";
import {APIurl, capitalize} from "../../utils/Utils";
import React, {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";
import moment from "moment/moment";
import {Add, Check, Close, Delete, Edit, Remove} from "@mui/icons-material";
import {useSearchParams} from "react-router-dom";

export function FerieDipendenti() {
    const {
        loading,
        bigLoading,
        error,
        message,
        setLoading,
        setBigLoading,
        setError,
        setMessage
    } = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get("userId")
    const userName = searchParams.get("userName")
    if (!userId) window.location.replace("/gestione-utenti/dipendenti")

    return (
        <PageStandard title={"Ferie dipendenti - " + userName} sidebarVisible={true}>
            <FerieDipendentiInner userId={userId} />
        </PageStandard>
    )
}

export function FerieDipendentiInner(props: {userId: string|null}) {

    const {
        loading,
        bigLoading,
        error,
        message,
        setLoading,
        setBigLoading,
        setError,
        setMessage
    } = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)


    useEffect(() => {
        fetchUser().then(() => {})
    }, []);
    async function fetchUser(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getUser/${props.userId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }


    const initialNewHoliday = {
        date: moment().format("YYYY-MM-DD"),
        start: moment().format("YYYY-MM-DD"),
        end: moment().add(1, "week").format("YYYY-MM-DD"),
    }
    const [userHolidays, setUserHolidays] = useState<HolidayModel[]>([])
    const [newHoliday, setNewHoliday] = useState<{start: string, end: string}>(initialNewHoliday)

    useEffect(() => {
        fetchUserHolidays().then(() => {})
    }, []);

    async function fetchUserHolidays(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getUserHolidays/` + props.userId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setUserHolidays(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    if (bigLoading) return <CircularProgress/>
    if (error) return <></>
    return (
        <Grid container direction="row" spacing={5} sx={{
            display: 'flex',
            px: {xs: 2, md: 6},
            py: {xs: 2, md: 3},
        }}>
            <Grid xs={12} md={6}>
                <Card sx={{flexGrow: 1}}>
                    <Box sx={{mb: 1}}>
                        <Stack direction="row" spacing={2} justifyContent="space-between">
                            <Stack>
                                <Typography level="title-md">Ferie future</Typography>
                                <Typography level="body-sm">
                                    Modifica o elimina le ferie future
                                </Typography>
                            </Stack>
                        </Stack>
                    </Box>
                    <Divider/>
                    <Stack spacing={2} sx={{flexGrow: 1, p: 1}}>
                        {
                            userHolidays.map((item) => (<CardUserHoliday key={item.id} id={item.id} uid={item.uid} start={item.start} end={item.end}/>))
                        }
                    </Stack>
                </Card>
            </Grid>
            <Grid xs={12} md={6}>
                <Card sx={{flexGrow:1}}>
                    <Box sx={{ mb: 1 }}>
                        <Typography level="title-md">Informazioni nuova ferie</Typography>
                        <Typography level="body-sm">
                            Compila i campi per creare un nuovo periodo di ferie
                        </Typography>
                    </Box>
                    <Divider />
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{ my: 1 }}
                    >
                        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <FormLabel>Data inizio *</FormLabel>
                                    <Input size="sm"
                                           type="date"
                                           value={newHoliday.start}
                                           onChange={e => setNewHoliday({...newHoliday, start: e.target.value})}
                                           error={!validateDatesHoliday(null, newHoliday.start, newHoliday.end, userHolidays)}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <FormLabel>Data fine *</FormLabel>
                                    <Input size="sm"
                                           type="date"
                                           value={newHoliday.end}
                                           onChange={e => setNewHoliday({...newHoliday, end: e.target.value})}
                                           error={!validateDatesHoliday(null, newHoliday.start, newHoliday.end, userHolidays)}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Stack>
                    <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                        <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                            <Button size="sm"
                                    variant="solid"
                                    disabled={newHoliday.start==="" || newHoliday.end==="" || loading || !validateDatesHoliday(null, newHoliday.start, newHoliday.end, userHolidays)}
                                    onClick={() => {
                                        checkBookingsHolidays({id: null, newHoliday: {start: `${newHoliday.start} 00:00:00`, end: `${newHoliday.end} 23:59:59`}}).then((res) => {
                                            if(res.code === 200) {
                                                addUserHoliday().then(() => {})
                                            } else if(res.code === 400) {
                                                window.alert(`ERRORE\nSono state trovate ${res.bookings} prenotazioni che rientrano nel periodo richiesto.\nEliminare o modificare le prenotazioni interessate e successivamente creare le ferie.`)
                                            } else if(res.code === 401) {
                                                window.alert(`ERRORE\nIl periodo richiesto si sovrappone con altre ferie.`)
                                            }
                                        })
                                    }}
                            >
                                Aggiungi
                            </Button>
                        </CardActions>
                    </CardOverflow>
                </Card>
            </Grid>
        </Grid>
    )

    async function addUserHoliday() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/addUserHoliday`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    uid: props.userId,
                    start: `${newHoliday.start} 00:00:00`,
                    end: `${newHoliday.end} 23:59:59`,
                }),
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Ferie creata con successo")
                window.location.reload()
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }

    function validateDatesHoliday(id: string | null, start: string, end: string, holidays: HolidayModel[]): boolean {
        // Verifica che start e end siano date valide
        if (!moment(start).isValid() || !moment(end).isValid()) {
            return false;
        }

        // Verifica che start sia minore di end
        if (moment(start).isAfter(moment(end))) {
            return false;
        }

        // Filtra le festività esistenti che non sono la festività corrente (se l'ID è fornito)
        const filteredHolidays = holidays.filter(a => a.id !== id);

        // Utilizza some per controllare se esistono sovrapposizioni di date
        const hasOverlap = filteredHolidays.some(a => {
            const holidayStart = moment(a.start);
            const holidayEnd = moment(a.end);
            const newStart = moment(start);
            const newEnd = moment(end);

            // Verifica sovrapposizioni di date
            return newStart.isBefore(holidayEnd) && newEnd.isAfter(holidayStart);
        });

        // Se esiste una sovrapposizione, ritorna false
        return !hasOverlap;
    }

    function CardUserHoliday(props: HolidayModel) {

        const {id, start, end} = props;

        const [startDate, setStartDate] = useState(moment(start).format("YYYY-MM-DD"))
        const [endDate, setEndDate] = useState(moment(end).format("YYYY-MM-DD"))
        const [editEnabled, setEditEnabled] = useState(false)

        return (
            <Card>
                <Grid container spacing={2} alignItems="center">
                    <Grid xs={5}>
                        <Input size="sm"
                               type="date"
                               value={startDate}
                               disabled={!editEnabled}
                               onChange={e => setStartDate(e.target.value)}
                               error={!validateDatesHoliday(id, startDate, endDate, userHolidays)}
                        />
                    </Grid>
                    <Grid xs={5}>
                        <Input size="sm"
                               type="date"
                               value={endDate}
                               disabled={!editEnabled}
                               onChange={e => setEndDate(e.target.value)}
                               error={!validateDatesHoliday(id, startDate, endDate, userHolidays)}
                        />
                    </Grid>
                    <Grid xs={2} textAlign="end">
                        {
                            editEnabled ?
                                <>
                                    <IconButton onClick={() => {
                                        setEditEnabled(false)
                                        setStartDate(moment(start).format("YYYY-MM-DD"))
                                        setEndDate(moment(end).format("YYYY-MM-DD"))
                                    }}>
                                        <Close/>
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        checkBookingsHolidays({id: id, newHoliday: {start: `${startDate} 00:00:00`, end: `${endDate} 23:59:59`}}).then((res) => {
                                            if(res.code === 200) {
                                                if(window.confirm("Modificare questa ferie?")) {
                                                    updateHoliday().then(() => {})
                                                }
                                            } else if(res.code === 400) {
                                                window.alert(`ERRORE\nSono state trovate ${res.bookings} prenotazioni che rientrano nel periodo richiesto.\nEliminare o modificare le prenotazioni interessate e successivamente modificare le ferie.`)
                                            } else if(res.code === 401) {
                                                window.alert(`ERRORE\nIl periodo richiesto si sovrappone con altre ferie.`)
                                            }
                                        })
                                    }}
                                        disabled={loading || !validateDatesHoliday(id, startDate, endDate, userHolidays)}
                                    >
                                        <Check/>
                                    </IconButton>
                                </>
                                :
                                <>
                                    <IconButton onClick={() => setEditEnabled(true)}>
                                        <Edit/>
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        if(window.confirm("Eliminare definitivamente questa ferie?")) {
                                            deleteHoliday().then(() => {})
                                        }
                                    }}>
                                        <Delete/>
                                    </IconButton>
                                </>
                        }
                    </Grid>
                </Grid>
            </Card>
        )

        async function updateHoliday() {
            setLoading(true)
            try {
                const user = firebase.auth().currentUser;
                if (!user) throw new Error("Utente non autenticato");
                const token = await user.getIdToken();

                const response = await fetch(`${APIurl}/updateUserHoliday/${id}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        start: `${startDate} 00:00:00`,
                        end: `${endDate} 23:59:59`,
                    }),
                })
                if (!response.ok) {
                    const error = await response.clone().json();
                    setError(error);
                } else {
                    setMessage("Ferie modificata con successo")
                    window.location.reload()
                }
            } catch (e: unknown) {
                setError(e)
            } finally {
                setLoading(false)
            }
        }

        async function deleteHoliday() {
            setLoading(true)
            try {
                const user2 = firebase.auth().currentUser
                if (!user2) throw new Error("Utente non autenticato")
                const token = await user2.getIdToken();

                const response = await fetch(`${APIurl}/deleteUserHoliday/${id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                })
                if (!response.ok) {
                    const error = await response.clone().json();
                    setError(error);
                } else {
                    setMessage("Ferie eliminata con successo")
                    window.location.reload()
                }
            } catch (e: unknown) {
                setError(e)
            } finally {
                setLoading(false)
            }
        }
    }

    async function checkBookingsHolidays(props: {id: string | null, newHoliday: {start: string, end: string}}) {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getBookingsHolidays`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    id: props.id,
                    uid: user.uid,
                    newHoliday: props.newHoliday
                }),
            })
            return response.json()
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}