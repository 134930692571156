import {PageStandard} from "../../utils/PageStandard";
import {
    Alert,
    Box,
    Button,
    Card,
    CardActions,
    CardOverflow,
    Chip,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Option,
    Select,
    Stack,
    Typography
} from "@mui/joy";
import {CanCreateModel, Roles, ServiceModel, UserModel, UtilizzoStatsModel} from "../../utils/Model";
import {APIurl, capitalize, isValidPhoneNumber, minutesToHoursString, TelefonoInput} from "../../utils/Utils";
import {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";
import {Warning} from "@mui/icons-material";

export function NuovoUtente() {

    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const initialData : UserModel = {
        birthdate: "",
        createdAt: "",
        email: "",
        id: "",
        name: "",
        permissions: [],
        phoneNumberPrefisso: "39",
        phoneNumber: "",
        role: Roles.USER,
        services: [],
        surname: "",
        workingHours: {"0": [], "1": [], "2": [], "3": [], "4": [], "5": [], "6": []}
    }
    const [data, setData] = useState<UserModel>(initialData)
    const [services, setServices] = useState<ServiceModel[]>([]);

    useEffect(() => {
        fetchCanCreate().then(() => {})
        fetchServices().then(() => {})
    }, []);

    async function fetchServices(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getServices`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setServices(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    const [canCreate, setCanCreate] = useState<CanCreateModel>({result: null})
    async function fetchCanCreate(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/canCreateUser`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setCanCreate(data)
            }

        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    return (
        <PageStandard title="Nuovo utente" sidebarVisible={true}>
            <Stack sx={{
                display: 'flex',
                maxWidth: '800px',
                mx: 'auto',
                px: { xs: 2, md: 6 },
                py: { xs: 2, md: 3 },
            }}>
                {
                    canCreate.result!==null && !canCreate.result &&
                        <Alert variant="soft" color="danger" startDecorator={<Warning />} sx={{mb:2}}>
                            <Stack direction="column">
                                <Typography level="title-md">Attenzione!</Typography>
                                <Typography level="body-sm">Hai raggiunto il numero massimo di utenti previsti nel tuo piano di abbonamento. Creando nuovi utenti, potresti ricevere un addebito aggiuntivo.</Typography>
                            </Stack>
                        </Alert>
                }

                <Card>
                <Box sx={{ mb: 1 }}>
                    <Typography level="title-md">Informazioni nuovo utente</Typography>
                    <Typography level="body-sm">
                        Compila i campi per creare il profilo del nuovo utente
                    </Typography>
                </Box>
                <Divider />
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{ my: 1 }}
                >
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Nome *</FormLabel>
                                <Input size="sm"
                                       type="text"
                                       placeholder="Mario"
                                       value={data.name}
                                       onChange={e => setData({...data, name: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Cognome *</FormLabel>
                                <Input size="sm"
                                       type="text"
                                       placeholder="Rossi"
                                       value={data.surname}
                                       onChange={e => setData({...data, surname: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <FormControl>
                                <FormLabel>Email *</FormLabel>
                                <Input size="sm"
                                       type="email"
                                       placeholder="nome@email.com"
                                       value={data.email}
                                       onChange={e => setData({...data, email: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <FormControl>
                                <FormLabel>Telefono</FormLabel>
                                <TelefonoInput valuePrefisso={data.phoneNumberPrefisso}
                                               valuePhone={data.phoneNumber}
                                               onChangePrefisso={e => setData({...data, phoneNumberPrefisso: e})}
                                               onChangePhone={e => setData({...data, phoneNumber: e})}
                                               error={!isValidPhoneNumber(data.phoneNumberPrefisso + " " + data.phoneNumber)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <FormControl>
                                <FormLabel>Data di nascita</FormLabel>
                                <Input size="sm"
                                       type="date"
                                       value={data.birthdate}
                                       onChange={e => setData({...data, birthdate: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Ruolo *</FormLabel>
                                <Select
                                    size="sm"
                                    placeholder="Scegli..."
                                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                                    value={data.role}
                                    onChange={(e, newValue) => setData({...data, role: newValue!})}
                                >
                                    <Option value="" disabled>Scegli...</Option>
                                    {
                                        Object.keys(Roles).map(a => <Option value={a.toLowerCase()} key={a}>{capitalize(a.toLowerCase())}</Option>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Permessi</FormLabel>
                                <Select
                                    size="sm"
                                    placeholder="Scegli..."
                                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                                    multiple
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                                            {selected.map((selectedOption) => (
                                                <div key={selectedOption.label!.toString()}>
                                                    <Chip variant="soft" color="primary">
                                                        {selectedOption.label}
                                                    </Chip>
                                                </div>
                                            ))}
                                        </Box>
                                    )}
                                    value={data.permissions}
                                    onChange={(e, newValue) => setData({...data, permissions: newValue!})}
                                >
                                    <Option value="" disabled>Scegli...</Option>
                                    {
                                        Object.keys(Roles).map(a => <Option value={a.toLowerCase()} key={a}>{capitalize(a.toLowerCase())}</Option>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={12}>
                            <FormControl>
                                <FormLabel>Servizi</FormLabel>
                                <Select
                                    size="sm"
                                    placeholder="Scegli..."
                                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                                    multiple
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                                            {selected.map((selectedOption) => (
                                                <div key={selectedOption.label!.toString()}>
                                                    <Chip variant="soft" color="primary">
                                                        {selectedOption.label}
                                                    </Chip>
                                                </div>
                                            ))}
                                        </Box>
                                    )}
                                    value={data.services}
                                    onChange={(e, newValue) => setData({...data, services: newValue!})}
                                >
                                    <Option value="" disabled>Scegli...</Option>
                                    {
                                        services.map(a => <Option value={a.id} key={a.id}>{a.title} - {minutesToHoursString(a.duration)}</Option>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Stack>
                <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                    <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                        <Button size="sm"
                                variant="outlined"
                                color="neutral"
                                onClick={() => {
                                    if(window.confirm("Cancellare tutti i campi?")) setData(initialData)
                                }}
                                disabled={data === initialData}
                        >
                            Cancella campi
                        </Button>
                        <Button size="sm"
                                variant="solid"
                                disabled={data.name==="" || data.surname==="" || data.email==="" || loading}
                                onClick={() => addUser()}
                        >
                            Aggiungi
                        </Button>
                    </CardActions>
                </CardOverflow>
            </Card>
            </Stack>
        </PageStandard>
    )


    async function addUser() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/addUser`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(data)
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Utente creato con successo")
                setData(initialData)
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}