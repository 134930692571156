import {PageStandard} from "../../utils/PageStandard";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardOverflow, Checkbox,
    Chip,
    Divider,
    FormControl,
    FormLabel,
    Grid, IconButton,
    Input,
    Option,
    Select,
    Stack,
    Typography
} from "@mui/joy";
import {ChiusuraAziendaleModel, CustomerModel, Roles, ServiceModel, UserModel} from "../../utils/Model";
import {APIurl, capitalize, minutesToHoursString} from "../../utils/Utils";
import {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import moment from "moment";
import {Delete} from "@mui/icons-material";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";

export function ChiusuraAziendale() {

    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const initialData : ChiusuraAziendaleModel = {
        id: "",
        start: "",
        end: "",
    }
    const [data, setData] = useState<ChiusuraAziendaleModel>(initialData)
    const [chiusure, setChiusure] = useState<ChiusuraAziendaleModel[]>([])
    const [chiusureData, setChiusureData] = useState<ChiusuraAziendaleModel[]>([])

    useEffect(() => {
        fetchChiusure().then(() => {})
    }, []);
    useEffect(() => {
        setChiusure(chiusureData.sort((a,b) => a.start < b.start ? 1 : -1))
    }, [chiusureData]);

    async function fetchChiusure(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getFutureChiusureAziendali`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setChiusureData(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    return (
        <PageStandard title="Chiusura aziendale" sidebarVisible={true}>
            <Stack direction={{xs:"column", md:"row"}} spacing={5} sx={{
                display: 'flex',
                px: { xs: 2, md: 6 },
                py: { xs: 2, md: 3 },
            }}>
                <Card sx={{flexGrow:1}}>
                    <Box sx={{ mb: 1 }}>
                        <Typography level="title-md">Chiusure aziendali future</Typography>
                        <Typography level="body-sm">
                            Monitora o elimina le chiusure aziendali future
                        </Typography>
                    </Box>
                    <Divider />
                    <Stack
                        direction="column"
                        spacing={3}
                        sx={{ my: 1 }}
                    >
                        {
                            chiusure.map(a => <RowChiusura chiusura={a} key={a.id} />)
                        }
                    </Stack>
                </Card>
                <Card sx={{flexGrow:1}}>
                    <Box sx={{ mb: 1 }}>
                        <Typography level="title-md">Informazioni nuova chiusura</Typography>
                        <Typography level="body-sm">
                            Compila i campi per creare una nuova chiusura aziendale
                        </Typography>
                    </Box>
                    <Divider />
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{ my: 1 }}
                    >
                        <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <FormLabel>Data inizio *</FormLabel>
                                    <Input size="sm"
                                           type="date"
                                           value={data.start}
                                           onChange={e => setData({...data, start: e.target.value})}
                                           error={data.end < data.start}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <FormLabel>Data fine *</FormLabel>
                                    <Input size="sm"
                                           type="date"
                                           value={data.end}
                                           onChange={e => setData({...data, end: e.target.value})}
                                           error={data.end < data.start}
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Stack>
                    <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                        <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                            <Button size="sm"
                                    variant="solid"
                                    disabled={data.start==="" || data.end==="" || data.end < data.start || loading}
                                    onClick={() => {
                                        checkBookingsChiusureAziendale({newHoliday: {start: moment(data.start, "YYYY-MM-DD").startOf("day").format("YYYY-MM-DD HH:mm:ss"), end: moment(data.end, "YYYY-MM-DD").endOf("day").format("YYYY-MM-DD HH:mm:ss")}}).then((res) => {
                                            if(res.code === 200) {
                                                addChiusura().then(() => {})
                                            } else if(res.code === 400) {
                                                window.alert(`ERRORE\nSono state trovate ${res.bookings} prenotazioni che rientrano nel periodo richiesto.\nEliminare o modificare le prenotazioni interessate e successivamente aggiungere la chiusura aziendale.`)
                                            } else if(res.code === 401) {
                                                window.alert(`ERRORE\nIl periodo richiesto si sovrappone con altre chiusure aziendali.`)
                                            }
                                        })
                                    }}
                            >
                                Aggiungi
                            </Button>
                        </CardActions>
                    </CardOverflow>
                </Card>
            </Stack>
        </PageStandard>
    )


    async function addChiusura() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/addChiusuraAziendale`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    start: moment(data.start, "YYYY-MM-DD").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
                    end: moment(data.end, "YYYY-MM-DD").endOf("day").format("YYYY-MM-DD HH:mm:ss"),
                })
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Chiusura aziendale creata con successo")
                setData(initialData)
                window.location.reload()
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }

    function RowChiusura(props: {chiusura: ChiusuraAziendaleModel}) {
        const chiusura = props.chiusura

        return (
            <Stack direction="row" spacing={4} sx={{ flexGrow: 1, alignItems:"center" }}>
                <Box>
                    <Typography level="body-sm">Data inizio</Typography>
                    <Typography fontWeight="bold">{moment(chiusura.start).format("DD/MM/YY")}</Typography>
                </Box>
                <Box>
                    <Typography level="body-sm">Data fine</Typography>
                    <Typography fontWeight="bold">{moment(chiusura.end).format("DD/MM/YY")}</Typography>
                </Box>
                <Box sx={{flexGrow:1, display:"flex", justifyContent:"end"}}>
                    <IconButton onClick={() => {
                        if(window.confirm("Eliminare definitivamente questa chiusura aziendale?")) deleteChiusura()
                    }}>
                        <Delete />
                    </IconButton>
                </Box>
            </Stack>
        )

        async function deleteChiusura() {
            setLoading(true)
            try {
                const user = firebase.auth().currentUser;
                if (!user) throw new Error("Utente non autenticato");
                const token = await user.getIdToken();

                const response = await fetch(`${APIurl}/deleteChiusuraAziendale/${chiusura.id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                })
                if (!response.ok) {
                    const error = await response.clone().json();
                    setError(error);
                } else {
                    setMessage("Chiusura eliminata con successo")
                    window.location.reload()
                }
            } catch (e: unknown) {
                setError(e)
            } finally {
                setLoading(false)
            }
        }
    }

    async function checkBookingsChiusureAziendale(props: {newHoliday: {start: string, end: string}}) {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getBookingsChiusureAziendali`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    newHoliday: props.newHoliday
                }),
            })
            return response.json()
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}