import {PageStandard} from "../../utils/PageStandard";
import {
    Alert,
    AspectRatio,
    Box,
    Button,
    Card,
    CardActions,
    CardContent,
    CardOverflow,
    Chip,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Option,
    Select,
    Stack,
    Textarea,
    Typography
} from "@mui/joy";
import {CanCreateModel, Durations, ServiceModel} from "../../utils/Model";
import {APIurl, formatPriceEuro, genUUID, minutesToHoursString} from "../../utils/Utils";
import {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";
import {Warning} from "@mui/icons-material";

export function NuovoServizio() {

    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const initialData: ServiceModel = {
        id: "",
        duration: 15,
        title: "",
        description: "",
        price: undefined,
        imageUrl: undefined,
    }
    const [data, setData] = useState<ServiceModel>(initialData)

    useEffect(() => {
        fetchCanCreate().then(() => {})
    }, []);
    const [canCreate, setCanCreate] = useState<CanCreateModel>({result: null})
    async function fetchCanCreate(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/canCreateService`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setCanCreate(data)
            }

        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    return (
        <PageStandard title="Nuovo servizio" sidebarVisible={true}>
            <Stack>
                {
                    canCreate.result!==null && !canCreate.result &&
                    <Alert variant="soft" color="danger" startDecorator={<Warning />} sx={{mb:2}}>
                        <Stack direction="column">
                            <Typography level="title-md">Attenzione!</Typography>
                            <Typography level="body-sm">Hai raggiunto il numero massimo di servizi previsti nel tuo piano di abbonamento. Creando nuovi servizi, potresti ricevere un addebito aggiuntivo.</Typography>
                        </Stack>
                    </Alert>
                }
                <Stack
                    direction={{xs:"column", md:"row"}}
                    spacing={5}
                    sx={{
                        display: 'flex',
                        mx: 'auto',
                        px: {xs: 2, md: 6},
                        py: {xs: 2, md: 3},
                    }}
                >

                    <Card>
                        <Box sx={{mb: 1}}>
                            <Typography level="title-md">Informazioni nuovo servizio</Typography>
                            <Typography level="body-sm">
                                Compila i campi per creare un nuovo servizio
                            </Typography>
                        </Box>
                        <Divider/>
                        <Stack
                            direction="row"
                            spacing={3}
                            sx={{my: 1}}
                        >
                            <Grid container spacing={2} sx={{flexGrow: 1}}>
                                <Grid xs={12} md={12}>
                                    <FormControl>
                                        <FormLabel>Titolo *</FormLabel>
                                        <Input size="sm"
                                               type="text"
                                               placeholder="Il titolo del servizio"
                                               value={data.title}
                                               onChange={e => setData({...data, title: e.target.value})}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={12}>
                                    <FormControl>
                                        <FormLabel>Descrizione</FormLabel>
                                        <Textarea size="sm"
                                                  minRows={3}
                                                  placeholder="Descrivi il nuovo servizio"
                                                  value={data.description}
                                                  onChange={e => setData({...data, description: e.target.value})}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel>Durata *</FormLabel>
                                        <Select
                                            size="sm"
                                            placeholder="Scegli..."
                                            slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                                            value={data.duration}
                                            onChange={(e, newValue) => setData({...data, duration: newValue!})}
                                        >
                                            <Option value="" disabled>Scegli...</Option>
                                            {
                                                Durations.map(a => <Option value={a}
                                                                           key={a}>{minutesToHoursString(a)}</Option>)
                                            }
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={6}>
                                    <FormControl>
                                        <FormLabel>Prezzo (€)</FormLabel>
                                        <Input size="sm"
                                               type="number"
                                               placeholder="10,00"
                                               slotProps={{
                                                   input: {
                                                       min: 1,
                                                       step: 1,
                                                   },
                                               }}
                                               value={data.price}
                                               onChange={e => setData({
                                                   ...data,
                                                   price: Number(e.target.value) !== 0 ? Number(e.target.value) : undefined
                                               })}
                                        />
                                    </FormControl>
                                </Grid>
                                <Grid xs={12} md={12}>
                                    <FormControl>
                                        <FormLabel>URL immagine</FormLabel>
                                        <Input size="sm"
                                               type="url"
                                               placeholder="https://www.example.com/image.jpg"
                                               value={data.imageUrl}
                                               onChange={e => setData({...data, imageUrl: e.target.value})}
                                        />
                                    </FormControl>
                                </Grid>
                            </Grid>
                        </Stack>
                        <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                            <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                                <Button size="sm"
                                        variant="outlined"
                                        color="neutral"
                                        onClick={() => {
                                            if (window.confirm("Cancellare tutti i campi?")) setData(initialData)
                                        }}
                                        disabled={data === initialData}
                                >
                                    Cancella campi
                                </Button>
                                <Button size="sm"
                                        variant="solid"
                                        disabled={data.title === "" || loading}
                                        onClick={() => addService()}
                                >
                                    Aggiungi
                                </Button>
                            </CardActions>
                        </CardOverflow>
                    </Card>
                    <Stack spacing={2} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
                        <Typography level="h3">Anteprima</Typography>
                        <CardServiceAnteprima service={data} />
                    </Stack>
                </Stack>
            </Stack>
        </PageStandard>
    )


    async function addService() {
        setLoading(true)
        const id = genUUID()
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/addService`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({...data, id: id})
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Servizio creato con successo")
                setData(initialData)
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}

export function CardServiceAnteprima(props: {service: ServiceModel}) {
    const service = props.service

    return (
        <Card variant="outlined" sx={{ height:"100%", width:380 }}>
            <CardOverflow>
                <AspectRatio ratio="2" color="neutral" variant="soft">
                    {
                        service.imageUrl ?
                            <img
                                src={service.imageUrl}
                                loading="lazy"
                                alt={service.title}
                            />
                            :
                            null
                    }
                </AspectRatio>
            </CardOverflow>
            <CardContent>
                <Typography level="title-md">
                    {service.title}
                </Typography>
                <Typography level="body-sm">
                    {service.description}
                </Typography>
            </CardContent>
            <CardOverflow variant="soft">
                <Divider inset="context" />
                <CardContent orientation="horizontal" sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                    <Stack direction="row" spacing={1}>
                        <Chip variant="soft"
                              color="primary"
                        >
                            {minutesToHoursString(service.duration)}
                        </Chip>
                        {
                            service.price ?
                                <Chip variant="soft"
                                      color="success"
                                >
                                    {formatPriceEuro(service.price)}
                                </Chip>
                                :
                                null
                        }
                    </Stack>
                </CardContent>
            </CardOverflow>
        </Card>
    )
}