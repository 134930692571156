import {PageStandard} from "../../utils/PageStandard";
import {useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {ServiceModel, UserModel} from "../../utils/Model";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardOverflow,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    FormLabel,
    Option,
    Select,
    Stack,
    Typography
} from "@mui/joy";
import {APIurl} from "../../utils/Utils";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";

export function AssegnaServizi() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get("userId")
    if(!userId) window.location.replace("/gestione-utenti/dipendenti")

    const [services, setServices] = useState<ServiceModel[]>([])
    const [user, setUser] = useState<UserModel | undefined>(undefined)
    const [defaultSelectedServices, setDefaultSelectedServices] = useState<string[]>([])
    const [selectedServices, setSelectedServices] = useState<string[]>([])

    useEffect(() => {
        fetchServices().then(() => {})
        fetchUser().then(() => {})
    }, [searchParams]);

    async function fetchServices(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getServices`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setServices(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }
    async function fetchUser(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getUser/` + userId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setDefaultSelectedServices(data.services)
                setSelectedServices(data.services)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    return (
        <PageStandard title="Assegna servizi" sidebarVisible={true}>
            {
                !loading && services ?
                    <Stack
                        direction="row"
                        spacing={5}
                    >
                        <Card sx={{flexGrow:1}}>
                            <Box sx={{mb: 1}}>
                                <Typography level="title-md">{user?.surname.toUpperCase()} {user?.name}</Typography>
                                <Typography level="body-sm">
                                    Scegli i servizi che può effettuare questo utente
                                </Typography>
                            </Box>
                            <Divider/>
                            <Stack
                                direction="row"
                                spacing={3}
                                sx={{my: 1, flexGrow:1}}
                            >
                                <FormControl sx={{flexGrow:1}}>
                                    <FormLabel>Servizi</FormLabel>
                                    <Select
                                        multiple
                                        size="sm"
                                        placeholder="Seleziona gli utenti da assegnare"
                                        value={selectedServices}
                                        onChange={(e, newValue) => setSelectedServices(newValue!)}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                                                {selected.map((selectedOption) => (
                                                    <Chip variant="soft" color="primary" key={selectedOption.id}>
                                                        {selectedOption.label}
                                                    </Chip>
                                                ))}
                                            </Box>
                                        )}
                                        sx={{
                                            minWidth: '15rem',
                                        }}
                                        slotProps={{
                                            listbox: {
                                                sx: {
                                                    width: '100%',
                                                },
                                            },
                                        }}
                                    >
                                        {
                                            services.map(a => <Option value={a.id} label={a.title} key={a.id}>
                                                <Stack direction="row" spacing={1} sx={{alignItems:"center"}}>
                                                    <Typography>{a.title}</Typography>
                                                </Stack>
                                            </Option>)
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                            <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                                <CardActions sx={{display:"flex", justifyContent: 'space-between', pt: 2}}>
                                    <Stack direction="row" spacing={2}>
                                        <Button size="sm"
                                                variant="outlined"
                                                color="neutral"
                                                onClick={() => {
                                                    setSelectedServices(services.map(a => a.id))
                                                }}
                                        >
                                            Seleziona tutti gli utenti
                                        </Button>
                                        <Button size="sm"
                                                variant="outlined"
                                                color="danger"
                                                onClick={() => {
                                                    setSelectedServices([])
                                                }}
                                        >
                                            Deseleziona tutti gli utenti
                                        </Button>
                                    </Stack>
                                    <Button size="sm"
                                            variant="solid"
                                            onClick={() => updateUserServices()}
                                            disabled={defaultSelectedServices.sort().join(',') === selectedServices.sort().join(',')}
                                    >
                                        Aggiorna
                                    </Button>
                                </CardActions>
                            </CardOverflow>
                        </Card>
                    </Stack>
                    :
                    <CircularProgress />
            }

        </PageStandard>
    )

    async function updateUserServices() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/updateUserServices`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({userId: userId, serviceIds: selectedServices})
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Utenti assegnati con successo")
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}