export const companyCategories = [
    "Agricoltura",
    "Alimentare e Bevande",
    "Architettura e Design",
    "Arti e Cultura",
    "Assicurazioni",
    "Automotive",
    "Bellezza e Cosmetica",
    "Biotecnologia",
    "Chimica",
    "Consulenza",
    "E-commerce",
    "Editoria e Stampa",
    "Educazione",
    "Energia",
    "Eventi e Conferenze",
    "Gestione dei Rifiuti",
    "Giochi e Giocattoli",
    "Hardware",
    "Immobiliare",
    "Ingegneria",
    "Intrattenimento e Media",
    "Legale",
    "Manifatturiero",
    "Marketing e Pubblicità",
    "Moda e Abbigliamento",
    "Non Profit e ONG",
    "Retail",
    "Ricerca e Sviluppo",
    "Risorse Umane",
    "Sanità",
    "Servizi Finanziari",
    "Servizi Pubblici",
    "Sicurezza",
    "Software e SaaS",
    "Sport e Fitness",
    "Tecnologia",
    "Telecomunicazioni",
    "Trasporti e Logistica",
    "Turismo e Ospitalità",
];