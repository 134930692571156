import {PageStandard} from "../../utils/PageStandard";
import {useSearchParams} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {ServiceModel, UserModel} from "../../utils/Model";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {
    Avatar,
    Box,
    Button,
    Card,
    CardActions,
    CardOverflow,
    Chip,
    CircularProgress,
    Divider,
    FormControl,
    FormLabel,
    Option,
    Select,
    Stack,
    Typography
} from "@mui/joy";
import {CardServiceAnteprima} from "./NuovoServizio";
import {APIurl, getInitials} from "../../utils/Utils";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";

export function AssegnaUtenti() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const [searchParams, setSearchParams] = useSearchParams();
    const serviceId = searchParams.get("serviceId")
    if(!serviceId) window.location.replace("/servizi/servizi-offerti")

    const [users, setUsers] = useState<UserModel[]>([])
    const [service, setService] = useState<ServiceModel | undefined>()
    const [defaultSelectedUsers, setDefaultSelectedUsers] = useState<string[]>([])
    const [selectedUsers, setSelectedUsers] = useState<string[]>([])

    useEffect(() => {
        async function fetchData() {
            setLoading(true)
            try {
                await fetchUsers()
                await fetchService()
            } catch (e: unknown) {
                setError(e)
            } finally {
                setLoading(false)
            }
        }

        if(serviceId) {
            fetchData()
        }
    }, [searchParams]);
    useEffect(() => {
        let resSelectedUsers : string[] = []
        users.map(a => {
            if(a.services.includes(serviceId!)) resSelectedUsers.push(a.id)
        })
        setDefaultSelectedUsers(resSelectedUsers)
        setSelectedUsers(resSelectedUsers)
    }, [users]);

    async function fetchUsers(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getUsers`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setUsers(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }
    async function fetchService(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getService/` + serviceId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setService(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    return (
        <PageStandard title="Assegna utenti" sidebarVisible={true}>
            {
                !loading && service ?
                    <Stack
                        direction={{xs:"column", md:"row"}}
                        justifyContent="center" alignItems="center"
                        spacing={5}
                    >
                        <Stack spacing={2}>
                            <CardServiceAnteprima service={service} />
                        </Stack>
                        <Card sx={{flexGrow:1}}>
                            <Box sx={{mb: 1}}>
                                <Typography level="title-md">Assegna utenti abilitati per il servizio</Typography>
                                <Typography level="body-sm">
                                    Scegli gli utenti che possono effettuare questo servizio
                                </Typography>
                            </Box>
                            <Divider/>
                            <Stack
                                direction="row"
                                spacing={3}
                                sx={{my: 1, flexGrow:1}}
                            >
                                <FormControl sx={{flexGrow:1}}>
                                    <FormLabel>Utenti</FormLabel>
                                    <Select
                                        multiple
                                        size="sm"
                                        placeholder="Seleziona gli utenti da assegnare"
                                        value={selectedUsers}
                                        onChange={(e, newValue) => setSelectedUsers(newValue!)}
                                        renderValue={(selected) => (
                                            <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                                                {selected.map((selectedOption) => (
                                                    <Chip variant="soft" color="primary" key={selectedOption.id}>
                                                        {selectedOption.label}
                                                    </Chip>
                                                ))}
                                            </Box>
                                        )}
                                        sx={{
                                            minWidth: '15rem',
                                        }}
                                        slotProps={{
                                            listbox: {
                                                sx: {
                                                    width: '100%',
                                                },
                                            },
                                        }}
                                    >
                                        {
                                            users.map(a => <Option value={a.id} label={a.surname.toUpperCase() + " " + a.name} key={a.id}>
                                                <Stack direction="row" spacing={1} sx={{alignItems:"center"}}>
                                                    <Avatar variant="solid" size="sm">{getInitials(a.surname.toUpperCase() + " " + a.name)}</Avatar>
                                                    <Typography>{a.surname.toUpperCase()} {a.name}</Typography>
                                                </Stack>
                                            </Option>)
                                        }
                                    </Select>
                                </FormControl>
                            </Stack>
                            <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                                <CardActions sx={{display:"flex", justifyContent: 'space-between', pt: 2}}>
                                    <Stack direction={{xs:"column", lg:"row"}} spacing={2}>
                                        <Button size="sm"
                                                variant="outlined"
                                                color="neutral"
                                                onClick={() => {
                                                    setSelectedUsers(users.map(a => a.id))
                                                }}
                                        >
                                            Seleziona tutti gli utenti
                                        </Button>
                                        <Button size="sm"
                                                variant="outlined"
                                                color="danger"
                                                onClick={() => {
                                                    setSelectedUsers([])
                                                }}
                                        >
                                            Deseleziona tutti gli utenti
                                        </Button>
                                    </Stack>
                                    <Button size="sm"
                                            variant="solid"
                                            onClick={() => updateUserServices()}
                                            disabled={defaultSelectedUsers.sort().join(',') === selectedUsers.sort().join(',')}
                                            sx={{height: "100%"}}
                                    >
                                        Aggiorna
                                    </Button>
                                </CardActions>
                            </CardOverflow>
                        </Card>
                    </Stack>
                    :
                    <CircularProgress />
            }

        </PageStandard>
    )

    async function updateUserServices() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/updateUsersServices`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({userIds: selectedUsers, serviceId: serviceId})
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Utenti assegnati con successo")
                window.location.reload()
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}