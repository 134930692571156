import {PageStandard} from "../../utils/PageStandard";
import {
    Alert,
    AspectRatio, Box,
    Button,
    Card,
    CardContent,
    CardOverflow, Checkbox,
    Chip, CircularProgress, DialogTitle,
    Divider, FormControl, FormLabel,
    Grid, IconButton, Input, Modal, ModalDialog, Sheet,
    Stack,
    Typography
} from "@mui/joy";
import {
    BookingModel,
    CanCreateModel,
    CustomerModel, Permissions,
    Roles,
    ServiceModel,
    SlotModel,
    UserModel
} from "../../utils/Model";
import {
    APIurl,
    areObjectsEqual,
    formatPriceEuro, isValidEmail,
    isValidPhoneNumber,
    minutesToHoursString,
    TelefonoInput
} from "../../utils/Utils";
import {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {Delete, Search, Warning} from "@mui/icons-material";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";
import moment from "moment";
import {Link, useSearchParams} from "react-router-dom";

export function ModificaPrenotazione() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany, user} = useContext(AuthContext)
    const enum TABS {
        SERVICES,
        EMPLOYEE,
        EVENT,
        CUSTOMER,
        OVERVIEW,
        RESPONSE,
    }
    const [tab, setTab] = useState(TABS.SERVICES)

    const [searchParams, setSearchParams] = useSearchParams();
    const bookingId = searchParams.get("id")
    if(!bookingId) window.location.replace("/prenotazioni/tutte-le-prenotazioni")
    useEffect(() => {
        fetchBooking().then(() => {})
    }, []);
    async function fetchBooking(): Promise<void> {
        setLoading(true)
        try {
            const user2 = firebase.auth().currentUser;
            if (!user2) throw new Error("Utente non autenticato");
            const token = await user2.getIdToken();

            const response = await fetch(`${APIurl}/getBooking/` + bookingId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                if((user.role===Roles.ADMIN || user.permissions.includes(Permissions.PRENOTAZIONI_ALTRI_UTENTI)) || data.booking.employeeId === firebase.auth().currentUser?.uid) {
                    setService(data.booking.serviceId)
                    setEmployee(data.booking.employeeId)
                    setCustomer(data.customer)
                    if(moment().isSameOrAfter(moment(data.booking.end).add(30, "minutes"))) window.location.replace("/prenotazioni/tutte-le-prenotazioni")
                } else {
                    setError("Non hai i permessi per modificare questa prenotazione")
                }
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    const customerInitialData = {
        name: "",
        surname: "",
        email: "",
        phoneNumberPrefisso: "39",
        phoneNumber: "",
        terms: false,
        marketing: true
    }

    const [services, setServices] = useState<ServiceModel[]>([])
    const [service, setService] = useState<string | null>(null)
    const [employees, setEmployees] = useState<UserModel[]>([])
    const [employee, setEmployee] = useState<string | null>(null)
    const [slots, setSlots] = useState<{ [day: string]: [SlotModel] }>({})
    const [slot, setSlot] = useState<Date | null>(null)
    const [customer, setCustomer] = useState(customerInitialData)
    const [response, setResponse] = useState<string | null>(null)

    async function fetchCustomers(props: {query: string}): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/searchCustomer?query=${props.query}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setCustomers(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }
    const [customers, setCustomers] = useState<CustomerModel[]>([])

    async function fetchServices(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getServices`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setServices(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }
    async function fetchEmployees(props: {serviceId: string}): Promise<void> {
        setLoading(true)
        try {
            const user2 = firebase.auth().currentUser;
            if (!user2) throw new Error("Utente non autenticato");
            const token = await user2.getIdToken();

            const response = await fetch(`${APIurl}/getUsersByService/${props.serviceId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                if((user.role===Roles.ADMIN || user.permissions.includes(Permissions.PRENOTAZIONI_ALTRI_UTENTI)) || data.booking.employeeId === firebase.auth().currentUser?.uid) {
                    setEmployees(data)
                } else {
                    setEmployees(data.filter((e: UserModel) => e.id===user2.uid))
                }
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }
    async function fetchSlots(props: {employeeId: string}): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const duration = services.find(s => s.id === service)?.duration
            const response = await fetch(`${APIurl}/getSlots/${props.employeeId}/${duration}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setSlots(data.slots)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        fetchServices().then(() => {})
    }, []);

    if (service === null || customer === null || employee === null || error) return null
    return (
        <PageStandard title="Modifica prenotazione" sidebarVisible={true}>
            <Stack sx={{
                display: 'flex',
                width: "100%",
                mx: 'auto',
                px: {xs: 2, md: 6},
                py: {xs: 2, md: 3},
            }}>

                {switchTabs()}

            </Stack>
        </PageStandard>
    )

    function switchTabs() {
        switch (tab) {
            case TABS.SERVICES:
                return <ServiceTab/>
            case TABS.EMPLOYEE:
                return <EmployeesTab/>
            case TABS.EVENT:
                return <EventTab/>
            case TABS.CUSTOMER:
                return <CustomerTab/>
            case TABS.OVERVIEW:
                return <OverviewTab/>
            case TABS.RESPONSE:
                return <ResponseTab/>
        }
    }

    function ServiceTab() {
        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Seleziona un servizio</Typography>
                <Grid container spacing={2}>
                    {
                        services.map(s => {
                            return <CardService service={s} key={s.id} />
                        })
                    }
                </Grid>
                <Stack sx={{width:'100%'}}>
                    <Stack direction="row" justifyContent="end">
                        <Button disabled={service === null}
                                onClick={() => fetchEmployees({serviceId: service!}).then(() => setTab(TABS.EMPLOYEE))}
                        >
                            Avanti
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        )
    }

    function EmployeesTab() {
        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Seleziona un utente</Typography>
                <Grid container spacing={2}>
                    {
                        employees.length!==0 ?
                            employees.map(e => {
                                return <Grid xs={12} key={e.id}><Card variant="outlined" sx={{ height:"100%", border: e.id===employee ? 2 : ""}} color={e.id===employee ? "primary" : "neutral"}  key={e.id} className="hoverScale cursorPointer" onClick={() => setEmployee(e.id)}>
                                    <CardContent>
                                        <Typography level="title-md">
                                            {e.surname} {e.name}
                                        </Typography>
                                    </CardContent>
                                </Card></Grid>
                            })
                            :
                            <Typography level="body-xs">Non ci sono dipendenti per questo servizio</Typography>
                    }
                </Grid>
                <Stack sx={{width:'100%'}}>
                    <Stack direction="row" justifyContent="space-between">
                        <Button onClick={() => {
                            setTab(TABS.SERVICES)
                            setEmployee(null)
                            setEmployees([])
                        }}
                                variant="outlined"
                                color="neutral"
                        >
                            Indietro
                        </Button>
                        <Button disabled={employee === null}
                                onClick={() => fetchSlots({employeeId: employee!}).then(() => setTab(TABS.EVENT))}
                        >
                            Avanti
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        )
    }

    function EventTab() {
        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Seleziona un orario</Typography>
                <Stack direction="row" spacing={2}>
                    {
                        Object.keys(slots).length!==0 ?
                            Object.keys(slots).map(day => <Stack direction="column" spacing={2} key={day}>
                                <Typography level="body-lg" fontWeight="bold" textAlign="center">
                                    {moment(day, "YYYY-MM-DD").format("ddd DD/MM")}
                                </Typography>
                                <Stack direction="column" spacing={1} sx={{maxHeight: {xs:"60vh", md:"50vh"}, pl: 1}}
                                       className="scrollbarOrari">
                                    {
                                        slots[day].map(s =>
                                            <Button variant={slot === s.start ? "solid" : "outlined"}
                                                    size="sm"
                                                    key={s.id}
                                                    onClick={() => {
                                                        if(s.employeeId.length === 1) {
                                                            setSlot(s.start)
                                                        }
                                                        else {
                                                            setSlot(s.start)
                                                            setEmployee(s.employeeId[Math.floor(Math.random() * s.employeeId.length)])
                                                        }
                                                    }}
                                            >
                                                {moment(s.start, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}
                                            </Button>
                                        )
                                    }
                                </Stack>
                            </Stack>)
                            :
                            <Typography level="body-xs">Non ci sono turni disponibili per questo dipendente</Typography>
                    }
                </Stack>
                <Stack sx={{width:'100%'}}>
                    <Stack direction="row" justifyContent="space-between">
                        <Button onClick={() => {
                            setTab(TABS.EMPLOYEE)
                            setSlot(null)
                            setSlots({})
                        }}
                                variant="outlined"
                                color="neutral"
                        >
                            Indietro
                        </Button>
                        <Button disabled={slot === null}
                                onClick={() => setTab(TABS.CUSTOMER)}
                        >
                            Avanti
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        )
    }

    function CustomerTab() {

        const [customer2, setCustomer2] = useState(customer)
        const [querySearch, setQuerySearch] = useState("")

        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Cliente</Typography>

                <Grid container spacing={2}>
                    <Grid xs={12} md={6}>
                        <FormControl size="sm">
                            <FormLabel>Nome *</FormLabel>
                            <Input type="text"
                                   placeholder="Mario"
                                   value={customer2.name}
                                   onChange={e => setCustomer2({...customer2, name: e.target.value})}
                                   error={customer2.name.trim() === ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <FormControl size="sm">
                            <FormLabel>Cognome *</FormLabel>
                            <Input type="text"
                                   placeholder="Rossi"
                                   value={customer2.surname}
                                   onChange={e => setCustomer2({...customer2, surname: e.target.value})}
                                   error={customer2.surname.trim() === ""}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <FormControl size="sm">
                            <FormLabel>Email *</FormLabel>
                            <Input type="email"
                                   placeholder="nome@mail.com"
                                   value={customer2.email}
                                   onChange={e => setCustomer2({...customer2, email: e.target.value})}
                                   error={!isValidEmail(customer2.email)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <FormControl size="sm">
                            <FormLabel>Telefono *</FormLabel>
                            <TelefonoInput valuePrefisso={customer2.phoneNumberPrefisso}
                                           valuePhone={customer2.phoneNumber}
                                           onChangePrefisso={e => setCustomer2({...customer2, phoneNumberPrefisso: e})}
                                           onChangePhone={e => setCustomer2({...customer2, phoneNumber: e})}
                                           error={!isValidPhoneNumber(customer2.phoneNumberPrefisso + " " + customer2.phoneNumber)}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <FormControl>
                            <Checkbox label="Acconsento al trattamento dei dati *"
                                      size="sm"
                                      checked={customer2.terms}
                                      onChange={() => setCustomer2({...customer2, terms: !customer2.terms})}
                                      color={!customer2.terms ? "danger" : "neutral"}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={6}>
                        <FormControl>
                            <Checkbox label="Voglio ricevere comunicazioni di carattere promozionale"
                                      size="sm"
                                      checked={customer2.marketing}
                                      onChange={() => setCustomer2({...customer2, marketing: !customer.marketing})}
                            />
                        </FormControl>
                    </Grid>
                    <Grid xs={12} md={12} textAlign="end">
                        <Stack direction="row" spacing={2} sx={{
                            width: "100%",
                            display: 'flex',
                            justifyContent: "space-between",
                            alignItems: "center"
                        }}>
                            <Button
                                variant="outlined"
                                color="neutral"
                                onClick={() => {
                                    setTab(TABS.EVENT)
                                }}
                            >
                                Indietro
                            </Button>
                            <Button disabled={
                                customer2.name.trim() === "" ||
                                customer2.surname.trim() === "" ||
                                !isValidEmail(customer2.email) ||
                                !isValidPhoneNumber(customer2.phoneNumberPrefisso + " " + customer2.phoneNumber) ||
                                !customer2.terms
                            }
                                    onClick={() => {
                                        setTab(TABS.OVERVIEW)
                                        setCustomer(customer2)
                                    }}
                            >
                                Avanti
                            </Button>
                        </Stack>
                    </Grid>
                    <Grid xs={12} mt={4}>
                        <Stack direction="column" spacing={2}>
                            <Typography level="h3">Ricerca cliente</Typography>
                            <FormControl>
                                <FormLabel>Nome, cognome, email, telefono o ID</FormLabel>
                                <Input type="text"
                                       placeholder="Nome, cognome, email, telefono o ID"
                                       value={querySearch}
                                       onChange={e => setQuerySearch(e.target.value)}
                                       onKeyDown={event => {
                                           if (event.key === "Enter" && !(querySearch.length < 3)) {
                                               document.getElementById("findCustomersButton")?.click();
                                           }
                                       }}
                                       endDecorator={
                                           <Button onClick={() => fetchCustomers({query: querySearch})} disabled={querySearch.length < 3} id="findCustomersButton">
                                               Cerca
                                           </Button>
                                       }
                                />
                            </FormControl>
                            <Stack direction="column" spacing={2}>
                                {
                                    customers.map(a => <Sheet variant="outlined"
                                                              color="primary"
                                                              key={a.id}
                                                              sx={{p:2, borderRadius:5, cursor:"pointer"}}
                                                              className="hoverScale"
                                                              onClick={() => {
                                                                  setCustomer2({
                                                                      name: a.name,
                                                                      surname: a.surname,
                                                                      email: a.email ?? "",
                                                                      phoneNumberPrefisso: a.phoneNumberPrefisso ?? "39",
                                                                      phoneNumber: a.phoneNumber ?? "",
                                                                      terms: a.terms,
                                                                      marketing: a.marketing
                                                                  })
                                                              }}
                                    >
                                        <Typography>{a.surname.toUpperCase()} {a.name}</Typography>
                                        <Typography>{a.email} +{a.phoneNumberPrefisso} {a.phoneNumber}</Typography>
                                    </Sheet>)
                                }
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Stack>
        )
    }

    function OverviewTab() {

        const sl = Object.values(slots).flat().find(s => s.start === slot);
        const empl = employees.find(e => e.id===employee)

        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Riepilogo</Typography>
                <Stack direction="column" spacing={2}>
                    <Card
                        size="lg"
                        variant="soft"
                        sx={{
                            borderRadius: 'xl',
                            minWidth: { xs:"auto", md:"350px" },
                        }}
                    >
                        <CardContent>
                            <Stack spacing={2}>
                                <Stack spacing={1}>
                                    <Typography level="h3">La prenotazione</Typography>
                                    <Stack>
                                        <Typography level="body-md">
                                            Data: <b>{moment(sl!.start, "YYYY-MM-DD HH:mm:ss").format("dddd DD/MM/YYYY")}</b>
                                        </Typography>
                                        <Typography level="body-md">
                                            Ora: <b>{moment(sl!.start, "YYYY-MM-DD HH:mm:ss").format("HH:mm")} - {moment(sl!.end, "YYYY-MM-DD HH:mm:ss").format("HH:mm")}</b>
                                        </Typography>
                                        <Typography level="body-md">
                                            Addetto: <b>{empl?.name} {empl?.surname}</b>
                                        </Typography>
                                    </Stack>
                                </Stack>
                                <Stack spacing={1}>
                                    <Typography level="h3">Il cliente</Typography>
                                    <Stack>
                                        <Typography level="body-md">Nome: <b>{customer.name}</b></Typography>
                                        <Typography level="body-md">Cognome: <b>{customer.surname}</b></Typography>
                                        <Typography level="body-md">Email: <b>{customer.email}</b></Typography>
                                        <Typography level="body-md">Telefono: <b>+{customer.phoneNumberPrefisso} {customer.phoneNumber}</b></Typography>
                                    </Stack>
                                </Stack>
                            </Stack>
                        </CardContent>
                    </Card>
                    <Stack direction="row" spacing={2} sx={{
                        width: "100%",
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center"
                    }}>
                        <Button
                            variant="outlined"
                            color="neutral"
                            fullWidth
                            onClick={() => {
                                setTab(TABS.CUSTOMER)
                            }}
                        >
                            Indietro
                        </Button>
                        <Button
                            variant="solid"
                            color="primary"
                            fullWidth
                            disabled={loading}
                            onClick={() => {
                                prenotaSlot()
                            }}
                        >
                            Modifica prenotazione
                        </Button>
                    </Stack>
                </Stack>
            </Stack>
        )
    }

    function ResponseTab() {

        return (
            <Stack direction="column" spacing={2} justifyContent="center" alignItems="center">
                <Typography level="h3">Prenotazione modificata</Typography>
                <Stack direction="row" spacing={2}>
                    <Link to={"/prenotazioni/prenotazione?id=" + response}>
                        <Button>
                            Gestisci la prenotazione
                        </Button>
                    </Link>
                </Stack>
            </Stack>
        )
    }

    async function prenotaSlot() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/addBooking`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    date: moment(slot!, "YYYY-MM-DD HH:mm:ss").format("YYYY-MM-DD"),
                    start: moment(slot!, "YYYY-MM-DD HH:mm:ss").format("HH:mm"),
                    serviceId: service!,
                    employeeId: employee,
                    customer: {
                        name: customer.name,
                        surname: customer.surname,
                        email: customer.email,
                        phoneNumberPrefisso: customer.phoneNumberPrefisso,
                        phoneNumber: customer.phoneNumber,
                        terms: customer.terms,
                        marketing: customer.marketing,
                    },
                    settings: {
                        reminderEmail: true,
                        reminderWhatsapp: true,
                    }
                })
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setResponse(data.id)
                setTab(TABS.RESPONSE)
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }

    function CardService(props: {service: ServiceModel}) {
        const ser = props.service

        return (
            <Grid xs={12} md={4}>
                <Card variant="outlined" sx={{ height:"100%", border: ser.id===service ? 2 : ""}} color={ser.id===service ? "primary" : "neutral"} className="hoverScale cursorPointer" onClick={() => setService(ser.id)}>
                    <CardOverflow>
                        <AspectRatio ratio="2" color="neutral" variant="soft">
                            {
                                ser.imageUrl ?
                                    <img
                                        src={ser.imageUrl}
                                        loading="lazy"
                                        alt={ser.title}
                                    />
                                    :
                                    null
                            }
                        </AspectRatio>
                    </CardOverflow>
                    <CardContent>
                        <Typography level="title-md">
                            {ser.title}
                        </Typography>
                        <Typography level="body-sm">
                            {ser.description}
                        </Typography>
                    </CardContent>
                    <CardOverflow variant="soft">
                        <Divider inset="context" />
                        <CardContent orientation="horizontal" sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                            <Stack direction="row" spacing={1}>
                                <Chip variant="soft"
                                      color="primary"
                                >
                                    {minutesToHoursString(ser.duration)}
                                </Chip>
                                {
                                    ser.price ?
                                        <Chip variant="soft"
                                              color="success"
                                        >
                                            {formatPriceEuro(ser.price)}
                                        </Chip>
                                        :
                                        null
                                }
                            </Stack>
                        </CardContent>
                    </CardOverflow>
                </Card>
            </Grid>
        )
    }
}