import React, {ReactNode, useContext, useState} from "react";
import {AuthContext} from "../utils/AuthProvider";
import {FeedbackContext} from "../utils/FeedbackContext";
import {
    Accordion, AccordionDetails,
    AccordionGroup, AccordionSummary,
    Box,
    Card,
    CardContent,
    Divider,
    Grid,
    IconButton,
    Stack,
    Typography
} from "@mui/joy";
import {Mail} from "@mui/icons-material";
import {LogoWhatsapp} from "../assets/LogoWhatsapp";
import {useLocation} from "react-router-dom";
import {FAQs} from "../utils/FAQs";
import {getPageTitleByUrl, getSupportoDescriptionByUrl, getSupportoFaqsByUrl} from "../utils/Pages";
import packageJson from '../../package.json';

export function Supporto() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const url = useLocation().pathname
    const path = url.split("/")
    const [index, setIndex] = useState<number | null>(0);

    return (
        <Box sx={{minHeight:"100%", display:"flex", flexDirection:"column", justifyContent:"space-between"}}>
            <Box>
                <Box p={3}>
                    <Typography level="h2" mb={2}>Supporto {getPageTitle(url)}</Typography>
                    <Typography level="body-sm">
                        {SwitchContentDescription(url)}
                    </Typography>
                </Box>
            </Box>
            <Box sx={{flexGrow: 1}}>
                <Divider />
                <Box p={3}>
                    <Typography level="h2" mb={2}>Domande frequenti</Typography>
                    <AccordionGroup>
                        {SwitchContentFaqs(url)}
                    </AccordionGroup>
                </Box>
            </Box>
            <Box>
                <Divider />
                <Box p={3}>
                    <Typography level="title-md">Non hai trovato quello che cercavi?</Typography>
                    <Typography level="body-sm" mb={2}>Contattaci subito. Proveremo a risolvere ogni tuo dubbio.</Typography>
                    <Grid container spacing={2}>
                        <Grid xs={6}>
                            <a href="https://wa.me/393295759200" rel="noreferrer" target="_blank">
                                <Card sx={{flexGrow: 1}} className="hoverScale">
                                    <CardContent>
                                        <Box sx={{display: 'flex', gap: 1, justifyContent: "start", alignItems: 'center'}}>
                                            <IconButton variant="soft" color="primary" size="md">
                                                <LogoWhatsapp width="20" height="20" color="var(--Icon-color)"/>
                                            </IconButton>
                                            <Stack>
                                                <Typography level="title-sm" fontWeight="bold">WhatsApp</Typography>
                                                <Typography level="title-sm">+39 329 575 9200</Typography>
                                            </Stack>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </a>
                        </Grid>
                        <Grid xs={6}>
                            <a href="mailto:supporto@octopusdesk.it" rel="noreferrer" target="_blank">
                                <Card sx={{flexGrow: 1}} className="hoverScale">
                                    <CardContent>
                                        <Box sx={{
                                            display: 'flex',
                                            gap: 1,
                                            justifyContent: "start",
                                            alignItems: 'center'
                                        }}>
                                            <IconButton variant="soft" color="primary" size="md">
                                                <Mail/>
                                            </IconButton>
                                            <Stack>
                                                <Typography level="title-sm" fontWeight="bold">Email</Typography>
                                                <Typography level="title-sm">supporto@octopusdesk.it</Typography>
                                            </Stack>
                                        </Box>
                                    </CardContent>
                                </Card>
                            </a>
                        </Grid>
                    </Grid>
                    <Typography level="body-xs" textAlign="center" mt={1} mb={-2} sx={{opacity:0.5}}>Octopus Desk &nbsp; v {packageJson.version}</Typography>
                </Box>
            </Box>
        </Box>
    )

    function SwitchContentDescription(url: string): string | ReactNode {

        const supportoDescription = getSupportoDescriptionByUrl(url)
        if (!supportoDescription || supportoDescription === "") {
            return <Typography>Ops.. non possiamo darti supporto per questa pagina. Prova a consultare le <i>Domande frequenti</i> o a contattare il nostro team.</Typography>
        } else {
            if (typeof supportoDescription === "string") return <Typography>{supportoDescription}</Typography>
            else return supportoDescription
        }
    }

    function SwitchContentFaqs(url: string): string | ReactNode {

        const supportoFaqs = getSupportoFaqsByUrl(url)
        if(supportoFaqs.length === 0) return <Typography level="body-sm">Ops.. non possiamo fornirti le domande frequenti per questa pagina. Vai alle <a href="https://www.octopusdesk.it/assistenza" rel="noreferrer" target="_blank"><i>Domande frequenti generali</i></a> o contatta il nostro team.</Typography>
        else return supportoFaqs.map((item, i) => <Accordion
            key={i}
            expanded={index === i}
            onChange={(event, expanded) => {
                setIndex(expanded ? i : null);
            }}
        >
            <AccordionSummary>{item.question}</AccordionSummary>
            <AccordionDetails>{item.answer}</AccordionDetails>
        </Accordion>)
    }

    function getPageTitle(url: string): string {
        const title = getPageTitleByUrl(url)
        if(title === null) return ""
        else return "- " + title
    }

}