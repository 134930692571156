import './App.css';
import firebase from "firebase/compat/app";
import {getAnalytics} from "firebase/analytics";
import {Box, CssBaseline, CssVarsProvider, extendTheme, useColorScheme} from "@mui/joy";
import {useEffect, useState} from "react";
import {AuthProvider} from "./utils/AuthProvider";
import {DarkMode, LightMode} from "@mui/icons-material";
import {Router} from "./utils/Router";
import {FeedbackProvider} from "./utils/FeedbackContext";

if (!firebase.apps.length) {
    const app = firebase.initializeApp({
        apiKey: "AIzaSyCpFCQ7n1vtPjTbzFsywbjJbyOqFLNtSwg",
        authDomain: "octopus-gestionale.firebaseapp.com",
        projectId: "octopus-gestionale",
        storageBucket: "octopus-gestionale.appspot.com",
        messagingSenderId: "328471403933",
        appId: "1:328471403933:web:ec7348d1f40b54fa012374",
        measurementId: "G-41TTP32S2S"
    })
    getAnalytics(app);
}

function App() {

    useEffect(() => {
        console.info('\x1b[34m%s\x1b[0m', // \x1b[38;5;33m
            "\n\n" +
            "   ___   ___ _____ ___  ___ _   _ ___ \n" +
            "  / _ \\ / __|_   _/ _ \\| _ \\ | | / __|\n" +
            " | (_) | (__  | || (_) |  _/ |_| \\__ \\\n" +
            "  \\___/ \\___| |_| \\___/|_|  \\___/|___/\n\n" +
            "         Developed with <3 by\n" +
            "        DANIELE DI GREGORIO .it\n" +
            "          www.octopusdesk.it\n")
    }, []);

    return (
        <div>
            <AuthProvider>
                <FeedbackProvider>
                    <CssVarsProvider defaultMode="light">
                        <CssBaseline />
                        <Box sx={{
                            minHeight: '100%',
                            maxWidth: '100vw',
                            backgroundColor: "var(--joy-palette-background-body)",
                        }}
                        >
                            {/*<ModeToggle/>*/}
                            <Router />
                        </Box>
                    </CssVarsProvider>
                </FeedbackProvider>
            </AuthProvider>
        </div>
    );
}

function ModeToggle() {
    const {mode, setMode} = useColorScheme()
    const [mounted, setMounted] = useState(false)

    useEffect(() => {
        setMounted(true)
    }, [])
    if(!mounted) return null

    return (
        <Box
            sx={{
                position: 'fixed',
                bottom: 0,
                right: 0,
                p: 2,
            }}
        >
            <div
                onClick={() => {
                    setMode(mode==='light' ? 'dark' : 'light')
                }}
            >
                {
                    mode==='light' ?
                        <DarkMode
                            sx={{cursor:'pointer'}}
                        />
                        :
                        <LightMode
                            sx={{
                                cursor:'pointer',
                                color:'white',
                            }}
                        />
                }
            </div>
        </Box>
    )
}

export default App;
