import {v4 as uuidv4} from 'uuid';
import {Divider, Input, Select, Option, Stack, Typography} from "@mui/joy";
import {useEffect, useState} from "react";
import {countryCodes} from "./Countries";
import moment from "moment";


export function capitalize(string: string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function minutesToHoursString(minutes: number) {
    if (minutes < 60) {
        return `${minutes} minuti`
    } else {
        const hours = Math.trunc(minutes / 60)
        const mins = (minutes % 60)
        const labelHours = hours === 1 ? "ora" : "ore"
        if (mins === 0) return `${hours} ${labelHours}`
        else return `${hours} ${labelHours} ${mins} minuti`
    }
}

export function formatPriceEuro(amount: number | string): string {
    if (typeof amount === 'string') {
        amount = amount.trim();
        const parsedAmount = parseFloat(amount);
        if (isNaN(parsedAmount)) {
            throw new Error('L\'input fornito non è un numero valido.');
        }
        amount = parsedAmount;
    }

    const formattedAmount = amount.toFixed(2);
    const amountWithComma = formattedAmount.replace('.', ',');
    return `€ ${amountWithComma}`;
}

export function genUUID(): string {
    return uuidv4()
}

export function getInitials(text: string) {
    let words: string[] = text.split(' ')
    let initials: string[] = words.map(word => word[0])
    return initials.join('').substring(0, 2).toUpperCase()
}

export function areObjectsEqual(obj1: Record<string, any>, obj2: Record<string, any>): boolean {
    const normalize = (obj: Record<string, any>) =>
        Object.keys(obj).sort().map(key => [key, obj[key]]);

    const obj1Entries = normalize(obj1);
    const obj2Entries = normalize(obj2);

    return JSON.stringify(obj1Entries) === JSON.stringify(obj2Entries);
}

interface TelefonoInputProps {
    size?: "sm" | "md" | "lg" | undefined,
    placeholder?: string,
    valuePrefisso: string,
    valuePhone: string,
    onChangePrefisso: (e: string) => void,
    onChangePhone: (e: string) => void,
    disabled?: boolean,
    error?: boolean,
}

export function TelefonoInput({size, placeholder, valuePrefisso, valuePhone, onChangePrefisso, onChangePhone, disabled, error}: TelefonoInputProps) {


    return (
        <Input
            size={size}
            placeholder={placeholder}
            value={valuePhone}
            onChange={(e) => onChangePhone(clearPhone(e.target.value))}
            startDecorator={
                <>
                    <Select
                        variant="plain"
                        value={valuePrefisso}
                        onChange={(_, value) => value && onChangePrefisso(value)}
                        sx={{ml: -1, '&:hover': {bgcolor: 'transparent'}}}
                        size={size}
                        renderValue={(selected) => (
                            <Typography level="body-sm">+{selected?.value}</Typography>
                        )}
                    >
                        {
                            countryCodes.sort((a,b) => a.dial_code>b.dial_code ? 1 : -1)
                                .map(a => <Option key={a.code} value={a.dial_code.replace("+", "")}>
                                    <Stack direction="row" spacing={1} alignContent="center">
                                        <Typography level="body-sm">{getFlagEmoji(a.code)}</Typography>
                                        <Typography level="body-sm">{a.dial_code}</Typography>
                                    </Stack>

                                </Option>)
                        }
                    </Select>
                    <Divider orientation="vertical" />
                </>
            }
            disabled={disabled}
            error={error}
        />
    )

    function clearPhone(input: string) : string {
        return input.replace(/\D/g, "");
    }

}

interface TimeInputProps {
    size?: 'sm' | 'md' | 'lg';
    value?: string;
    onChange?: (value: string) => void;
    error?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
}

export function TimeInput({ size = 'md', value = '', onChange, error, disabled, readOnly }: TimeInputProps) {
    const [time, setTime] = useState(value);

    const roundMinutes = (minutes: number) => {
        const validMinutes = [0, 15, 30, 45];
        return validMinutes.reduce((prev, curr) =>
            Math.abs(curr - minutes) < Math.abs(prev - minutes) ? curr : prev
        );
    };

    const handleBlur = () => {
        let [hours, minutes] = time.split(':').map(Number);

        // Arrotonda i minuti al valore più vicino
        minutes = roundMinutes(minutes);

        // Formatta di nuovo l'orario come stringa "HH:MM"
        const formattedTime = `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;

        setTime(formattedTime);
        if (onChange) {
            onChange(formattedTime);
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setTime(e.target.value);
    };

    /*
    useEffect(() => {
        handleBlur()
    }, []);
     */

    return (
        <Input
            size={size}
            type="time"
            value={time}
            onChange={handleChange} // Aggiorna lo stato man mano che l'utente inserisce il valore
            onBlur={handleBlur}      // Esegue il rounding dei minuti quando si perde il focus
            error={error}
            disabled={disabled}
            readOnly={readOnly}
        />
    );
}

export function getRoundedCurrentTime(): string {
    const now = moment();
    const currentMinutes = now.minutes();

    const validMinutes = [0, 15, 30, 45];
    const roundedMinutes = validMinutes.reduce((prev, curr) =>
        Math.abs(curr - currentMinutes) < Math.abs(prev - currentMinutes) ? curr : prev
    );

    // Imposta i minuti arrotondati
    now.minutes(roundedMinutes);
    now.seconds(0); // Imposta i secondi a 0 per una formattazione precisa

    // Restituisce la stringa formattata "HH:mm"
    return now.format("HH:mm");
}


const getFlagEmoji = (countryCode: string) => {
    const codePoints = countryCode
        .toUpperCase()
        .split('')
        .map((char) => 127397 + char.charCodeAt(0))
    return String.fromCodePoint(...codePoints)
}


export function isValidEmail(email: string): boolean {
    // Espressione regolare per validare un'email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
}

export function isValidPhoneNumber(phoneNumber: string): boolean {
    // Espressione regolare per validare il numero di telefono
    const phoneRegex = /^\d{1,4}\s\d{6,15}$/;
    return phoneRegex.test(phoneNumber);
}

export const APIurl = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'http://localhost:8800' : 'https://api.octopusdesk.it'