import {PageStandard} from "../utils/PageStandard";
import React, {ReactNode, useContext, useEffect, useState} from "react";
import {AuthContext} from "../utils/AuthProvider";
import {FeedbackContext} from "../utils/FeedbackContext";
import firebase from "firebase/compat/app";
import {APIurl} from "../utils/Utils";
import moment from "moment/moment";
import {StatsModel} from "../utils/Model";
import {Card, CardContent, CircularProgress, Grid, Stack, Typography} from "@mui/joy";
import {Bar, Doughnut} from "react-chartjs-2";
import {ArcElement, ChartData} from "chart.js";
import { Chart as ChartJS, Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale } from 'chart.js';

// Register necessary components for Chart.js
ChartJS.register(
    Title,
    Tooltip,
    Legend,
    BarElement,
    CategoryScale,
    LinearScale,
    ArcElement,
);

export function Statistiche() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const initialStats: StatsModel = {
        totalCustomers: 0,
        newCustomersThisMonth: 0,
        avgNewCustomersPerMonth: 0,
        customersPerMonth: {},
        marketingConsentPercentage: 0,
        marketingConsentCount: 0,

        totalBookings: 0,
        bookingsThisMonth: 0,
        avgBookingsPerMonth: 0,
        bookingsPerMonth: {},

        bookingsPerService: [],
        averageServicePrice: 0,
        totalEstimatedEarnings: 0,

        totalServices: 0,

        workHoursPerEmployee: {},
        holidaysPerEmployee: {},

        companyClosures: 0
    };

    const [stats, setStats] = useState<StatsModel>(initialStats)

    useEffect(() => {
        fetchStats().then(() => {})
    }, []);
    async function fetchStats(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getStats`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setStats(data)
            }

        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    const backgroundColors = [
        'rgba(54, 162, 235, 0.5)', // Blue
        'rgba(255, 99, 132, 0.5)', // Pink
        'rgba(255, 206, 86, 0.5)', // Yellow
        'rgba(75, 192, 192, 0.5)', // Green
        'rgba(153, 102, 255, 0.5)', // Purple
        'rgba(255, 159, 64, 0.5)', // Orange
        'rgba(201, 203, 207, 0.5)', // Grey
        'rgba(255, 99, 71, 0.5)', // Tomato
        'rgba(100, 149, 237, 0.5)', // CornflowerBlue
        'rgba(255, 105, 180, 0.5)' // HotPink
    ];
    const borderColors = [
        'rgba(54, 162, 235, 1)', // Blue
        'rgba(255, 99, 132, 1)', // Pink
        'rgba(255, 206, 86, 1)', // Yellow
        'rgba(75, 192, 192, 1)', // Green
        'rgba(153, 102, 255, 1)', // Purple
        'rgba(255, 159, 64, 1)', // Orange
        'rgba(201, 203, 207, 1)', // Grey
        'rgba(255, 99, 71, 1)', // Tomato
        'rgba(100, 149, 237, 1)', // CornflowerBlue
        'rgba(255, 105, 180, 1)' // HotPink
    ];

    if(stats === initialStats || loading) return null
    return (
        <PageStandard title="Statistiche" sidebarVisible={true}>
            <Stack spacing={3} pb={5}>

                {/* CLIENTI */}
                <Stack spacing={1}>
                    <Typography level="h3">Clienti</Typography>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={6}>
                            <Stack spacing={2} sx={{height: "100%"}}>
                                <CardStats label="Numero totale di clienti" value={stats.totalCustomers} />
                                <Stack spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                        <CardStats label="Nuovi clienti in questo mese" value={stats.newCustomersThisMonth} />
                                        <CardStats label="Media per mese nuovi clienti nell'ultimo anno" value={Number(stats.avgNewCustomersPerMonth).toFixed(2)} />
                                    </Stack>
                                    <Stack direction="row" spacing={2}>
                                        <CardStats label="Utenti con consenso marketing" value={Number(stats.marketingConsentPercentage).toFixed(2) + " %"} />
                                        <CardStats label="Utenti con consenso marketing" value={stats.marketingConsentCount} />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <CardStatsEmpty label="Nuovi clienti per mese" child={<>
                                <Bar data={convertDataset(stats.customersPerMonth, "Nuovi clienti per mese")} />
                            </>} />
                        </Grid>
                    </Grid>
                </Stack>

                {/* PRENOTAZIONI */}
                <Stack spacing={1}>
                    <Typography level="h3">Prenotazioni</Typography>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={6}>
                            <Stack spacing={2} sx={{height: "100%"}}>
                                <CardStats label="Numero totale di prenotazioni" value={stats?.totalBookings} />
                                <Stack spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                        <CardStats label="Prenotazioni in questo mese" value={stats?.bookingsThisMonth} />
                                        <CardStats label="Media prenotazioni per mese nell'ultimo anno" value={Number(stats.avgBookingsPerMonth).toFixed(2)} />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <CardStatsEmpty label="Prenotazioni per mese" child={<>
                                <Bar data={convertDataset(stats.bookingsPerMonth, "Prenotazioni per mese")} />
                            </>} />
                        </Grid>
                    </Grid>
                </Stack>

                {/* SERVIZI */}
                <Stack spacing={1}>
                    <Typography level="h3">Servizi</Typography>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={6}>
                            <Stack spacing={2} sx={{height: "100%"}}>
                                <CardStats label="Numero totale di servizi offerti" value={stats?.totalServices} />
                                <Stack spacing={2}>
                                    <Stack direction="row" spacing={2}>
                                        <CardStats label="Prezzo medio dei servizi" value={Number(stats.averageServicePrice).toFixed(2) + " €"} />
                                        <CardStats label="Guadagni totali stimati nell'ultimo anno" value={Number(stats.totalEstimatedEarnings).toFixed(2) + " €"} />
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid xs={12} md={6} sx={{maxHeight: "50vh"}}>
                            <CardStatsEmpty label="Prenotazioni per servizio" child={<>
                                <Doughnut data={convertDatasetBookingsPerService(stats.bookingsPerService)} />
                            </>} />
                        </Grid>
                    </Grid>
                </Stack>

                {/* ALTRO */}
                <Stack spacing={1}>
                    <Typography level="h3">Turni di lavoro, ferie e chiusure aziendali</Typography>
                    <Grid container spacing={2}>
                        <Grid xs={12} md={6}>
                            <CardStatsEmpty label="Ore di lavoro per dipendente al mese" child={<>
                                <Bar data={convertDatasetWorkingHours(stats.workHoursPerEmployee)} />
                            </>} />
                        </Grid>
                        <Grid xs={12} md={6}>
                            <CardStatsEmpty label="Giorni di ferie per dipendente al mese" child={<>
                                <Bar data={convertDatasetHolidays(stats.holidaysPerEmployee)} />
                            </>} />
                        </Grid>
                        <Grid xs={12} md={12}>
                            <CardStats label="Giorni di chiusura aziendale" value={stats?.companyClosures ?? 0} />
                        </Grid>
                    </Grid>
                </Stack>

            </Stack>
        </PageStandard>
    )

    interface CustomerBookingData {
        [month: string]: number;
    }
    function convertDataset(data: CustomerBookingData, label: string): ChartData<'bar'> {
        // Extract the labels and values from the data
        const labels = Object.keys(data);
        const values = Object.values(data);

        // Return the data in the format expected by react-chartjs-2
        return {
            labels,
            datasets: [
                {
                    label: label,
                    data: values,
                    backgroundColor: 'rgba(75, 192, 192, 0.2)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                },
            ],
        };
    }
    interface WorkHoursData {
        [employeeId: string]: { [month: string]: number };
    }
    function convertDatasetWorkingHours(data: WorkHoursData): ChartData<'bar'> {
        // Collect all unique months and employee IDs
        const months = new Set<string>();
        const employeeIds = new Set<string>();

        Object.keys(data).forEach(employeeId => {
            Object.keys(data[employeeId]).forEach(month => {
                months.add(month);
                employeeIds.add(employeeId);
            });
        });

        // Convert sets to arrays
        const labels = Array.from(months).sort(); // Ensure months are sorted correctly
        const employeeIdsArray = Array.from(employeeIds);

        // Create datasets for each employee
        const datasets = employeeIdsArray.map((employeeId, index) => {
            return {
                label: employeeId,
                data: labels.map(month => data[employeeId][month] || 0), // Default to 0 if month data is missing
                backgroundColor: backgroundColors[index % backgroundColors.length], // Cycle through background colors
                borderColor: borderColors[index % borderColors.length], // Cycle through border colors
                borderWidth: 1,
            };
        });

        return {
            labels: labels,
            datasets: datasets,
        };
    }
    interface HolidaysData {
        [employeeId: string]: number;
    }
    function convertDatasetHolidays(data: HolidaysData): ChartData<'bar'> {
        // Extract employee IDs and their holiday counts
        const employeeIds = Object.keys(data);
        const labels = employeeIds;
        const dataset = {
            label: 'Ferie per dipendente',
            data: employeeIds.map(employeeId => data[employeeId]),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
        };

        return {
            labels: labels,
            datasets: [dataset],
        };
    }
    interface BookingsPerServiceData {
        bookings_count: number;
        percentage: number;
        service_id: string;
        service_name: string;
    }
    function convertDatasetBookingsPerService(data: BookingsPerServiceData[]): ChartData<'doughnut'> {
        const labels = data.map(item => item.service_name);
        const values = data.map(item => item.bookings_count);

        return {
            labels: labels,
            datasets: [{
                data: values,
                backgroundColor: backgroundColors,
                borderColor: borderColors,
                borderWidth: 1
            }]
        };
    }

    function CardStats(props: {label: string, value: number | string | undefined}) {
        return (
            <Card sx={{textAlign:"center", height:"100%", width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", p:2}}>
                <CardContent sx={{textAlign:"center", height:"100%", width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", p:2}}>
                    <Typography level="title-sm">{props.label}</Typography>
                    <Typography level="h2" fontWeight="bold">{props.value}</Typography>
                </CardContent>
            </Card>
        )
    }
    function CardStatsEmpty(props: {label: string, child: ReactNode}) {
        return (
            <Card sx={{textAlign:"center", height:"100%", width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", p:2}}>
                <CardContent sx={{textAlign:"center", height:"100%", width:"100%", display:"flex", flexDirection:"column", justifyContent:"center", alignItems:"center", p:2}}>
                    <Typography level="title-sm">{props.label}</Typography>
                    {props.child}
                </CardContent>
            </Card>
        )
    }

}