import {PageStandard} from "../../utils/PageStandard";
import {
    Alert,
    Autocomplete,
    Box,
    Button,
    Card,
    CardActions,
    CardOverflow, CircularProgress,
    Divider,
    FormControl, FormHelperText,
    FormLabel,
    Grid,
    Input, Option, Select,
    Stack, Switch, Tooltip,
    Typography
} from "@mui/joy";
import {CompanyDetailsModel} from "../../utils/Model";
import {APIurl, areObjectsEqual, minutesToHoursString} from "../../utils/Utils";
import React, {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {countries} from "../../utils/Countries";
import {companyCategories} from "../../utils/CompanyCategories";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";
import {Info, InfoOutlined} from "@mui/icons-material";
import moment from "moment";
import {Link} from "react-router-dom";

export function ImpostazioniGestionale() {

    const {loading, bigLoading, error, message, setLoading, setBigLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const gestionaleSettingsInitial = {
        prenotazioniAttive: true,
        reminderEmail: true,
        emailConfirmation: true,
        emailEdit: false,
        emailDelete: false,
        emailReminderOneDayBefore: false,
        emailReminderSameDay: true,
        reminderWhatsapp: false,
        whatsappConfirmation: false,
        whatsappEdit: false,
        whatsappDelete: false,
        whatsappReminderOneDayBefore: false,
        whatsappReminderSameDay: false,
        customerEditBooking: true,
        customerEditBookingDelay: 1,
        customerDeleteBooking: true,
        customerDeleteBookingDelay: 1,
        customerMinDelay: 1,
        customerMaxDelay: 14,
        turniAutomatici: true,
        turniDelay: 3,
        slotMinimo: 30
    }
    const [gestionaleSettings, setGestionaleSettings] = useState(gestionaleSettingsInitial)
    const [serverGestionaleSettings, setServerGestionaleSettings] = useState(gestionaleSettingsInitial)

    useEffect(() => {
        fetchGestionaleSettings().then(() => {})
    }, []);

    async function fetchGestionaleSettings(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getGestionaleSettings`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setGestionaleSettings(data)
                setServerGestionaleSettings(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    if(bigLoading) return <CircularProgress />
    return (
        <PageStandard title="Impostazioni gestionale" sidebarVisible={true}>
            <Stack spacing={3} sx={{
                display: 'flex',
                maxWidth: '1000px',
                mx: 'auto',
                px: { xs: 2, md: 6 },
                py: { xs: 2, md: 3 },
            }}>
                <Card sx={{maxWidth: '500px'}}>
                    <Box sx={{mb: 1}}>
                        <Typography level="title-md">Impostazioni gestionale</Typography>
                        <Typography level="body-sm">
                            Modifica le impostazioni del gestionale per adattarlo alle tue esigenze
                        </Typography>
                        <Typography level="body-sm">
                            Se preferisci, utilizza la <Link to="/configurazione-guidata">configurazione guidata</Link>.
                        </Typography>
                    </Box>
                    <Divider/>
                    <FormControl sx={{p: 1}}>
                        <FormLabel>
                            <Stack direction="row">
                                <Switch
                                    checked={gestionaleSettings.prenotazioniAttive}
                                    onChange={(event) => setGestionaleSettings({
                                        ...gestionaleSettings,
                                        prenotazioniAttive: event.target.checked
                                    })}
                                    sx={{mr: 2}}
                                />
                                <Typography level="title-sm">Prenotazioni attive</Typography>
                            </Stack>
                        </FormLabel>
                        <FormHelperText>Se disabiliti questa opzione, i clienti non potranno prenotare
                            autonomamente.</FormHelperText>
                    </FormControl>
                    <Divider/>
                    <div>
                        <FormControl sx={{p: 1}}>
                            <FormLabel>
                                <Stack direction="row">
                                    <Switch
                                        checked={gestionaleSettings.reminderEmail}
                                        onChange={(event) => setGestionaleSettings({
                                            ...gestionaleSettings,
                                            reminderEmail: event.target.checked
                                        })}
                                        sx={{mr: 2}}
                                    />
                                    <Typography level="title-sm">Reminder via email</Typography>
                                </Stack>
                            </FormLabel>
                            <FormHelperText>I reminder via email notificano al cliente tutti gli stati della
                                prenotazione e ricordano l'appuntamento al cliente.</FormHelperText>
                            <Typography level="body-sm">Il servizio di notifica via email è sempre incluso
                                nell'abbonamento.</Typography>
                        </FormControl>
                        {
                            gestionaleSettings.reminderEmail &&
                            <>
                                <FormControl sx={{p: 1}}>
                                    <FormLabel>
                                        <Stack direction="row">
                                            <Switch
                                                checked={gestionaleSettings.emailConfirmation}
                                                onChange={(event) => setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    emailConfirmation: event.target.checked
                                                })}
                                                sx={{mr: 2}}
                                            />
                                            <Typography level="title-sm">Email di conferma prenotazione</Typography>
                                        </Stack>
                                    </FormLabel>
                                    <FormHelperText>La mail di conferma viene inviata appena l'utente crea una
                                        prenotazione.</FormHelperText>
                                </FormControl>
                                <FormControl sx={{p: 1}}>
                                    <FormLabel>
                                        <Stack direction="row">
                                            <Switch
                                                checked={gestionaleSettings.emailEdit}
                                                onChange={(event) => setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    emailEdit: event.target.checked
                                                })}
                                                sx={{mr: 2}}
                                            />
                                            <Typography level="title-sm">Messaggio di modifica prenotazione</Typography>
                                        </Stack>
                                    </FormLabel>
                                    <FormHelperText>La mail di modifica viene inviata quando la prenotazione viene
                                        modificata.</FormHelperText>
                                </FormControl>
                                <FormControl sx={{p: 1}}>
                                    <FormLabel>
                                        <Stack direction="row">
                                            <Switch
                                                checked={gestionaleSettings.emailDelete}
                                                onChange={(event) => setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    emailDelete: event.target.checked
                                                })}
                                                sx={{mr: 2}}
                                            />
                                            <Typography level="title-sm">Messaggio di annullamento prenotazione</Typography>
                                        </Stack>
                                    </FormLabel>
                                    <FormHelperText>La mail di annullamento viene inviata quando la prenotazione viene
                                        annullata.</FormHelperText>
                                </FormControl>
                                <FormControl sx={{p: 1}}>
                                    <FormLabel>
                                        <Stack direction="row">
                                            <Switch
                                                checked={gestionaleSettings.emailReminderOneDayBefore}
                                                onChange={(event) => setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    emailReminderOneDayBefore: event.target.checked
                                                })}
                                                sx={{mr: 2}}
                                            />
                                            <Typography level="title-sm">Reminder prenotazione giorno prima dell'appuntamento</Typography>
                                        </Stack>
                                    </FormLabel>
                                    <FormHelperText>Questa mail viene inviata il giorno prima
                                        dell'appuntamento.</FormHelperText>
                                </FormControl>
                                <FormControl sx={{p: 1}}>
                                    <FormLabel>
                                        <Stack direction="row">
                                            <Switch
                                                checked={gestionaleSettings.emailReminderSameDay}
                                                onChange={(event) => setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    emailReminderSameDay: event.target.checked
                                                })}
                                                sx={{mr: 2}}
                                            />
                                            <Typography level="title-sm">Reminder prenotazione giorno stesso dell'appuntamento</Typography>
                                        </Stack>
                                    </FormLabel>
                                    <FormHelperText>Questa mail viene inviata il giorno stesso
                                        dell'appuntamento.</FormHelperText>
                                </FormControl>
                            </>
                        }
                    </div>

                    <Divider/>

                    <div>
                        <FormControl sx={{p: 1}}>
                            <FormLabel>
                                <Stack direction="row">
                                    <Switch
                                        checked={gestionaleSettings.reminderWhatsapp}
                                        onChange={(event) => setGestionaleSettings({
                                            ...gestionaleSettings,
                                            reminderWhatsapp: event.target.checked
                                        })}
                                        sx={{mr: 2}}
                                    />
                                    <Typography level="title-sm">Reminder Whatsapp</Typography>
                                </Stack>
                            </FormLabel>
                            <FormHelperText>I reminder WhatsApp notificano al cliente tutti gli stati della prenotazione
                                e ricordano l'appuntamento al cliente.</FormHelperText>
                            <Alert variant="soft" color="warning" size="sm" sx={{mt: 1}}>
                                <Typography level="body-xs">Il servizio WhatsApp non è incluso nell'abbonamento,
                                    consulta il <a href="https://www.octopusdesk.it/reminder-whatsapp" target="_blank"
                                                   rel="noreferrer">tariffario</a>.</Typography>
                            </Alert>
                        </FormControl>
                        {
                            gestionaleSettings.reminderWhatsapp &&
                            <>
                                <FormControl sx={{p: 1}}>
                                    <FormLabel>
                                        <Stack direction="row">
                                            <Switch
                                                checked={gestionaleSettings.whatsappConfirmation}
                                                onChange={(event) => setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    whatsappConfirmation: event.target.checked
                                                })}
                                                sx={{mr: 2}}
                                            />
                                            <Typography level="title-sm">Messaggio di conferma prenotazione</Typography>
                                        </Stack>
                                    </FormLabel>
                                    <FormHelperText>Il messaggio di conferma viene inviato appena l'utente crea una
                                        prenotazione.</FormHelperText>
                                </FormControl>
                                <FormControl sx={{p: 1}}>
                                    <FormLabel>
                                        <Stack direction="row">
                                            <Switch
                                                checked={gestionaleSettings.whatsappEdit}
                                                onChange={(event) => setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    whatsappEdit: event.target.checked
                                                })}
                                                sx={{mr: 2}}
                                            />
                                            <Typography level="title-sm">Messaggio di modifica prenotazione</Typography>
                                        </Stack>
                                    </FormLabel>
                                    <FormHelperText>Il messaggio di modifica viene inviato quando la prenotazione viene
                                        modificata.</FormHelperText>
                                </FormControl>
                                <FormControl sx={{p: 1}}>
                                    <FormLabel>
                                        <Stack direction="row">
                                            <Switch
                                                checked={gestionaleSettings.whatsappDelete}
                                                onChange={(event) => setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    whatsappDelete: event.target.checked
                                                })}
                                                sx={{mr: 2}}
                                            />
                                            <Typography level="title-sm">Messaggio di annullamento prenotazione</Typography>
                                        </Stack>
                                    </FormLabel>
                                    <FormHelperText>Il messaggio di annullamento viene inviato quando la prenotazione
                                        viene annullata.</FormHelperText>
                                </FormControl>
                                <FormControl sx={{p: 1}}>
                                    <FormLabel>
                                        <Stack direction="row">
                                            <Switch
                                                checked={gestionaleSettings.whatsappReminderOneDayBefore}
                                                onChange={(event) => setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    whatsappReminderOneDayBefore: event.target.checked
                                                })}
                                                sx={{mr: 2}}
                                            />
                                            <Typography level="title-sm">Reminder prenotazione giorno prima dell'appuntamento</Typography>
                                        </Stack>
                                    </FormLabel>
                                    <FormHelperText>Questo messaggio viene inviato il giorno prima
                                        dell'appuntamento.</FormHelperText>
                                </FormControl>
                                <FormControl sx={{p: 1}}>
                                    <FormLabel>
                                        <Stack direction="row">
                                            <Switch
                                                checked={gestionaleSettings.whatsappReminderSameDay}
                                                onChange={(event) => setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    whatsappReminderSameDay: event.target.checked
                                                })}
                                                sx={{mr: 2}}
                                            />
                                            <Typography level="title-sm">Reminder prenotazione giorno stesso dell'appuntamento</Typography>
                                        </Stack>
                                    </FormLabel>
                                    <FormHelperText>Questo messaggio viene inviato il giorno stesso
                                        dell'appuntamento.</FormHelperText>
                                </FormControl>
                            </>
                        }
                    </div>

                    <Divider/>

                    <div>
                        <FormControl sx={{p: 1}}>
                            <FormLabel>Slot minimo</FormLabel>
                            <Select
                                size="sm"
                                placeholder="Scegli..."
                                slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                                value={gestionaleSettings.slotMinimo}
                                onChange={(e, newValue) => setGestionaleSettings({
                                    ...gestionaleSettings,
                                    slotMinimo: Number(newValue!)
                                })}
                            >
                                <Option value="" disabled>Scegli...</Option>
                                {
                                    Array.from({length: 12}, (_, i) => (i + 1)*15)
                                        .map(a => <Option value={a}
                                                              key={a}>{minutesToHoursString(a)}</Option>)
                                }
                            </Select>
                            <FormHelperText>Indica l'intervallo di tempo minimo per la scelta dello slot di prenotazione.</FormHelperText>
                        </FormControl>
                    </div>

                        <Divider/>

                        <div>
                            <FormControl sx={{p: 1}}>
                                <FormLabel>
                                    <Stack direction="row">
                                        <Switch
                                            checked={gestionaleSettings.customerEditBooking}
                                            onChange={(event) => setGestionaleSettings({
                                                ...gestionaleSettings,
                                                customerEditBooking: event.target.checked
                                            })}
                                            sx={{mr: 2}}
                                        />
                                        <Typography level="title-sm">Modifica prenotazione</Typography>
                                    </Stack>
                                </FormLabel>
                                <FormHelperText>Consenti ai clienti di modificare la prenotazione.</FormHelperText>
                            </FormControl>
                            {
                                gestionaleSettings.customerEditBooking &&
                                <FormControl sx={{p: 1}}>
                                    <FormLabel>Delay modifica prenotazione (ore)</FormLabel>
                                    <Input size="sm"
                                           type="number"
                                           value={gestionaleSettings.customerEditBookingDelay}
                                           onChange={e => setGestionaleSettings({
                                               ...gestionaleSettings,
                                               customerEditBookingDelay: Number(e.target.value)
                                           })}
                                           endDecorator={<Typography>ore</Typography>}
                                           slotProps={{
                                               input: {
                                                   min: 1,
                                                   max: 48,
                                               }
                                           }}
                                           error={gestionaleSettings.customerEditBookingDelay < 1 || gestionaleSettings.customerEditBookingDelay > 48}
                                    />
                                    <FormHelperText>Indica fino a quante ore prima il cliente può modificare la
                                        prenotazione</FormHelperText>
                                </FormControl>
                            }
                            <FormControl sx={{p: 1}}>
                                <FormLabel>
                                    <Stack direction="row">
                                        <Switch
                                            checked={gestionaleSettings.customerDeleteBooking}
                                            onChange={(event) => setGestionaleSettings({
                                                ...gestionaleSettings,
                                                customerDeleteBooking: event.target.checked
                                            })}
                                            sx={{mr: 2}}
                                        />
                                        <Typography level="title-sm">Annullamento prenotazione</Typography>
                                    </Stack>
                                </FormLabel>
                                <FormHelperText>Consenti ai clienti di annullare la prenotazione.</FormHelperText>
                            </FormControl>
                            {
                                gestionaleSettings.customerDeleteBooking &&
                                <FormControl sx={{p: 1}}>
                                    <FormLabel>Delay annullamento prenotazione (ore)</FormLabel>
                                    <Input size="sm"
                                           type="number"
                                           value={gestionaleSettings.customerDeleteBookingDelay}
                                           onChange={e => setGestionaleSettings({
                                               ...gestionaleSettings,
                                               customerDeleteBookingDelay: Number(e.target.value)
                                           })}
                                           endDecorator={<Typography>ore</Typography>}
                                           slotProps={{
                                               input: {
                                                   min: 1,
                                                   max: 48,
                                               }
                                           }}
                                           error={gestionaleSettings.customerDeleteBookingDelay < 1 || gestionaleSettings.customerDeleteBookingDelay > 48}
                                    />
                                    <FormHelperText>Indica fino a quante ore prima il cliente può annullare la
                                        prenotazione</FormHelperText>
                                </FormControl>
                            }

                            <FormControl sx={{p: 1}}>
                                <FormLabel>Delay minimo prenotazione slot da parte dei clienti (giorni)</FormLabel>
                                <Input size="sm"
                                       type="number"
                                       value={gestionaleSettings.customerMinDelay}
                                       onChange={e => setGestionaleSettings({
                                           ...gestionaleSettings,
                                           customerMinDelay: Number(e.target.value)
                                       })}
                                       endDecorator={<Typography>giorni</Typography>}
                                       slotProps={{
                                           input: {
                                               min: 1,
                                           }
                                       }}
                                       error={gestionaleSettings.customerMinDelay < 1}
                                />
                                <FormHelperText>Indica tra quanti giorni viene presentato il primo appuntamento
                                    disponibile al cliente.</FormHelperText>
                                <FormHelperText>Esempio: con questa configurazione è possibile prenotare a partire
                                    dal {moment().add(gestionaleSettings.customerMinDelay, "days").format("DD/MM")}.</FormHelperText>
                            </FormControl>
                            <FormControl sx={{p: 1}}>
                                <FormLabel>Delay massimo prenotazione slot da parte dei clienti (giorni)</FormLabel>
                                <Input size="sm"
                                       type="number"
                                       value={gestionaleSettings.customerMaxDelay}
                                       onChange={e => setGestionaleSettings({
                                           ...gestionaleSettings,
                                           customerMaxDelay: Number(e.target.value)
                                       })}
                                       endDecorator={<Typography>giorni</Typography>}
                                       slotProps={{
                                           input: {
                                               min: 2,
                                           }
                                       }}
                                       error={gestionaleSettings.customerMaxDelay < 2}
                                />
                                <FormHelperText>Indica tra quanti giorni viene presentato l'ultimo appuntamento
                                    disponibile al cliente.</FormHelperText>
                                <FormHelperText>Esempio: con questa configurazione è possibile prenotare fino
                                    al {moment().add(gestionaleSettings.customerMaxDelay, "days").format("DD/MM")}.</FormHelperText>
                            </FormControl>
                        </div>

                        {userCompany.plan.turniAutomatici && <Divider/>}
                        {
                            userCompany.plan.turniAutomatici &&
                            <div>
                                <FormControl sx={{p: 1}}>
                                    <FormLabel>
                                        <Stack direction="row">
                                            <Switch
                                                checked={gestionaleSettings.turniAutomatici}
                                                onChange={(event) => setGestionaleSettings({
                                                    ...gestionaleSettings,
                                                    turniAutomatici: event.target.checked
                                                })}
                                                sx={{mr: 2}}
                                            />
                                            <Typography level="title-sm">Turni automatici</Typography>
                                        </Stack>
                                    </FormLabel>
                                    <FormHelperText>I turni vengono generati automaticamente sulla base dei turni di
                                        default
                                        dei singoli dipendenti.</FormHelperText>
                                    <FormHelperText>Se l'opzione è attiva, i turni vengono generati automaticamente
                                        nella
                                        notte tra la domenica e il lunedì.</FormHelperText>
                                </FormControl>
                                {
                                    gestionaleSettings.turniAutomatici &&
                                    <FormControl sx={{p: 1}}>
                                        <FormLabel>Delay generazione turni automatici (settimane)</FormLabel>
                                        <Input size="sm"
                                               type="number"
                                               value={gestionaleSettings.turniDelay}
                                               onChange={e => setGestionaleSettings({
                                                   ...gestionaleSettings,
                                                   turniDelay: Number(e.target.value)
                                               })}
                                               endDecorator={<Typography>settimane</Typography>}
                                               slotProps={{
                                                   input: {
                                                       min: 1,
                                                       max: 10,
                                                   }
                                               }}
                                               error={gestionaleSettings.turniDelay < 1 || gestionaleSettings.turniDelay > 10}
                                        />
                                        <FormHelperText>Indica tra quante settimane verranno generati i
                                            turni.</FormHelperText>
                                        <FormHelperText>Esempio: con questa configurazione
                                            lunedì {moment().add(1, "week").startOf("week").format("DD/MM")} a
                                            mezzanotte,
                                            verranno generati i turni della settimana
                                            dal {moment().add(gestionaleSettings.turniDelay + 1, "week").startOf("week").format("DD/MM")} al {moment().add(gestionaleSettings.turniDelay + 1, "week").endOf("week").format("DD/MM")}.</FormHelperText>
                                    </FormControl>
                                }
                            </div>
                        }

                        <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                            <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                                <Button size="sm"
                                        variant="solid"
                                        disabled={loading || areObjectsEqual(serverGestionaleSettings, gestionaleSettings)}
                                        onClick={() => updateGestionaleSettings()}
                                >
                                    Aggiorna dati
                                </Button>
                            </CardActions>
                        </CardOverflow>
                </Card>
            </Stack>
        </PageStandard>
)

async function updateGestionaleSettings() {
    setLoading(true)
    try {
        const user = firebase.auth().currentUser;
        if (!user) throw new Error("Utente non autenticato");
        const token = await user.getIdToken();

        const response = await fetch(`${APIurl}/updateGestionaleSettings`, {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": `Bearer ${token}`
            },
            body: JSON.stringify({newSettings: gestionaleSettings})
        })
        if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Dati modificati con successo")
                window.location.reload()
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}