import {PageStandard} from "../../utils/PageStandard";
import {
    Box,
    Card,
    CardContent,
    CircularProgress,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Stack,
    Typography
} from "@mui/joy";
import {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {AuthContext} from "../../utils/AuthProvider";
import {BookingFormattedModel, Permissions, Roles} from "../../utils/Model";
import firebase from "firebase/compat/app";
import {APIurl} from "../../utils/Utils";
import {CardPrenotazione} from "./Agenda";

export function CercaPrenotazione() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany, user} = useContext(AuthContext)

    const [booking, setBooking] = useState<null | string>(null)
    const [date, setDate] = useState<null | string>(null)
    const [customer, setCustomer] = useState<null | string>(null)
    const [employee, setEmployee] = useState<null | string>(null)
    const [service, setService] = useState<null | string>(null)
    const [bookings, setBookings] = useState<BookingFormattedModel[]>([])

    useEffect(() => {
        if(booking || date || customer || employee || service) {
            fetchBookingsResearch().then(() => {})
        }
    }, [booking, date, customer, employee, service]);
    async function fetchBookingsResearch(): Promise<void> {
        setLoading(true)
        try {
            const user2 = firebase.auth().currentUser;
            if (!user2) throw new Error("Utente non autenticato");
            const token = await user2.getIdToken();

            const emp = (user.role===Roles.ADMIN || user.permissions.includes(Permissions.PRENOTAZIONI_ALTRI_UTENTI)) ? employee : user2.uid
            const response = await fetch(`${APIurl}/getBookingsResearch/${booking}/${date}/${customer}/${emp}/${service}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setBookings(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    return (
        <PageStandard title="Cerca prenotazione" sidebarVisible={true}>
            <Grid container spacing={2} sx={{width:'100%'}}>
                <Grid xs={12} md={4}>
                    <Card>
                        <Typography level="title-lg">Ricerca rapida</Typography>
                        <Divider />
                        <CardContent>
                            <Stack direction="column" spacing={2}>
                                <FormControl size="sm">
                                    <FormLabel>ID prenotazione</FormLabel>
                                    <Input type="text"
                                           value={booking ?? ""}
                                           onChange={e => setBooking(e.target.value === "" ? null : e.target.value)}
                                           placeholder="XXXXXXXXXX"
                                    />
                                </FormControl>
                                <FormControl size="sm">
                                    <FormLabel>Data</FormLabel>
                                    <Input type="date"
                                           value={date ?? ""}
                                           onChange={e => setDate(e.target.value === "" ? null : e.target.value)}
                                    />
                                </FormControl>
                                <FormControl size="sm">
                                    <FormLabel>Cliente</FormLabel>
                                    <Input type="text"
                                           value={customer ?? ""}
                                           onChange={e => setCustomer(e.target.value === "" ? null : e.target.value)}
                                           placeholder="Nome, cognome, email o telefono"
                                    />
                                </FormControl>
                                {
                                    (user.role===Roles.ADMIN || user.permissions.includes(Permissions.PRENOTAZIONI_ALTRI_UTENTI)) &&
                                    <FormControl size="sm">
                                        <FormLabel>Dipendente</FormLabel>
                                        <Input type="text"
                                               value={employee ?? ""}
                                               onChange={e => setEmployee(e.target.value === "" ? null : e.target.value)}
                                               placeholder="Nome, cognome, email o telefono"
                                        />
                                    </FormControl>
                                }
                                <FormControl size="sm">
                                    <FormLabel>Servizio</FormLabel>
                                    <Input type="text"
                                           value={service ?? ""}
                                           onChange={e => setService(e.target.value === "" ? null : e.target.value)}
                                           placeholder="Titolo"
                                    />
                                </FormControl>
                            </Stack>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid xs={12} md={8}>
                    <Stack direction="column" spacing={2}>
                        {
                            loading ?
                                <Stack justifyContent="center" alignItems="center"><CircularProgress /></Stack>
                                :
                                bookings.length !== 0 ?
                                    bookings.map((booking, index) => <CardPrenotazione key={booking.id} booking={booking} dateVisible={true} />)
                                    :
                                    <Typography level="body-sm" textAlign="center" pt={4}>Non è stata trovata nessuna prenotazione</Typography>
                        }
                    </Stack>
                </Grid>
            </Grid>
        </PageStandard>
    )
}