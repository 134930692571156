import {PageStandard} from "../../utils/PageStandard";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardOverflow,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    Input,
    Stack,
    Switch,
    Typography
} from "@mui/joy";
import {WorkingHoursModel} from "../../utils/Model";
import {APIurl, capitalize, TimeInput} from "../../utils/Utils";
import React, {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {Add, Remove} from "@mui/icons-material";
import moment from "moment";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";

export function OrariDiLavoroSettimanali() {

    const {loading, bigLoading, error, message, setLoading, setBigLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const workingHoursInitial: WorkingHoursModel = {
        "0": [],
        "1": [],
        "2": [],
        "3": [],
        "4": [],
        "5": [],
        "6": [],
    }
    const [serverWorkingHours, setServerWorkingHours] = useState<WorkingHoursModel | undefined>()
    const [workingHours, setWorkingHours] = useState<WorkingHoursModel>(workingHoursInitial)

    useEffect(() => {
        fetchWorkingHours().then(() => {})
    }, []);

    async function fetchWorkingHours(): Promise<void> {
        setBigLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getWorkingHours`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setServerWorkingHours(data)
                setWorkingHours(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setBigLoading(false)
        }
    }

    const setDayValues = (day: string) => (newValues: { start: string, end: string }[]) => {
        setWorkingHours(prev => ({ ...prev, [day]: newValues }));
    };

    if(bigLoading) return <CircularProgress />
    return (
        <PageStandard title="Orari di lavoro settimanali" sidebarVisible={true}>
            <Stack sx={{
                display: 'flex',
                maxWidth: {xs:"auto", md:'1000px'},
                minWidth: {xs:"auto", md:'700px'},
                mx: 'auto',
                px: { xs: 2, md: 6 },
                py: { xs: 2, md: 3 },
            }}>
                <Card>
                    <Box sx={{ mb: 1 }}>
                        <Typography level="title-md">Orari di lavoro settimanali</Typography>
                        <Typography level="body-sm">
                            Imposta gli orari di lavoro settimanali
                        </Typography>
                    </Box>
                    <Divider />
                    <Stack spacing={2} sx={{ flexGrow: 1, p:1 }}>
                        <Grid container textAlign="center" alignItems="center">
                            <Grid xs={0} md={3} />
                            <Grid xs={5} md={4}>
                                <Typography level="title-md">Ora inizio</Typography>
                            </Grid>
                            <Grid  xs={5} md={4}>
                                <Typography level="title-md">Ora fine</Typography>
                            </Grid>
                        </Grid>
                        {
                            Object.keys(workingHours).sort().map(d => <RowDay id={d}
                                                                              values={workingHours[d as keyof WorkingHoursModel]}
                                                                              setValues={setDayValues(d)}
                                                                              key={d} />)
                        }
                    </Stack>
                    <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                        <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>
                            <Button size="sm"
                                    variant="solid"
                                    disabled={loading || JSON.stringify(Object.values(serverWorkingHours ?? {}).sort())===JSON.stringify(Object.values(workingHours).sort()) || !validateWorkingHours()}
                                    onClick={() => updateWorkingHours()}
                            >
                                Aggiorna dati
                            </Button>
                        </CardActions>
                    </CardOverflow>
                </Card>
            </Stack>
        </PageStandard>
    )

    function validateWorkingHours(): boolean {
        // Verifica che ci sia almeno un giorno a settimana con almeno uno slot
        const hasAtLeastOneSlot = Object.values(workingHours).some(daySlots => daySlots.length > 0);
        if (!hasAtLeastOneSlot) {
            return false;
        }

        // Verifica che tutti gli slot siano validi
        const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

        for (const day in workingHours) {
            const slots = workingHours[day as keyof WorkingHoursModel];
            for (let i = 0; i < slots.length; i++) {
                const { start, end } = slots[i];

                // Verifica il formato di start e end
                if (!timeFormatRegex.test(start) || !timeFormatRegex.test(end)) {
                    return false;
                }

                // Verifica che start sia minore di end
                if (start >= end) {
                    return false;
                }

                // Verifica che lo start di uno slot non sia antecedente all'end dello slot precedente
                if (i > 0 && start < slots[i - 1].end) {
                    return false;
                }

                // Verifica che l'end di uno slot non sia successivo allo start dello slot successivo
                if (i < slots.length - 1 && end > slots[i + 1].start) {
                    return false;
                }
            }
        }

        return true;
    }


    function RowDay(props: {id: string, values: {start: string, end: string}[], setValues: (newValues: { start: string, end: string }[]) => void }) {

        const { id, values, setValues } = props;

        const dayLabel = capitalize(moment.weekdays(true)[Number(id)])

        const handleAddTimeSlot = () => {
            const newValues = [...values, { start: '', end: '' }];
            setValues(newValues);
        };

        const handleRemoveTimeSlot = (index: number) => {
            const newValues = values.filter((_, i) => i !== index);
            setValues(newValues);
        };

        const handleChange = (index: number, field: 'start' | 'end', value: string) => {
            const newValues = values.map((v, i) => (i === index ? { ...v, [field]: value } : v));
            setValues(newValues);
        };

        return (
            <Stack>
                {
                    values.length > 0 ?
                    values.map((slot, index) => {
                        const isFirst = index === 0
                        const isLast = index === values.length-1
                        return (
                            <Grid container spacing={2} alignItems="center" sx={{mb:1}} key={index}>
                                <Grid xs={12} md={3}>
                                    {
                                        isFirst ?
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Switch size="sm"
                                                        checked={values.length > 0}
                                                        onChange={(event) => {
                                                            if(event.target.checked) setValues([{start:"", end:""}])
                                                            else setValues([])
                                                        }}
                                                />
                                                <Typography level="title-md">{dayLabel}</Typography>
                                            </Stack>
                                            :
                                            null
                                    }
                                </Grid>
                                <Grid xs={5} md={4}>
                                    <TimeInput size="sm"
                                               value={slot.start}
                                               onChange={e => handleChange(index, 'start', e)}
                                               error={!validateTimeFormatAndOrder(slot.start, slot.start, slot.end, values[index-1]?.end ?? undefined, values[index+1]?.start ?? undefined)}
                                    />
                                </Grid>
                                <Grid xs={5} md={4}>
                                    <TimeInput size="sm"
                                               value={slot.end}
                                               onChange={e => handleChange(index, 'end', e)}
                                               error={!validateTimeFormatAndOrder(slot.end, slot.start, slot.end, values[index-1]?.end ?? undefined, values[index+1]?.start ?? undefined)}
                                    />
                                </Grid>
                                <Grid xs={2} md={1}>
                                    {
                                        isLast ?
                                            <IconButton onClick={handleAddTimeSlot}>
                                                <Add />
                                            </IconButton>
                                            :
                                            <IconButton onClick={() => handleRemoveTimeSlot(index)}>
                                                <Remove />
                                            </IconButton>
                                    }
                                </Grid>
                            </Grid>
                        )
                    })
                        :
                        <Grid container spacing={2} alignItems="center" sx={{mb:1}}>
                            <Grid xs={6} md={3}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Switch size="sm"
                                            checked={values.length > 0}
                                            onChange={(event) => {
                                                if(event.target.checked) setValues([{start:"", end:""}])
                                                else setValues([])
                                            }}
                                    />
                                    <Typography level="title-md">{dayLabel}</Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={6} md={9}>
                                <Typography level="title-md">Chiuso</Typography>
                            </Grid>
                        </Grid>
                }
            </Stack>
        )

        function validateTimeFormatAndOrder(value: string, start: string, end: string, previousEnd?: string, nextStart?: string): boolean {
            const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
            // Verifica il formato di start e end
            if (!timeFormatRegex.test(value)) {
                return false;
            }

            // Verifica che start sia minore di end
            if (start >= end) {
                return false;
            }

            // Verifica che lo start non sia antecedente all'end dello slot precedente
            if (previousEnd && start < previousEnd) {
                return false;
            }

            // Verifica che l'end non sia successivo allo start dello slot successivo
            if (nextStart && end > nextStart) {
                return false;
            }

            return true;
        }
    }

    async function updateWorkingHours() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/updateWorkingHours`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({ workingHours })
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Dati modificati con successo")
                window.location.reload()
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}