import {PageStandard} from "../../utils/PageStandard";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardOverflow,
    Chip,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Option,
    Select,
    Stack,
    Typography
} from "@mui/joy";
import {Permissions, Roles, ServiceModel, UserModel} from "../../utils/Model";
import {
    APIurl,
    areObjectsEqual,
    capitalize,
    isValidPhoneNumber,
    minutesToHoursString,
    TelefonoInput
} from "../../utils/Utils";
import {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";
import {useSearchParams} from "react-router-dom";
import moment from "moment";

export function Utente() {

    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get("userId")
    if(!userId) window.location.replace("/gestione-utenti/dipendenti")

    const [user, setUser] = useState<UserModel | null>(null)
    const [userServer, setUserServer] = useState<UserModel | null>(null)
    const [services, setServices] = useState<ServiceModel[]>([]);

    useEffect(() => {
        fetchUser().then(() => {})
        fetchServices().then(() => {})
    }, []);

    async function fetchUser(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getUser/${userId}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                const newData = {
                    ...data,
                    birthdate: moment(data.birthdate).format("YYYY-MM-DD")
                }
                setUser(newData)
                setUserServer(newData)
            }

        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }
    async function fetchServices(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getServices`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setServices(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    if(user === null || services === null) return null
    else return (
        <PageStandard title="Utente" sidebarVisible={true}>
            <Stack sx={{
                display: 'flex',
                maxWidth: '800px',
                mx: 'auto',
                px: { xs: 2, md: 6 },
                py: { xs: 2, md: 3 },
            }}>
                <Card>
                <Box sx={{ mb: 1 }}>
                    <Typography level="title-md">Informazioni utente</Typography>
                    <Typography level="body-sm">
                        Modifica i dati dell'utente
                    </Typography>
                </Box>
                <Divider />
                <Stack
                    direction="row"
                    spacing={3}
                    sx={{ my: 1 }}
                >
                    <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Nome *</FormLabel>
                                <Input size="sm"
                                       type="text"
                                       placeholder="Mario"
                                       value={user.name}
                                       onChange={e => setUser({...user, name: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Cognome *</FormLabel>
                                <Input size="sm"
                                       type="text"
                                       placeholder="Rossi"
                                       value={user.surname}
                                       onChange={e => setUser({...user, surname: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <FormControl>
                                <FormLabel>Email *</FormLabel>
                                <Input size="sm"
                                       type="email"
                                       placeholder="nome@email.com"
                                       value={user.email}
                                       onChange={e => setUser({...user, email: e.target.value})}
                                       disabled
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <FormControl>
                                <FormLabel>Telefono</FormLabel>
                                <TelefonoInput valuePrefisso={user.phoneNumberPrefisso}
                                               valuePhone={user.phoneNumber}
                                               onChangePrefisso={e => setUser({...user, phoneNumberPrefisso: e})}
                                               onChangePhone={e => setUser({...user, phoneNumber: e})}
                                               error={!isValidPhoneNumber(user.phoneNumberPrefisso + " " + user.phoneNumber)}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={4}>
                            <FormControl>
                                <FormLabel>Data di nascita</FormLabel>
                                <Input size="sm"
                                       type="date"
                                       value={user.birthdate}
                                       onChange={e => setUser({...user, birthdate: e.target.value})}
                                />
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Ruolo *</FormLabel>
                                <Select
                                    size="sm"
                                    placeholder="Scegli..."
                                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                                    value={user.role}
                                    onChange={(e, newValue) => {
                                        if(newValue === Roles.ADMIN) {
                                            setUser({...user, role: newValue!, permissions: []})
                                        } else {
                                            setUser({...user, role: newValue!})
                                        }
                                    }}
                                >
                                    <Option value="" disabled>Scegli...</Option>
                                    {
                                        Object.keys(Roles).map(a => <Option value={a.toLowerCase()} key={a}>{capitalize(a.toLowerCase())}</Option>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={6}>
                            <FormControl>
                                <FormLabel>Permessi *</FormLabel>
                                <Select
                                    size="sm"
                                    placeholder="Scegli..."
                                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                                    multiple
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                                            {selected.map((selectedOption) => (
                                                <div key={selectedOption.label!.toString()}>
                                                    <Chip variant="soft" color="primary">
                                                        {selectedOption.label}
                                                    </Chip>
                                                </div>
                                            ))}
                                        </Box>
                                    )}
                                    value={user.permissions}
                                    onChange={(e, newValue) => setUser({...user, permissions: newValue!})}
                                    disabled={user.role === Roles.ADMIN}
                                >
                                    <Option value="" disabled>Scegli...</Option>
                                    {
                                        Object.keys(Permissions).map(a => <Option value={Object(Permissions)[a]} key={a}>{Object(Permissions)[a]}</Option>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid xs={12} md={12}>
                            <FormControl>
                                <FormLabel>Servizi</FormLabel>
                                <Select
                                    size="sm"
                                    placeholder="Scegli..."
                                    slotProps={{ button: { sx: { whiteSpace: 'nowrap' } } }}
                                    multiple
                                    renderValue={(selected) => (
                                        <Box sx={{ display: 'flex', gap: '0.25rem' }}>
                                            {selected.map((selectedOption) => (
                                                <div key={selectedOption.label?.toString()}>
                                                    <Chip variant="soft" color="primary">
                                                        {selectedOption.label}
                                                    </Chip>
                                                </div>
                                            ))}
                                        </Box>
                                    )}
                                    value={user.services}
                                    onChange={(e, newValue) => setUser({...user, services: newValue!})}
                                >
                                    <Option value="" disabled>Scegli...</Option>
                                    {
                                        services.map(a => <Option value={a.id} key={a.id}>{a.title} - {minutesToHoursString(a.duration)}</Option>)
                                    }
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </Stack>
                <CardOverflow sx={{ borderTop: '1px solid', borderColor: 'divider' }}>
                    <CardActions sx={{ alignSelf: 'flex-end', pt: 2 }}>

                        <Button size="sm"
                                variant="solid"
                                disabled={user.name==="" || user.surname==="" || user.email==="" || loading || areObjectsEqual(user, userServer!)}
                                onClick={() => updateUser()}
                        >
                            Modifica dati
                        </Button>
                    </CardActions>
                </CardOverflow>
            </Card>
            </Stack>
        </PageStandard>
    )


    async function updateUser() {
        setLoading(true)
        try {
            const user1 = firebase.auth().currentUser;
            if (!user1) throw new Error("Utente non autenticato");
            const token = await user1.getIdToken();

            const response = await fetch(`${APIurl}/updateUser/${userId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(user)
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Utente aggiornato con successo")
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}