import {PageStandard} from "../../utils/PageStandard";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardOverflow,
    CircularProgress,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Input,
    Stack,
    Switch,
    Typography
} from "@mui/joy";
import {ShiftModel, WorkingHoursModel} from "../../utils/Model";
import {APIurl, capitalize, TimeInput} from "../../utils/Utils";
import React, {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";
import moment from "moment/moment";
import {Add, Check, Close, Delete, Edit, Remove} from "@mui/icons-material";
import {useSearchParams} from "react-router-dom";

export function TurniDipendenti() {
    const {
        loading,
        bigLoading,
        error,
        message,
        setLoading,
        setBigLoading,
        setError,
        setMessage
    } = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const [searchParams, setSearchParams] = useSearchParams();
    const userId = searchParams.get("userId")
    if (!userId) window.location.replace("/gestione-utenti/dipendenti")

    const [userName, setUserName] = useState<String>("")
    useEffect(() => {
        fetchUserName().then(() => {})
    }, []);
    async function fetchUserName(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getUser/` + userId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setUserName(data.surname + " " + data.name)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    return (
        <PageStandard title={"Turni dipendenti - " + userName} sidebarVisible={true}>
            <TurniDipendentiInner userId={userId} />
        </PageStandard>
    );
}

export function TurniDipendentiInner(props: {userId: string|null}) {

    const {
        loading,
        bigLoading,
        error,
        message,
        setLoading,
        setBigLoading,
        setError,
        setMessage
    } = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const workingHoursInitial: WorkingHoursModel = {
        "0": [],
        "1": [],
        "2": [],
        "3": [],
        "4": [],
        "5": [],
        "6": [],
    }
    const initialNewShift = {
        date: moment().format("YYYY-MM-DD"),
        start: "08:00",
        end: "12:00"
    }
    const [defaultWorkingHours, setDefaultWorkingHours] = useState<WorkingHoursModel>(workingHoursInitial)
    const [serverDefaultWorkingHours, setServerDefaultWorkingHours] = useState<WorkingHoursModel>(workingHoursInitial)
    const [userShifts, setUserShifts] = useState<ShiftModel[]>([])
    const [newShift, setNewShift] = useState<{date: string, start: string, end: string}>(initialNewShift)
    const [newShiftVisible, setNewShiftVisible] = useState(false)

    useEffect(() => {
        fetchUserDefaultWorkingHours().then(() => {})
        fetchUserShifts().then(() => {})
    }, []);

    async function fetchUserDefaultWorkingHours(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getUser/` + props.userId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setServerDefaultWorkingHours(data.workingHours)
                setDefaultWorkingHours(data.workingHours)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }
    async function fetchUserShifts(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getUserShifts/` + props.userId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setUserShifts(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    const setDayValues = (day: string) => (newValues: { start: string, end: string }[]) => {
        setDefaultWorkingHours(prev => ({...prev, [day]: newValues}));
    };

    if (bigLoading) return <CircularProgress/>
    if (error) return <></>
    return (
        <Grid container spacing={5} sx={{
            display: 'flex',
            px: {xs: 2, md: 6},
            py: {xs: 2, md: 3},
        }}>
            <Grid xs={12} md={6}>
                <Card sx={{flexGrow: 1}}>
                    <Box sx={{mb: 1}}>
                        <Stack direction="row" spacing={2} justifyContent="space-between">
                            <Stack>
                                <Typography level="title-md">Turni di lavoro futuri</Typography>
                                <Typography level="body-sm">
                                    Modifica o elimina i turni di lavoro futuri
                                </Typography>
                            </Stack>
                            <IconButton onClick={() => setNewShiftVisible(true)}>
                                <Add/>
                            </IconButton>
                        </Stack>
                    </Box>
                    { newShiftVisible ? <Divider /> : null }
                    {
                        newShiftVisible ?
                            <Stack spacing={2} sx={{flexGrow: 1, p: 1}}>
                                <Grid container spacing={2} alignItems="center">
                                    <Grid xs={3}>
                                        <FormControl size="sm">
                                            <FormLabel>Data</FormLabel>
                                            <Input size="sm"
                                                   type="date"
                                                   value={newShift?.date}
                                                   onChange={e => setNewShift({...newShift, date: e.target.value})}
                                                   error={!validateTimeShift(null, newShift.date, newShift.start, newShift.end, userShifts)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={4}>
                                        <FormControl size="sm">
                                            <FormLabel>Ora inizio</FormLabel>
                                            <TimeInput size="sm"
                                                       value={newShift?.start}
                                                       onChange={e => setNewShift({...newShift, start: e})}
                                                       error={!validateTimeShift(null, newShift.date, newShift.start, newShift.end, userShifts)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={4}>
                                        <FormControl size="sm">
                                            <FormLabel>Ora fine</FormLabel>
                                            <TimeInput size="sm"
                                                       value={newShift?.end}
                                                       onChange={e => setNewShift({...newShift, end: e})}
                                                       error={!validateTimeShift(null, newShift.date, newShift.start, newShift.end, userShifts)}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid xs={1} textAlign="end">
                                        <IconButton
                                            onClick={() => addShift()}
                                            disabled={loading || !validateTimeShift(null, newShift.date, newShift.start, newShift.end, userShifts)}
                                        >
                                            <Check/>
                                        </IconButton>
                                    </Grid>
                                </Grid>
                            </Stack>
                            :
                            null
                    }
                    <Divider/>
                    <Stack spacing={2} sx={{flexGrow: 1, p: 1}}>
                        {
                            userShifts.map((item) => (<CardUserShift key={item.id} id={item.id} uid={item.uid} start={item.start} end={item.end}/>))
                        }
                    </Stack>
                </Card>
            </Grid>
            <Grid xs={12} md={6}>
                <Card sx={{flexGrow: 1}}>
                    <Box sx={{mb: 1}}>
                        <Typography level="title-md">Turno settimanale di default</Typography>
                        <Typography level="body-sm">
                            Imposta gli orari di lavoro di default dell'utente
                        </Typography>
                    </Box>
                    <Divider/>
                    <Stack spacing={2} sx={{flexGrow: 1, p: 1}}>
                        <Grid container textAlign="center" alignItems="center">
                            <Grid xs={0} md={3}/>
                            <Grid xs={5} md={4}>
                                <Typography level="title-md">Ora inizio</Typography>
                            </Grid>
                            <Grid xs={5} md={4}>
                                <Typography level="title-md">Ora fine</Typography>
                            </Grid>
                        </Grid>
                        {
                            Object.keys(defaultWorkingHours).sort().map(d => <RowDay id={d}
                                                                                     values={defaultWorkingHours[d as keyof WorkingHoursModel]}
                                                                                     setValues={setDayValues(d)}
                                                                                     key={d}/>)
                        }
                    </Stack>
                    <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                        <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                            <Button size="sm"
                                    variant="solid"
                                    disabled={loading || JSON.stringify(Object.values(serverDefaultWorkingHours ?? {}).sort()) === JSON.stringify(Object.values(defaultWorkingHours).sort()) || !validateWorkingHours()}
                                    onClick={() => updateWorkingHours()}
                            >
                                Aggiorna dati
                            </Button>
                        </CardActions>
                    </CardOverflow>
                </Card>
            </Grid>
        </Grid>
    )

    async function addShift() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/addShift`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({
                    uid: props.userId,
                    start: `${newShift.date} ${newShift.start}:00`,
                    end: `${newShift.date} ${newShift.end}:00`,
                }),
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Turno creato con successo")
                window.location.reload()
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }

    function validateWorkingHours(): boolean {
        // Verifica che ci sia almeno un giorno a settimana con almeno uno slot
        const hasAtLeastOneSlot = Object.values(defaultWorkingHours).some(daySlots => daySlots.length > 0);
        if (!hasAtLeastOneSlot) {
            return false;
        }

        // Verifica che tutti gli slot siano validi
        const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;

        for (const day in defaultWorkingHours) {
            const slots = defaultWorkingHours[day as keyof WorkingHoursModel];
            for (let i = 0; i < slots.length; i++) {
                const {start, end} = slots[i];

                // Verifica il formato di start e end
                if (!timeFormatRegex.test(start) || !timeFormatRegex.test(end)) {
                    return false;
                }

                // Verifica che start sia minore di end
                if (start >= end) {
                    return false;
                }

                // Verifica che lo start di uno slot non sia antecedente all'end dello slot precedente
                if (i > 0 && start < slots[i - 1].end) {
                    return false;
                }

                // Verifica che l'end di uno slot non sia successivo allo start dello slot successivo
                if (i < slots.length - 1 && end > slots[i + 1].start) {
                    return false;
                }
            }
        }

        return true;
    }


    function RowDay(props: {
        id: string,
        values: { start: string, end: string }[],
        setValues: (newValues: { start: string, end: string }[]) => void
    }) {

        const {id, values, setValues} = props;

        const dayLabel = capitalize(moment.weekdays(true)[Number(id)])

        const handleAddTimeSlot = () => {
            const newValues = [...values, {start: '', end: ''}];
            setValues(newValues);
        };

        const handleRemoveTimeSlot = (index: number) => {
            const newValues = values.filter((_, i) => i !== index);
            setValues(newValues);
        };

        const handleChange = (index: number, field: 'start' | 'end', value: string) => {
            const newValues = values.map((v, i) => (i === index ? {...v, [field]: value} : v));
            setValues(newValues);
        };

        return (
            <Stack>
                {
                    values.length > 0 ?
                        values.map((slot, index) => {
                            const isFirst = index === 0
                            const isLast = index === values.length - 1
                            return (
                                <Grid container spacing={2} alignItems="center" sx={{mb: 1}} key={index}>
                                    <Grid xs={12} md={3}>
                                        {
                                            isFirst ?
                                                <Stack direction="row" spacing={1} alignItems="center">
                                                    <Switch size="sm"
                                                            checked={values.length > 0}
                                                            onChange={(event) => {
                                                                if (event.target.checked) setValues([{
                                                                    start: "",
                                                                    end: ""
                                                                }])
                                                                else setValues([])
                                                            }}
                                                    />
                                                    <Typography level="title-md">{dayLabel}</Typography>
                                                </Stack>
                                                :
                                                null
                                        }
                                    </Grid>
                                    <Grid xs={5} md={4}>
                                        <TimeInput size="sm"
                                                   value={slot.start}
                                                   onChange={e => handleChange(index, 'start', e)}
                                                   error={!validateTimeFormatAndOrder(slot.start, slot.start, slot.end, values[index - 1]?.end ?? undefined, values[index + 1]?.start ?? undefined)}
                                        />
                                    </Grid>
                                    <Grid xs={5} md={4}>
                                        <TimeInput size="sm"
                                                   value={slot.end}
                                                   onChange={e => handleChange(index, 'end', e)}
                                                   error={!validateTimeFormatAndOrder(slot.end, slot.start, slot.end, values[index - 1]?.end ?? undefined, values[index + 1]?.start ?? undefined)}
                                        />
                                    </Grid>
                                    <Grid xs={2} md={1}>
                                        {
                                            isLast ?
                                                <IconButton onClick={handleAddTimeSlot}>
                                                    <Add/>
                                                </IconButton>
                                                :
                                                <IconButton onClick={() => handleRemoveTimeSlot(index)}>
                                                    <Remove/>
                                                </IconButton>
                                        }
                                    </Grid>
                                </Grid>
                            )
                        })
                        :
                        <Grid container spacing={2} alignItems="center" sx={{mb: 1}}>
                            <Grid xs={3}>
                                <Stack direction="row" spacing={1} alignItems="center">
                                    <Switch size="sm"
                                            checked={values.length > 0}
                                            onChange={(event) => {
                                                if (event.target.checked) setValues([{start: "", end: ""}])
                                                else setValues([])
                                            }}
                                    />
                                    <Typography level="title-md">{dayLabel}</Typography>
                                </Stack>
                            </Grid>
                            <Grid xs={9}>
                                <Typography level="title-md"></Typography>
                            </Grid>
                        </Grid>
                }
            </Stack>
        )

        function validateTimeFormatAndOrder(value: string, start: string, end: string, previousEnd?: string, nextStart?: string): boolean {
            const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
            // Verifica il formato di start e end
            if (!timeFormatRegex.test(value)) {
                return false;
            }

            // Verifica che start sia minore di end
            if (start >= end) {
                return false;
            }

            // Verifica che lo start non sia antecedente all'end dello slot precedente
            if (previousEnd && start < previousEnd) {
                return false;
            }

            // Verifica che l'end non sia successivo allo start dello slot successivo
            return !(nextStart && end > nextStart);


        }
    }

    function validateTimeShift(id: string | null, date: string, start: string, end: string, shifts: ShiftModel[]): boolean {
        const timeFormatRegex = /^([01]\d|2[0-3]):([0-5]\d)$/;
        // Verifica il formato di start e end
        if (!timeFormatRegex.test(start)) {
            return false;
        }
        if (!timeFormatRegex.test(end)) {
            return false;
        }

        // Verifica che start sia minore di end
        if (start >= end) {
            return false;
        }

        const dayShifts = shifts.filter(a => moment(a.start).format("YYYY-MM-DD") === date).filter(a => a.id!==id);

        // Utilizza some per controllare se esistono sovrapposizioni
        const hasOverlap = dayShifts.some(a => {
            const s = moment(a.start).format("HH:mm");
            const e = moment(a.end).format("HH:mm");

            // Verifica sovrapposizioni di orari
            if (start === s || start === e || end === s || end === e) {
                return true;
            }
            return (start > s && start < e) || (end > s && end < e);

        });

        // Se esiste una sovrapposizione, ritorna false
        return !hasOverlap;


    }

    function CardUserShift(props: ShiftModel) {

        const {id, start, end} = props;

        const dayLabel = moment(start).format("ddd DD/MM/YY")//"Lun 04/10/24"
        const [startTime, setStartTime] = useState(moment(start).format("HH:mm"))
        const [endTime, setEndTime] = useState(moment(end).format("HH:mm"))
        const [editEnabled, setEditEnabled] = useState(false)

        return (
            <Card>
                <Grid container spacing={2} alignItems="center">
                    <Grid xs={3}>
                        <Typography level="title-md">{dayLabel}</Typography>
                    </Grid>
                    <Grid xs={3}>
                        <TimeInput size="sm"
                                   value={startTime}
                                   disabled={!editEnabled}
                                   onChange={e => setStartTime(e)}
                                   error={!validateTimeShift(id, moment(start).format("YYYY-MM-DD"), startTime, endTime, userShifts)}
                        />
                    </Grid>
                    <Grid xs={3}>
                        <TimeInput size="sm"
                                   value={endTime}
                                   disabled={!editEnabled}
                                   onChange={e => setEndTime(e)}
                                   error={!validateTimeShift(id, moment(start).format("YYYY-MM-DD"), startTime, endTime, userShifts)}
                        />
                    </Grid>
                    <Grid xs={3} textAlign="end">
                        {
                            editEnabled ?
                                <>
                                    <IconButton onClick={() => {
                                        setEditEnabled(false)
                                        setStartTime(moment(start).format("HH:mm"))
                                        setEndTime(moment(end).format("HH:mm"))
                                    }}>
                                        <Close/>
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        checkBookingsShift({action:"edit", id: id, newShift: {start: `${moment(start).format("YYYY-MM-DD")} ${startTime}:00`, end: `${moment(start).format("YYYY-MM-DD")} ${endTime}:00`}}).then((res) => {
                                            if(res.code === 200) {
                                                if(window.confirm("Modificare questo turno?")) {
                                                    updateShift().then(() => {})
                                                }
                                            } else if(res.code === 400) {
                                                window.alert(`ERRORE\nSono state trovate ${res.bookings} prenotazioni che non rientrano nel nuovo intervallo di turni.\nEliminare o modificare le prenotazioni interessate e successivamente modificare il turno.`)
                                            } else if(res.code === 401) {
                                                window.alert(`ERRORE\nIl nuovo turno si sovrappone con un altro turno`)
                                            }
                                        })
                                    }}
                                        disabled={loading || !validateTimeShift(id, moment(start).format("YYYY-MM-DD"), startTime, endTime, userShifts)}
                                    >
                                        <Check/>
                                    </IconButton>
                                </>
                                :
                                <>
                                    <IconButton onClick={() => setEditEnabled(true)}>
                                        <Edit/>
                                    </IconButton>
                                    <IconButton onClick={() => {
                                        checkBookingsShift({action:"delete", id: id, newShift: null}).then((res) => {
                                            if(res.code === 200) {
                                                if(window.confirm("Eliminare definitivamente questo turno?")) {
                                                    deleteShift().then(() => {})
                                                }
                                            } else if(res.code === 400) {
                                                window.alert(`ERRORE\nSono state trovate ${res.bookings} prenotazioni durante questo turno.\nEliminare o modificare le prenotazioni e successivamente eliminare il turno.`)
                                            }
                                        })
                                    }}>
                                        <Delete/>
                                    </IconButton>
                                </>
                        }
                    </Grid>
                </Grid>
            </Card>
        )

        async function updateShift() {
            setLoading(true)
            try {
                const user = firebase.auth().currentUser;
                if (!user) throw new Error("Utente non autenticato");
                const token = await user.getIdToken();

                const response = await fetch(`${APIurl}/updateShift/${id}`, {
                    method: "PUT",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        start: `${moment(start).format("YYYY-MM-DD")} ${startTime}:00`,
                        end: `${moment(start).format("YYYY-MM-DD")} ${endTime}:00`,
                    }),
                })
                if (!response.ok) {
                    const error = await response.clone().json();
                    setError(error);
                } else {
                    setMessage("Turno modificato con successo")
                    window.location.reload()
                }
            } catch (e: unknown) {
                setError(e)
            } finally {
                setLoading(false)
            }
        }

        async function checkBookingsShift(props: {action: string, id: string, newShift: {start: string, end: string} | null}) {
            setLoading(true)
            try {
                const user = firebase.auth().currentUser;
                if (!user) throw new Error("Utente non autenticato");
                const token = await user.getIdToken();

                const response = await fetch(`${APIurl}/getBookingsShifts`, {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                    body: JSON.stringify({
                        id: props.id,
                        uid: user.uid,
                        action: props.action,
                        newShift: props.newShift
                    }),
                })
                return response.json()
            } catch (e: unknown) {
                setError(e)
            } finally {
                setLoading(false)
            }
        }

        async function deleteShift() {
            setLoading(true)
            try {
                const user2 = firebase.auth().currentUser
                if (!user2) throw new Error("Utente non autenticato")
                const token = await user2.getIdToken();

                const response = await fetch(`${APIurl}/deleteShift/${id}`, {
                    method: "DELETE",
                    headers: {
                        "Content-Type": "application/json",
                        "Authorization": `Bearer ${token}`
                    },
                })
                if (!response.ok) {
                    const error = await response.clone().json();
                    setError(error);
                } else {
                    setMessage("Turno eliminato con successo")
                    window.location.reload()
                }
            } catch (e: unknown) {
                setError(e)
            } finally {
                setLoading(false)
            }
        }
    }

    async function updateWorkingHours() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/updateUserWorkingHours/${props.userId}`, {
                method: "PUT",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({workingHours: defaultWorkingHours})
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Dati modificati con successo")
                window.location.reload()
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}