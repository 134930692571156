import {PageStandard} from "../../utils/PageStandard";
import FullCalendar from "@fullcalendar/react";
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from "@fullcalendar/interaction"
import {CalendarEventModel, CustomerModel, Permissions, Roles, ServiceModel, UserModel} from "../../utils/Model";
import itLocale from '@fullcalendar/core/locales/it';
import {
    Badge,
    Box,
    Button,
    ButtonGroup,
    Chip,
    Drawer,
    FormControl,
    FormLabel,
    IconButton, Option, Select,
    Stack,
    Tooltip,
    Typography
} from "@mui/joy";
import {Link} from "react-router-dom";
import {
    CalendarViewDay,
    CalendarViewMonth,
    CalendarViewWeek,
    ChevronLeft,
    ChevronRight, FilterList, ZoomIn,
    ZoomOut
} from "@mui/icons-material";
import {useContext, useEffect, useRef, useState} from "react";
import moment, {Moment} from "moment";
import 'moment/locale/it';
import {FeedbackContext} from "../../utils/FeedbackContext";
import {APIurl, minutesToHoursString} from "../../utils/Utils";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";

export function TutteLePrenotazioni() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany, user} = useContext(AuthContext)

    const [bookings, setBookings] = useState<CalendarEventModel[]>([])
    const calendarRef = useRef<any>(null)
    const initialView = "timeGridWeek"
    const [currentView, setCurrentView] = useState(initialView)
    const [displayedDates, setDisplayedDates] = useState<{from: Moment, to: Moment} | null>(null)
    const [zoom, setZoom] = useState(3)
    const [services, setServices] = useState<ServiceModel[]>([])
    const [users, setUsers] = useState<UserModel[]>([])
    const [filtersVisible, setFiltersVisible] = useState(false)
    const [filters, setFilters] = useState({service: "", employee: ""})
    moment.locale('it')

    useEffect(() => {
        fetchBookings().then(() => {})
    }, []);

    async function fetchBookings(): Promise<void> {
        setLoading(true);
        try {
            const user2 = firebase.auth().currentUser;
            if (!user2) throw new Error("Utente non autenticato");
            const token = await user2.getIdToken();

            const url = (user.role===Roles.ADMIN || user.permissions.includes(Permissions.PRENOTAZIONI_ALTRI_UTENTI)) ?
                `${APIurl}/getBookings/null`
                :
                `${APIurl}/getBookings/${user2.uid}`
            const response = await fetch(url, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setServices(data.services)
                setBookings(data.bookings)
                setUsers(data.users)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
        }
    }

    function badgeCounterFilter(): number {
        let res = 0

        if(filters.service !== "") res++
        if(filters.employee !== "") res++

        return res
    }

    return (
        <PageStandard title="Tutte le prenotazioni"
                      titleElement={genTitle()}
                      sidebarVisible={true}
                      headerEnd={<Stack direction="row" sx={{flexWrap: "wrap", gap:2}}>
                          <Badge badgeContent={badgeCounterFilter()}>
                              <IconButton
                                  variant="outlined" color="primary"
                                  onClick={() => setFiltersVisible(true)}
                              >
                                  <FilterList/>
                              </IconButton>
                          </Badge>
                          <ButtonGroup variant="outlined" color="primary">
                              <IconButton
                                  onClick={() => zoomOut()}
                                  disabled={zoom <= 1.6 || currentView === "dayGridMonth"}
                              >
                                  <ZoomOut/>
                              </IconButton>
                              <IconButton
                                  onClick={() => zoomIn()}
                                  disabled={zoom >= 10 || currentView === "dayGridMonth"}
                              >
                                  <ZoomIn/>
                              </IconButton>
                          </ButtonGroup>
                          <Link to="/prenotazioni/nuova-prenotazione"><Button>Nuova prenotazione</Button></Link>
                          <ButtonGroup variant="outlined" color="primary">
                              <IconButton onClick={() => calPrev()}><ChevronLeft/></IconButton>
                              <Button variant={moment().startOf("day").isSame(displayedDates?.from) ? "solid" : "outlined"} onClick={() => calToday()}>Oggi</Button>
                              <IconButton onClick={() => calNext()}><ChevronRight/></IconButton>
                          </ButtonGroup>
                          <ButtonGroup color="primary">
                              <IconButton variant={currentView==="dayGridMonth" ? "solid" : "outlined"} onClick={() => calView("dayGridMonth")}><CalendarViewMonth/></IconButton>
                              <IconButton variant={currentView==="timeGridWeek" ? "solid" : "outlined"} onClick={() => calView("timeGridWeek")}><CalendarViewWeek/></IconButton>
                              <IconButton variant={currentView==="timeGridDay" ? "solid" : "outlined"} onClick={() => calView("timeGridDay")}><CalendarViewDay/></IconButton>
                          </ButtonGroup>
        </Stack>}
        >
            <Drawer
                anchor="right"
                open={filtersVisible}
                onClose={() => setFiltersVisible(false)}
            >
                <Stack spacing={3} m={4} sx={{height: "100%"}} justifyContent="space-between">
                    <Stack spacing={3}>
                    <FormControl size="sm">
                        <FormLabel>Servizio</FormLabel>
                        <Select
                            size="sm"
                            placeholder="Scegli..."
                            slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                            value={filters.service}
                            onChange={(e, newValue) => setFilters({...filters, service: newValue!})}
                        >
                            <Option value="">Tutti i servizi</Option>
                            {
                                services.map(a => <Option value={a.id}
                                                          key={a.id}>{a.title} - {minutesToHoursString(a.duration)}</Option>)
                            }
                        </Select>
                    </FormControl>
                    <FormControl size="sm">
                        <FormLabel>Dipendenti</FormLabel>
                        <Select
                            size="sm"
                            placeholder="Scegli..."
                            slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                            value={filters.employee}
                            onChange={(e, newValue) => setFilters({...filters, employee: newValue!})}
                        >
                            <Option value="">Tutti i dipendenti</Option>
                            {
                                users.map(a => <Option value={a.id}
                                                       key={a.id}>{a.surname.toUpperCase()} {a.name}</Option>)
                            }
                        </Select>
                    </FormControl>
                    </Stack>
                    <Stack spacing={3}>
                        <Button
                            variant="outlined"
                            color="primary"
                            onClick={() => setFilters({service: "", employee: ""})}
                        >
                            Cancella filtri
                        </Button>
                        <Button
                            variant="solid"
                            color="primary"
                            onClick={() => setFiltersVisible(false)}
                        >
                            Chiudi
                        </Button>
                    </Stack>
                </Stack>
            </Drawer>
            <Box sx={{pb:4, height:"100%"}}>
                <FullCalendar
                    plugins={[ timeGridPlugin, dayGridPlugin, interactionPlugin ]}
                    initialView={initialView}
                    events={filterEvents(bookings)}
                    locale={itLocale}
                    headerToolbar={false}
                    height="85vh"
                    ref={calendarRef}
                    allDaySlot={false}
                    slotLabelFormat={{hour:"2-digit", minute:"2-digit"}}
                    slotLabelClassNames="hourLabels"
                    dayHeaderClassNames="hourLabels"
                    datesSet={(dateInfo) => {
                        setDisplayedDates({from: moment(dateInfo.start), to: moment(dateInfo.end).subtract(1, "day")})
                    }}
                    nowIndicator={true}
                    eventContent={e => renderEvent(e as unknown)}
                    eventDidMount={info => {
                        info.el.style.borderRadius = '8px';
                        info.el.style.borderWidth = '0px';
                        info.el.style.padding = '3px';
                    }}
                    /*
                    selectable
                    select={e => {
                        const date = moment(e.start).format("YYYY-MM-DD")
                        const time = moment(e.start).format("HH:mm")
                        window.location.href = `/prenotazioni/nuova-prenotazione?date=${date}&time=${time}`
                    }}
                     */
                    eventClick={event => window.location.href = `/prenotazioni/prenotazione?id=${event.event.id}`}
                    expandRows
                    eventDisplay="block"
                />
            </Box>
        </PageStandard>
    )

    function filterEvents(bookings: CalendarEventModel[]): CalendarEventModel[] {
        let res = bookings

        if(filters.service !== "") {
            res = res.filter(a => a.serviceId === filters.service)
        }
        if(filters.employee !== "") {
            res = res.filter(a => a.employeeId === filters.employee)
        }

        return res
    }

    function calPrev() {
        const calendarAPI = calendarRef?.current?.getApi();
        calendarAPI?.prev();
    }
    function calNext() {
        const calendarAPI = calendarRef?.current?.getApi();
        calendarAPI?.next();
    }
    function calToday() {
        const calendarAPI = calendarRef?.current?.getApi();
        calendarAPI?.today();
    }
    function calView(view: string) {
        const calendarAPI = calendarRef?.current?.getApi();
        calendarAPI?.changeView(view)
        setCurrentView(view)
    }
    function genTitle() {
        switch (currentView) {
            case "dayGridMonth":
                return <Stack spacing={1}>
                    <Typography>Tutte le prenotazioni</Typography>
                    <Chip size="lg" variant="soft" color="primary" sx={{textTransform:"capitalize"}}>
                        {displayedDates?.from.add(15, "days").format("MMMM YYYY")}
                    </Chip>
                </Stack>
            case "timeGridWeek":
                const sameMonth = displayedDates?.from.isSame(displayedDates?.to, "month")
                const sameYear = displayedDates?.from.isSame(displayedDates?.to, "year")

                if(sameYear && sameMonth) {
                    return <Stack spacing={1}>
                        <Typography>Tutte le prenotazioni</Typography>
                        <Chip size="lg" variant="soft" color="primary">
                            {displayedDates?.from.format("D")} - {displayedDates?.to.format("D MMM YYYY")}
                        </Chip>
                    </Stack>
                } else if(sameYear) {
                    return <Stack spacing={1}>
                        <Typography>Tutte le prenotazioni</Typography>
                        <Chip size="lg" variant="soft" color="primary">
                            {displayedDates?.from.format("D MMM")} - {displayedDates?.to.format("D MMM YYYY")}
                        </Chip>
                    </Stack>
                } else  {
                    return <Stack spacing={1}>
                        <Typography>Tutte le prenotazioni</Typography>
                        <Chip size="lg" variant="soft" color="primary">
                            {displayedDates?.from.format("D MMM YYYY")} - {displayedDates?.to.format("D MMM YYYY")}
                        </Chip>
                    </Stack>
                }

            case "timeGridDay":
                return <Stack spacing={1}>
                    <Typography>Tutte le prenotazioni</Typography>
                    <Chip size="lg" variant="soft" color="primary">
                        {displayedDates?.from.format("D MMMM YYYY")}
                    </Chip>
                </Stack>
            default:
                return <Typography>Tutte le prenotazioni</Typography>
        }
    }

    function renderEvent(e: unknown) {

        const event = (e as { event: {
                title: string,
                start: Date,
                end: Date,
                id: string,
                url?: string,
                extendedProps: {
                    serviceId: string,
                    customerId: string,
                    employeeId: string,
                    customerNotes?: string
                }
            } })
            .event

        const start = moment(event.start)
        const end = moment(event.end)
        const user = users.find(u => u.id === event.extendedProps.employeeId)
        const userName = user?.name + " " + user?.surname
        const service = services.find(s => s.id === event.extendedProps.serviceId)?.title

        if(currentView === "dayGridMonth") return (
            <Tooltip placement="top"
                     variant="outlined"
                     color="primary"
                     title={<Stack>
                         <Typography level="title-md" fontWeight="bold">{event.title}</Typography>
                         <Typography level="title-sm">{userName}</Typography>
                         <Typography level="title-sm">{service}</Typography>
                         <Typography level="title-sm">{start.format("HH:mm")} - {end.format("HH:mm")}</Typography>
                     </Stack>}
            >
                <Stack spacing="0.3em"
                       sx={{
                           cursor: "pointer",
                           overflow: "hidden",
                       }}
                >
                    <Typography level="body-sm"
                                sx={{
                                    fontSize:"small",
                                    fontWeight:600,
                                    color:"#fff",
                                    lineHeight:"1em"
                                }}
                    >
                        {event.title}
                    </Typography>
                </Stack>
            </Tooltip>
        )
        else return (
            <Tooltip placement="top"
                     variant="outlined"
                     color="primary"
                     title={<Stack>
                         <Typography level="title-md" fontWeight="bold">{event.title}</Typography>
                         <Typography level="title-sm">{userName}</Typography>
                         <Typography level="title-sm">{service}</Typography>
                         <Typography level="title-sm">{start.format("HH:mm")} - {end.format("HH:mm")}</Typography>
                     </Stack>}
            >
                <Stack spacing="0.3em"
                       sx={{
                           p: 0.2,
                           cursor: "pointer",
                       }}
                >
                    <Typography level="body-sm"
                                sx={{
                                    fontSize:"small",
                                    fontWeight:600,
                                    color:"#fff",
                                    lineHeight:"1em"
                                }}
                    >
                        {event.title}
                    </Typography>
                    <Stack direction={currentView==="timeGridDay" ? "row" : "column"} spacing="0.3em">
                        <Chip size="sm" variant="soft" color="primary">{userName}</Chip>
                        <Chip size="sm" variant="soft" color="primary">{service}</Chip>
                    </Stack>
                    <Typography level="body-sm"
                                sx={{
                                    fontSize:"smaller",
                                    color:"#fff",
                                    lineHeight:"1em"
                                }}
                    >
                        {start.format("HH:mm")} - {end.format("HH:mm")}
                    </Typography>
                </Stack>
            </Tooltip>
        )
    }

    function zoomIn() {
        const elements = document.getElementsByClassName('fc-timegrid-slot')
        for(let i=0; i< elements.length; i++){
            const div = elements[i]
            div.setAttribute(
                "style",
                "height:" + (zoom + 0.5) + "em !important"
            );
        }
        setZoom(zoom + 0.5)
    }
    function zoomOut() {
        const elements = document.getElementsByClassName('fc-timegrid-slot')
        for(let i=0; i< elements.length; i++){
            const div = elements[i]
            div.setAttribute(
                "style",
                "height:" + (zoom - 0.5) + "em !important"
            );
        }
        setZoom(zoom - 0.5)
    }
}