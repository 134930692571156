import {Header} from "./Header";
import {Sidebar} from "./Sidebar";
import {Box, Breadcrumbs, Typography} from "@mui/joy";
import {ReactElement, ReactNode, useEffect} from "react";
import {ChevronRight, Home} from "@mui/icons-material";
import {useLocation} from "react-router";
import {PAGES} from "./Pages";
import {Link} from "react-router-dom";


export function PageStandard(props: {title: string, titleElement?: ReactElement, sidebarVisible: boolean, headerEnd?: ReactElement, children: ReactNode}) {

    const location = useLocation()
    const breadcumb = getBreadcumb()
    const titleElement = props.titleElement || props.title

    useEffect(() => {
        if(breadcumb.length > 0) document.title = breadcumb[breadcumb.length-1].title + " | Octopus Desk";
    }, [breadcumb, location]);

    return (
        <Box sx={{ display: 'flex', minHeight: '100dvh' }}>
            {
                props.sidebarVisible ?
                    <>
                        <Header />
                        <Sidebar />
                    </>
                    :
                    null
            }
            <Box
                component="main"
                className="MainContent"
                sx={{
                    px: { xs: 2, md: 6 },
                    pt: {
                        xs: 'calc(12px + var(--Header-height))',
                        sm: 'calc(12px + 4/3*var(--Header-height))',
                        md: 3,
                    },
                    paddingLeft: {
                        xs: 2,
                        md: 'calc(20vw + 3em)',
                        lg: 'calc(15vw + 3em)',
                    },
                    pb: { xs: 2, sm: 2, md: 5 },
                    flex: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    minWidth: 0,
                    height: '100dvh',
                    gap: 1,
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    {
                        breadcumb.length > 0 ?
                            <Breadcrumbs
                                size="sm"
                                aria-label="breadcrumbs"
                                separator={<ChevronRight />}
                                sx={{ pl: 0 }}
                            >
                                <Link to="/" style={{display:"flex", justifyContent:"center", alignItems:"center"}}>
                                    <Home />
                                </Link>
                                <Link to={breadcumb[0].link}>
                                    <Typography color="neutral" fontWeight={500} fontSize={12}>
                                        {breadcumb[0].title}
                                    </Typography>
                                </Link>
                                {
                                    breadcumb.length > 1 ?
                                        <Link to={breadcumb[0].link + breadcumb[1].link}>
                                            <Typography color="primary" fontWeight={500} fontSize={12}>
                                                {breadcumb[1].title}
                                            </Typography>
                                        </Link>
                                        :
                                        null
                                }
                            </Breadcrumbs>
                            :
                            null
                    }
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        mb: {xs:0, md:1},
                        gap: 1,
                        flexDirection: { xs: 'column', sm: 'row' },
                        alignItems: { xs: 'start', sm: 'center' },
                        flexWrap: 'wrap',
                        justifyContent: 'space-between',
                    }}
                >
                    <Typography level="h2" component="h1">
                        {titleElement}
                    </Typography>
                    {
                        props.headerEnd ?
                            props.headerEnd
                            :
                            null
                    }
                </Box>
                {props.children}
            </Box>
        </Box>
    )

    function getBreadcumb() {
        const pages = location.pathname.split("/").slice(1)
        let breadcumb : {title: string, link: string}[] = []
        if(pages.length === 1) {
            const found = PAGES.find(p => p.link === "/" + pages[0])
            if(found) breadcumb.push({title: found.title, link: found.link})
        } else if(pages.length === 2) {
            const found = PAGES.find(p => p.link === "/" + pages[0])
            if(found && found.subpages) {
                breadcumb.push({title: found.title, link: found.link})

                const found2 = found.subpages.find(p => p.link === "/" + pages[1])
                if (found2) breadcumb.push({title: found2.title, link: found2.link})
            }
        }
        return breadcumb
    }
}