import {PageStandard} from "../../utils/PageStandard";
import {
    AspectRatio,
    Box,
    Button,
    Card,
    CardContent,
    CardOverflow,
    Chip,
    Divider,
    Dropdown,
    FormControl,
    FormLabel,
    Grid,
    IconButton,
    Input,
    Menu,
    MenuButton,
    MenuItem,
    Modal,
    ModalClose,
    ModalDialog,
    Option,
    Select,
    Sheet,
    Stack,
    Typography
} from "@mui/joy";
import {FilterAlt, MoreHorizRounded, Search} from "@mui/icons-material";
import {useContext, useEffect, useState} from "react";
import {Durations, ServiceModel} from "../../utils/Model";
import {Link} from "react-router-dom";
import {APIurl, formatPriceEuro, minutesToHoursString} from "../../utils/Utils";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";


export function ServiziOfferti() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [durationfilter, setDurationfilter] = useState<number | null>(null);
    const [sort, setSort] = useState("");
    const [servicesData, setServicesData] = useState<ServiceModel[]>([]);
    const [services, setServices] = useState<ServiceModel[]>([]);

    useEffect(() => {
        fetchServices().then(() => {})
    }, []);
    useEffect(() => {
        let res = servicesData
        if (search.length >= 3) {
            res = res.filter(a => `${a.title} ${a.description} ${a.id}`.toLowerCase().includes(search.toLowerCase()))
        }
        if (durationfilter) {
            res = res.filter(a => a.duration === durationfilter)
        }
        if (sort !== "") {
            switch (sort) {
                case "title_asc":
                    res = res.sort((a, b) => {
                        return a.title < b.title ? -1 : 1
                    })
                    break;
                case "title_desc":
                    res = res.sort((a, b) => {
                        return a.title > b.title ? -1 : 1
                    })
                    break;
                case "price_asc":
                    res = res.sort((a, b) => {
                        if(a.price || b.price) return a.price! < b.price! ? -1 : 1
                        else return 1
                    })
                    break;
                case "price_desc":
                    res = res.sort((a, b) => {
                        if(a.price || b.price) return a.price! > b.price! ? -1 : 1
                        else return 1
                    })
                    break;
                default:
                    break;
            }
        }
        setServices(res)
    }, [search, durationfilter, sort, servicesData]);

    async function fetchServices(): Promise<void> {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getServices`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                const data = await response.json()
                setServicesData(data)
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }


    function Filters() {
        return (
            <>
                <FormControl size="sm">
                    <FormLabel>Durata</FormLabel>
                    <Select
                        size="sm"
                        placeholder="Filtra per ruolo"
                        slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                        value={durationfilter}
                        onChange={(e, newValue) => setDurationfilter(newValue!)}
                    >
                        <Option value="">Tutti</Option>
                        {
                            Durations.map(a => <Option value={a}
                                                                key={a}>{minutesToHoursString(a)}</Option>)
                        }
                    </Select>
                </FormControl>
                <FormControl size="sm">
                    <FormLabel>Ordina</FormLabel>
                    <Select
                        size="sm"
                        placeholder="Ordina"
                        slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                        value={sort}
                        onChange={(e, newValue) => setSort(newValue!)}
                    >
                        <Option value="title_asc">Titolo crescente</Option>
                        <Option value="title_desc">Titolo decrescente</Option>
                        <Option value="price_asc">Prezzo crescente</Option>
                        <Option value="price_desc">Prezzo decrescente</Option>
                    </Select>
                </FormControl>
            </>
        )
    }

    return (
        <PageStandard title="Servizi offerti"
                      sidebarVisible={true}
                      headerEnd={<Link to="/servizi/nuovo-servizio"><Button>Nuovo servizio</Button></Link>}
        >

            <Sheet
                className="SearchAndFilters-mobile"
                sx={{
                    display: {xs: 'flex', sm: 'none'},
                    my: 1,
                    gap: 1,
                }}
            >
                <Input
                    size="sm"
                    placeholder="Search"
                    startDecorator={<Search/>}
                    sx={{flexGrow: 1}}
                />
                <IconButton
                    size="sm"
                    variant="outlined"
                    color="neutral"
                    onClick={() => setOpen(true)}
                >
                    <FilterAlt/>
                </IconButton>
                <Modal open={open} onClose={() => setOpen(false)}>
                    <ModalDialog aria-labelledby="filter-modal" layout="fullscreen">
                        <ModalClose/>
                        <Typography id="filter-modal" level="h2">
                            Filters
                        </Typography>
                        <Divider sx={{my: 2}}/>
                        <Sheet sx={{display: 'flex', flexDirection: 'column', gap: 2}}>
                            <Filters/>
                            <Button color="primary" onClick={() => setOpen(false)}>
                                Submit
                            </Button>
                        </Sheet>
                    </ModalDialog>
                </Modal>
            </Sheet>
            <Box
                className="SearchAndFilters-tabletUp"
                sx={{
                    borderRadius: 'sm',
                    py: 2,
                    display: {xs: 'none', sm: 'flex'},
                    flexWrap: 'wrap',
                    gap: 1.5,
                    '& > *': {
                        minWidth: {xs: '120px', md: '160px'},
                    },
                }}
            >
                <FormControl sx={{flex: 1}} size="sm">
                    <FormLabel>Cerca</FormLabel>
                    <Input size="sm"
                           placeholder="Titolo, descrizione o ID"
                           startDecorator={<Search/>}
                           value={search}
                           onChange={(e) => setSearch(e.target.value)}
                    />
                </FormControl>
                <Filters/>
            </Box>
            <Grid container spacing={{xs:2, md:4}}>
                {
                    services.map(a => <CardService service={a} key={a.id} />)
                }
            </Grid>
        </PageStandard>
    )
}

export function CardService(props: { service: ServiceModel }) {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const service = props.service

    return (
        <Grid xs={12} sm={6} md={6} xl={3}>
            <Card variant="outlined" sx={{ height:"100%" }}>
                <CardOverflow>
                    <AspectRatio ratio="2" color="neutral" variant="soft">
                        {
                            service.imageUrl ?
                                <img
                                    src={service.imageUrl}
                                    loading="lazy"
                                    alt={service.title}
                                />
                                :
                                null
                        }
                    </AspectRatio>
                </CardOverflow>
                <CardContent>
                    <Typography level="title-md">
                    {service.title}
                    </Typography>
                    <Typography level="body-sm">
                        {service.description}
                    </Typography>
                </CardContent>
                <CardOverflow variant="soft">
                    <Divider inset="context" />
                    <CardContent orientation="horizontal" sx={{display:"flex", justifyContent:"space-between", alignItems:"center"}}>
                        <Stack direction="row" spacing={1}>
                            <Chip variant="soft"
                                  color="primary"
                            >
                                {minutesToHoursString(service.duration)}
                            </Chip>
                            {
                                service.price ?
                                    <Chip variant="soft"
                                          color="success"
                                    >
                                        {formatPriceEuro(service.price)}
                                    </Chip>
                                    :
                                    null
                            }
                        </Stack>
                        <IconButton>
                            <RowMenu />
                        </IconButton>
                    </CardContent>
                </CardOverflow>
            </Card>
        </Grid>
    )

    function RowMenu() {
        return (
            <Dropdown>
                <MenuButton
                    slots={{root: IconButton}}
                    slotProps={{root: {variant: 'plain', color: 'neutral', size: 'sm'}}}
                >
                    <MoreHorizRounded/>
                </MenuButton>
                <Menu size="sm" sx={{minWidth: 140}}>
                    <Link to={"/servizi/servizio?serviceId=" + service.id}>
                        <MenuItem>Modifica</MenuItem>
                    </Link>
                    <Link to={"/servizi/assegna-utenti-a-servizio?serviceId=" + service.id}>
                        <MenuItem>Assegna utenti</MenuItem>
                    </Link>
                    <Divider/>
                    <MenuItem color="danger"
                              onClick={() => {
                                  if(window.confirm("Eliminare definitvamente questo servizio?")) deleteService()
                              }}
                    >
                        Elimina
                    </MenuItem>
                </Menu>
            </Dropdown>
        );
    }

    async function deleteService() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/deleteService/${service.id}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Servizio eliminato con successo")
                window.location.reload()
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}