import {PageStandard} from "../../utils/PageStandard";
import {
    Box,
    Button,
    Card,
    CardActions,
    CardOverflow,
    Checkbox,
    Divider,
    FormControl,
    FormLabel,
    Grid,
    Input,
    Option,
    Select,
    Stack,
    Typography
} from "@mui/joy";
import {CustomerModel, Permissions, Roles, ServiceModel, UserModel} from "../../utils/Model";
import {APIurl, areObjectsEqual, minutesToHoursString, TimeInput} from "../../utils/Utils";
import React, {useContext, useEffect, useState} from "react";
import {FeedbackContext} from "../../utils/FeedbackContext";
import moment from "moment";
import {Link, useSearchParams} from "react-router-dom";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";
import {ContentCopy} from "@mui/icons-material";

export function Prenotazione() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany, user} = useContext(AuthContext)

    const [searchParams, setSearchParams] = useSearchParams();
    const bookingId = searchParams.get("id")
    if(!bookingId) window.location.replace("/prenotazioni/tutte-le-prenotazioni")

    const eventInitialData = {
        date: "",
        start: "",
        serviceId: "",
        employeeId: "",
    }
    const [bookingServer, setBookingServer] = useState(eventInitialData)
    const [booking, setBooking] = useState(eventInitialData)
    const [customer, setCustomer] = useState<CustomerModel | null>(null)
    const [services, setServices] = useState<ServiceModel[]>([])
    const [users, setUsers] = useState<UserModel[]>([])
    const [canEditDelete, setCanEditDelete] = useState(true)

    const settingsInitialData = {
        reminderEmail: userCompany.gestionaleSettings.reminderEmail,
        reminderWhatsapp: userCompany.gestionaleSettings.reminderEmail,
    }
    const [settings, setSettings] = useState(settingsInitialData)
    const [settingsServer, setSettingsServer] = useState(settingsInitialData)

    useEffect(() => {
        fetchBooking().then(() => {})
    }, []);

    async function fetchBooking(): Promise<void> {
        setLoading(true)
        try {
            const user2 = firebase.auth().currentUser;
            if (!user2) throw new Error("Utente non autenticato");
            const token = await user2.getIdToken();

            const response = await fetch(`${APIurl}/getBooking/` + bookingId, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {

                const data = await response.json()
                if((user.role===Roles.ADMIN || user.permissions.includes(Permissions.PRENOTAZIONI_ALTRI_UTENTI)) || data.booking.employeeId === firebase.auth().currentUser?.uid) {
                    setBooking(data.booking)
                    setBookingServer(data.booking)
                    setSettings(data.booking.settings)
                    setSettingsServer(data.booking.settings)
                    if(moment().isSameOrAfter(moment(data.booking.end).add(30, "minutes"))) setCanEditDelete(false)
                    setCustomer(data.customer)
                    setUsers(data.users)
                    setServices(data.services)
                } else {
                    setError("Non hai i permessi per vedere questa prenotazione")
                }
            }
        } catch (error) {
            throw error;
        } finally {
            setLoading(false)
        }
    }

    if (booking === null || customer === null || error) return null
    else return (
        <PageStandard title="Prenotazione" sidebarVisible={true}>
            <Stack sx={{
                display: 'flex',
                width: "100%",
                maxWidth: '800px',
                mx: 'auto',
                px: {xs: 2, md: 6},
                py: {xs: 2, md: 3},
            }}>
                <Card sx={{width: "100%"}}>
                    <Box>
                        <Typography level="title-md">Informazioni prenotazione</Typography>
                        <Typography level="body-sm">
                            Gestisci la prenotazione selezionata
                        </Typography>
                    </Box>
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{my: 1}}
                    >
                        <Grid container spacing={2} sx={{flexGrow: 1}}>
                            <Grid xs={12} md={12}>
                                <FormControl>
                                    <FormLabel>ID prenotazione</FormLabel>
                                    <Input size="sm"
                                           type="text"
                                           placeholder="XXXXXXXXXX"
                                           value={bookingId ?? ""}
                                           readOnly
                                           endDecorator={<ContentCopy sx={{cursor:"pointer"}} />}
                                           sx={{fontFamily:"monospace"}}
                                           onClick={() => {
                                               navigator.clipboard.writeText(bookingId ?? "")
                                                   .then(() => setMessage("Copiato negli appunti"))
                                                   .catch(err => setError(err))
                                           }}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <FormLabel>Data *</FormLabel>
                                    <Input size="sm"
                                           type="date"
                                           value={booking.date}
                                           readOnly
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <FormLabel>Ora inizio *</FormLabel>
                                    <TimeInput size="sm"
                                               value={booking.start}
                                               readOnly
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <FormLabel>Servizio *</FormLabel>
                                    <Select
                                        size="sm"
                                        placeholder="Scegli..."
                                        slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                                        value={booking.serviceId}
                                        disabled
                                    >
                                        <Option value="" disabled>Scegli...</Option>
                                        {
                                            services.map(a => <Option value={a.id}
                                                                      key={a.id}>{a.title} - {minutesToHoursString(a.duration)}</Option>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <FormLabel>Dipendente *</FormLabel>
                                    <Select
                                        size="sm"
                                        placeholder="Scegli..."
                                        slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                                        value={booking.employeeId}
                                        disabled
                                    >
                                        <Option value="" disabled>Scegli...</Option>
                                        {
                                            users.map(a => <Option value={a.id}
                                                                   key={a.id}>{a.surname.toUpperCase()} {a.name}</Option>)
                                        }
                                    </Select>
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Divider/>
                    <Stack direction="row" spacing={2} sx={{justifyContent:"space-between"}}>
                        <Box>
                            <Typography level="title-md">Informazioni cliente</Typography>
                            <Typography level="body-sm">
                                Per modificare le informazioni del cliente vai nella sezione <a href="/gestione-clienti/libro-clienti"><i>Libro clienti</i></a>.
                            </Typography>
                        </Box>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{my: 1}}
                    >
                        <Grid container spacing={2} sx={{flexGrow: 1}}>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <FormLabel>Nome *</FormLabel>
                                    <Input size="sm"
                                           type="text"
                                           placeholder="Mario"
                                           value={customer.name}
                                           readOnly
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <FormLabel>Cognome *</FormLabel>
                                    <Input size="sm"
                                           type="text"
                                           placeholder="Rossi"
                                           value={customer.surname}
                                           readOnly
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <FormLabel>Email *</FormLabel>
                                    <Input size="sm"
                                           type="email"
                                           placeholder="nome@email.com"
                                           value={customer.email}
                                           readOnly
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <FormLabel>Telefono *</FormLabel>
                                    <Input size="sm"
                                           type="tel"
                                           placeholder="3330000000"
                                           value={customer.phoneNumber}
                                           readOnly
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <Checkbox label="Consenso trattamento dei dati"
                                              size="sm"
                                              checked={true}
                                              readOnly
                                    />
                                </FormControl>
                            </Grid>
                            <Grid xs={12} md={6}>
                                <FormControl>
                                    <Checkbox label="Consenso marketing"
                                              size="sm"
                                              checked={customer.marketing}
                                              readOnly
                                    />
                                </FormControl>
                            </Grid>
                        </Grid>
                    </Stack>
                    <Divider/>
                    <Box>
                        <Typography level="title-md">Impostazioni</Typography>
                    </Box>
                    <Stack
                        direction="row"
                        spacing={3}
                        sx={{my: 1}}
                    >
                        {
                            userCompany.gestionaleSettings.reminderEmail ?
                                <Checkbox label="Reminder email"
                                          size="sm"
                                          checked={settings.reminderEmail}
                                          readOnly
                                />
                                :
                                <Typography level="body-sm">
                                    Il servizio di reminder via email non è abilitato.
                                </Typography>
                        }
                        {
                            userCompany.gestionaleSettings.reminderWhatsapp ?
                                <Checkbox label="Reminder WhatsApp"
                                          size="sm"
                                          checked={settings.reminderWhatsapp}
                                          readOnly
                                />
                                :
                                <Typography level="body-sm">
                                    Il servizio di reminder WhatsApp non è abilitato.
                                </Typography>
                        }
                    </Stack>
                    {
                        canEditDelete &&
                        <CardOverflow sx={{borderTop: '1px solid', borderColor: 'divider'}}>
                            <CardActions sx={{alignSelf: 'flex-end', pt: 2}}>
                                <Button size="sm"
                                        variant="outlined"
                                        color="danger"
                                        onClick={() => {
                                            if(window.confirm("Eliminare definitvamente la prenotazione?")) {
                                                deleteBooking().then(() => {})
                                            }}}
                                >
                                    Elimina prenotazione
                                </Button>
                                <Link to={"/prenotazioni/modifica-prenotazione?id=" + bookingId}>
                                    <Button size="sm"
                                            variant="solid"
                                            //onClick={() => updateBooking()}
                                    >
                                        Modifica prenotazione
                                    </Button>
                                </Link>
                            </CardActions>
                        </CardOverflow>
                    }
                </Card>
            </Stack>
        </PageStandard>
    )


    async function updateBooking() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/updateBooking/${bookingId}`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({eventData: booking, settings: settings})
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Prenotazione aggiornata con successo")
                window.location.reload()
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }

    async function deleteBooking() {
        setLoading(true)
        try {
            const user2 = firebase.auth().currentUser
            if (!user2) throw new Error("Utente non autenticato")
            const token = await user2.getIdToken();

            const response = await fetch(`${APIurl}/deleteBooking/${bookingId}`, {
                method: "DELETE",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Prenotazione eliminata con successo")
                window.location.href = "/prenotazioni/tutte-le-prenotazioni"
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }
}