import {PageStandard} from "../../utils/PageStandard";
import {useContext, useState} from "react";
import {
    Alert,
    Button,
    ButtonGroup,
    FormControl,
    FormLabel,
    Input,
    Option,
    Select,
    Stack,
    Textarea,
    Typography
} from "@mui/joy";
import {APIurl} from "../../utils/Utils";
import {FeedbackContext} from "../../utils/FeedbackContext";
import {Warning} from "@mui/icons-material";
import {AuthContext} from "../../utils/AuthProvider";
import firebase from "firebase/compat/app";
import JoditEditor from "jodit-react";

export function Newsletter() {
    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    const {userCompany} = useContext(AuthContext)

    const [recipients, setRecipients] = useState("MARKETING")
    const [subject, setSubject] = useState("")
    const initialUnsubscribe = "<p style=\"text-align: center; font-size: 12px;\">Non vuoi più ricevere queste mail? <a href=\"https://utils.octopusdesk.it/unsubscribe?user={{userEmail}}&amp;company={{companyId}}\" style=\"color: rgb(0, 0, 0); font-size: 12px;\"><u>Cancella iscrizione</u></a></p>"
    const initialText = `<p>Scrivi qui la tua mail.<br>ATTENZIONE: il link per la revoca del consenso non può essere eliminato o modificato. Se venisse eliminato o modificato, non sarà possibile inviare la mail.<br><br><br></p>${initialUnsubscribe}<p><br></p>`
    const [text, setText] = useState(initialText)


    return (
        <PageStandard title="Newsletter" sidebarVisible={true}>
            <Stack direction="column" spacing={2}>
                <FormControl>
                    <FormLabel>Destinatari *</FormLabel>
                    <Select
                        size="sm"
                        placeholder="Scegli..."
                        slotProps={{button: {sx: {whiteSpace: 'nowrap'}}}}
                        value={recipients}
                        onChange={(e, newValue) => setRecipients(newValue!)}
                    >
                        <Option value="MARKETING">Solo utenti con consenso marketing</Option>
                        <Option value="ALL">Tutti gli utenti</Option>
                    </Select>
                </FormControl>
                {
                    recipients==="ALL" ?
                        <Alert color="warning" startDecorator={<Warning />}>
                            <Stack>
                                <Typography level="title-md">Attenzione!</Typography>
                                <Typography level="body-sm">La comunicazione non deve essere di carattere promozionale, poiché verrà inviata anche agli utenti che non hanno espresso il consenso per il marketing.</Typography>
                                <Typography level="body-sm">Se la comunicazione è di carattere promozionale, si prega di selezionare solo gli utenti che hanno prestato il proprio consenso.</Typography>
                            </Stack>
                        </Alert>
                        :
                        null
                }
                <FormControl>
                    <FormLabel>Oggetto *</FormLabel>
                    <Input size="sm"
                           type="text"
                           placeholder="Oggetto della mail"
                           value={subject}
                           onChange={e => setSubject(e.target.value)}
                           error={subject.trim().length < 3}
                    />
                </FormControl>
                <FormControl>
                    <FormLabel>Messaggio *</FormLabel>
                    <JoditEditor
                        value={text}
                        onBlur={newContent => setText(newContent)}
                        config={{height:"25rem"}}
                    />
                </FormControl>
                <Stack direction="row" justifyContent="end" sx={{pb:4}}>
                    <Button onClick={() => {
                        if(checkIfUnsuscribe()) sendEmailToCustomers()
                        else window.alert("Non è presente il link di Unsubscribe")
                    }}
                            disabled={recipients==="" || subject.trim().length < 3 || text.trim().length < 3 || !checkIfUnsuscribe()}
                    >
                        Invia
                    </Button>
                </Stack>
            </Stack>
        </PageStandard>
    )

    async function sendEmailToCustomers() {
        setLoading(true)
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/sendEmailToCustomers`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify({subject: subject, text: text, recipients: recipients})
            })
            if (!response.ok) {
                const error = await response.clone().json();
                setError(error);
            } else {
                setMessage("Mail inviata con successo")
                setRecipients("MARKETING")
                setSubject("")
                setText(initialText)
            }
        } catch (e: unknown) {
            setError(e)
        } finally {
            setLoading(false)
        }
    }

    function checkIfUnsuscribe(): boolean {
        if(text.includes(initialUnsubscribe)) return true
        return false
    }
}