import React, {useContext, useState} from "react";
import {AspectRatio, Box, Button, Container, FormControl, FormLabel, Input, Sheet, Stack, Typography} from "@mui/joy";
import {FeedbackContext} from "../utils/FeedbackContext";
import firebase from "firebase/compat/app";
import "firebase/compat/auth"
import {Email, Key, Visibility, VisibilityOff} from "@mui/icons-material";
import {useLocation} from "react-router-dom";
import {LogoOctopus} from "../assets/Logo";


export function Login() {

    const {loading, error, message, setLoading, setError, setMessage} = useContext(FeedbackContext)
    enum TABS {
        LOGIN,
        RESET_PSW,
        CONFIRM_RESET_PSW,
    }
    const location = useLocation()

    const [tab, setTab] = useState<TABS>(TABS.LOGIN)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [pswvisible, setPswvisible] = useState(false)

    switch (tab) {
        case TABS.LOGIN :
            return (
                <Box sx={{height: '100vh'}}>
                    <Container maxWidth="sm" sx={{height: '100%', display:"flex", alignItems:"center"}}>
                        <Stack direction="column" spacing={2} sx={{width:"100%"}}>
                            <Box sx={{ display: 'flex', gap: 1, flexDirection:"column", justifyContent:"center", alignItems: 'center' }}>
                                <AspectRatio ratio={1} sx={{width:85, borderRadius:20}}>
                                    <Sheet variant="soft" color="primary" >
                                        <LogoOctopus width="60" height="60" color="var(--joy-palette-primary-500)" />
                                    </Sheet>
                                </AspectRatio>
                                <Typography level="h2">Octopus Desk</Typography>
                            </Box>
                            <Box sx={{display:"flex", justifyContent:"center"}}>

                            </Box>
                            <FormControl>
                                <FormLabel>
                                    Email
                                </FormLabel>
                                <Input type="email"
                                       placeholder="nome@email.com"
                                       value={email}
                                       onChange={(e) => setEmail(e.target.value)}
                                       startDecorator={<Email />}
                                       onKeyDown={(event) => {
                                           if (event.key === 'Enter') {
                                               SignIn(email, password, location.state).then(() => {})
                                           }
                                       }}
                                       autoFocus
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>
                                    Password
                                </FormLabel>
                                <Input type={pswvisible ? "text" : "password"}
                                       placeholder="********"
                                       value={password}
                                       onChange={(e) => setPassword(e.target.value)}
                                       startDecorator={<Key />}
                                       endDecorator={
                                           pswvisible ?
                                               <VisibilityOff onClick={() => setPswvisible(!pswvisible)} />
                                               :
                                               <Visibility onClick={() => setPswvisible(!pswvisible)} />
                                       }
                                       onKeyDown={(event) => {
                                           if (event.key === 'Enter') {
                                               SignIn(email, password, location.state).then(() => {})
                                           }
                                       }}
                                />
                            </FormControl>
                            <Button variant="solid"
                                    color="primary"
                                    onClick={() => SignIn(email, password, location.state)}
                                    disabled={!email || !password || loading}
                                    onKeyDown={(event) => {
                                        if (event.key === 'Enter') {
                                            SignIn(email, password, location.state).then(() => {})
                                        }
                                    }}
                            >
                                Accedi
                            </Button>

                            <Typography level="body-sm" textAlign="center" className="link" onClick={() => setTab(TABS.RESET_PSW)}>
                                Ho dimenticato la password
                            </Typography>
                        </Stack>
                    </Container>
                </Box>
            )
        case TABS.RESET_PSW :
            return (
                <Box sx={{height: '100vh'}}>
                    <Container maxWidth="sm" sx={{height: '100%', display:"flex", alignItems:"center"}}>
                        <Stack direction="column" spacing={2} sx={{width:"100%"}}>

                            <Box sx={{ display: 'flex', gap: 1, flexDirection:"column", justifyContent:"center", alignItems: 'center' }}>
                                <AspectRatio ratio={1} sx={{width:85, borderRadius:20}}>
                                    <Sheet variant="soft" color="primary" >
                                        <LogoOctopus width="60" height="60" color="var(--joy-palette-primary-500)" />
                                    </Sheet>
                                </AspectRatio>
                                <Typography level="h2">Octopus Desk</Typography>
                            </Box>
                            <Box sx={{display:"flex", justifyContent:"center"}}>

                            </Box>

                            <Typography level="body-sm" className="link" onClick={() => setTab(TABS.LOGIN)}>
                                &larr; Indietro
                            </Typography>

                            <FormControl>
                                <FormLabel>
                                    Email
                                </FormLabel>
                                <Input type="email"
                                       placeholder="nome@email.com"
                                       value={email}
                                       onChange={(e) => setEmail(e.target.value)}
                                       startDecorator={<Email />}
                                />
                            </FormControl>

                            <Button variant="solid"
                                    color="primary"
                                    onClick={() => ResetPsw(email)}
                                    disabled={!email || loading}
                            >
                                Reimposta password
                            </Button>

                        </Stack>
                    </Container>
                </Box>
            )
        case TABS.CONFIRM_RESET_PSW :
            return (
                <Box sx={{height: '100vh'}}>
                    <Container maxWidth="sm" sx={{height: '100%', display:"flex", alignItems:"center"}}>
                        <Stack direction="column" spacing={2} sx={{width:"100%"}}>

                            <Typography level="body-md" textAlign="center" >
                                Ti abbiamo inviato una mail con le istruzioni per il reset della password
                            </Typography>

                            <Button variant="solid"
                                    color="primary"
                                    onClick={() => setTab(TABS.LOGIN)}
                            >
                                Torna al login
                            </Button>

                        </Stack>
                    </Container>
                </Box>
            )
    }


    async function SignIn(email: string, password: string, url: { from: string } | null) {
        try {
            await firebase.auth().signInWithEmailAndPassword(email, password)
            if(url) window.location.replace(url.from)
        } catch (e: unknown) {
            setError(e)
        }
    }
    async function ResetPsw(email: string) {
        try {
            await firebase.auth().sendPasswordResetEmail(email)
        } catch (e: unknown) {
            setError(e)
        }
    }
}