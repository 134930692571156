import React from "react";


export function LogoOctopus(props: {width: string, height: string, color: string}) {
    return (
        <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
             width={props.width} height={props.height} viewBox="0 0 512.000000 512.000000"
             preserveAspectRatio="xMidYMid meet">

            <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
               fill={props.color} stroke="none">
                <path d="M2406 5105 c-83 -29 -146 -120 -146 -210 0 -197 236 -298 379 -162
94 90 95 226 2 318 -66 67 -148 85 -235 54z"/>
                <path d="M3812 4869 c-69 -35 -122 -120 -122 -195 0 -214 263 -311 399 -147
82 100 58 259 -51 331 -58 38 -163 43 -226 11z"/>
                <path d="M3000 4785 c-18 -88 -76 -192 -145 -261 -211 -213 -560 -204 -761 19
-25 28 -55 69 -66 91 -11 23 -22 43 -24 45 -7 8 -191 -98 -266 -154 -254 -191
-420 -450 -485 -755 -21 -101 -24 -332 -4 -425 96 -465 434 -777 920 -849 56
-8 106 -10 128 -6 72 17 123 98 109 174 -10 54 -56 100 -124 125 -77 28 -141
84 -177 157 -24 49 -29 75 -33 153 -8 174 43 308 162 426 116 115 218 157 416
171 255 18 488 -55 630 -197 122 -121 180 -279 180 -487 0 -285 -98 -520 -304
-728 -140 -141 -285 -228 -488 -292 -139 -45 -226 -57 -398 -56 -451 2 -1065
176 -1894 539 -107 47 -209 85 -226 85 -50 0 -107 -35 -130 -80 -19 -38 -20
-57 -20 -745 0 -758 -1 -744 51 -792 25 -22 129 -65 325 -133 l111 -38 27 68
c99 250 328 438 611 500 75 17 250 14 334 -5 272 -62 498 -259 596 -518 27
-74 55 -215 55 -283 l0 -44 118 1 c64 1 165 4 225 8 l107 6 4 115 c4 134 21
205 75 315 127 258 382 416 673 416 134 0 214 -19 338 -79 78 -38 109 -61 180
-132 48 -47 98 -101 112 -120 l25 -35 70 57 c79 66 263 252 263 267 0 6 -4 11
-9 11 -4 0 -39 28 -76 63 -359 328 -270 908 170 1113 101 47 218 68 331 61
l91 -6 7 76 c3 42 6 111 6 155 l0 78 -27 0 c-59 1 -171 31 -243 66 -54 27 -96
58 -151 113 -122 121 -179 257 -179 422 0 167 56 305 170 420 36 37 74 71 84
76 18 10 17 14 -19 67 -45 69 -107 149 -157 205 l-38 41 -50 -36 c-28 -20 -83
-48 -123 -62 -98 -36 -240 -37 -337 -3 -133 46 -244 143 -303 264 -44 90 -60
167 -55 270 l3 81 -30 8 c-53 15 -269 48 -312 48 l-42 0 -11 -55z"/>
                <path d="M4695 3737 c-202 -98 -236 -371 -63 -508 61 -48 107 -63 188 -63 115
-1 220 66 272 173 34 69 32 187 -4 257 -28 54 -86 112 -140 140 -21 12 -63 19
-118 21 -74 4 -92 1 -135 -20z"/>
                <path d="M4572 2245 c-69 -19 -105 -39 -152 -81 -200 -181 -155 -502 88 -621
51 -25 67 -28 162 -28 96 0 110 3 161 29 71 35 139 104 176 176 25 50 28 65
28 160 0 93 -3 112 -27 162 -74 159 -269 250 -436 203z"/>
                <path d="M1130 1031 c-200 -65 -337 -222 -370 -426 -57 -360 282 -674 638
-590 265 63 444 325 402 590 -33 206 -177 369 -379 429 -74 22 -219 20 -291
-3z"/>
                <path d="M3175 1032 c-78 -27 -138 -66 -193 -127 -242 -265 -95 -687 262 -748
193 -33 392 75 479 260 27 58 32 82 35 165 4 79 0 108 -17 161 -45 134 -158
247 -287 287 -84 25 -208 27 -279 2z"/>
            </g>
        </svg>
    )
}