import {createContext, ReactNode, useEffect, useState} from "react";
import firebase from "firebase/compat/app";
import 'firebase/compat/auth'
import {CompanyDetailsModel, UserModel} from "./Model";
import {APIurl} from "./Utils";


export const AuthContext = createContext<any>(null);

interface AuthProviderProps {
    children?: ReactNode
}
export enum AUTHSTATES {
    UNKNOWN,
    LOGGEDIN,
    LOGGEDOUT,
}

export function AuthProvider(props: AuthProviderProps) {

    const [authState, setAuthState] = useState<AUTHSTATES>(AUTHSTATES.UNKNOWN);
    const [user, setUser] = useState<UserModel | null>(null);
    const [currentUser, setCurrentUser] = useState<any>(null);
    const [userCompany, setUserCompany] = useState<CompanyDetailsModel | undefined>()
    const [logo, setLogo] = useState<string | null>(null)

    useEffect(() => {
        firebase.auth().onAuthStateChanged((user) => {
            if(user) {
                fetchUserCompany(user.uid)
                    .then(res => {
                        setUserCompany(res.company)
                        setUser(res.user)
                        setCurrentUser(user)
                        setAuthState(AUTHSTATES.LOGGEDIN)
                    })
                    .catch(() => {
                        setUserCompany(undefined)
                        setCurrentUser(null)
                        setAuthState(AUTHSTATES.LOGGEDOUT)
                        window.alert("Nessuna azienda trovata")
                        firebase.auth().signOut()
                    })
                fetchLogo().then(() => {})
            } else {
                setUserCompany(undefined)
                setCurrentUser(null)
                setAuthState(AUTHSTATES.LOGGEDOUT)
            }
        })
    }, [])

    async function fetchUserCompany(uid: string): Promise<{ company: CompanyDetailsModel, user: UserModel }> {
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/getCompanyAndUserByUser/${uid}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
            return await response.json();
        } catch (error) {
            throw error;
        }
    }
    async function fetchLogo(): Promise<void> {
        try {
            const user = firebase.auth().currentUser;
            if (!user) throw new Error("Utente non autenticato");
            const token = await user.getIdToken();

            const response = await fetch(`${APIurl}/uploads/logo`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Authorization": `Bearer ${token}`
                }
            })
            const blob = await response.blob();
            const url = URL.createObjectURL(blob);
            setLogo(url);
        } catch (error) {
            throw error;
        }
    }

    return (
        <AuthContext.Provider value={{currentUser, authState, userCompany, logo, user}}>
            {props.children}
        </AuthContext.Provider>
    )
}